.partner-benefits {
  .main-title {
    background: #298dff;
    min-height: auto;

    text-align: center;
    position: relative;
    @include breakpoint(large) {
      background: #0d7eff;
      min-height: rem-calc(90);
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(large) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(15);
      margin-left: rem-calc(-15);

      @include breakpoint(large) {
        border-width: rem-calc(30);
        margin-left: rem-calc(-30);
      }
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(18);
      margin-left: rem-calc(-18);
      @include breakpoint(large) {
        border-width: rem-calc(36);
        margin-left: rem-calc(-36);
      }
    }

    h5 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(36);
      padding: rem-calc(10);

      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: rem-calc(70);
        padding: rem-calc(10);
      }
    }
  }
  .partner-content {
    background: #298dff;
    padding-top: 0;
    padding-bottom:rem-calc(30);
    @include breakpoint(medium) {
      padding-top: rem-calc(45);
      padding-bottom:rem-calc(60);
    }
    .intro-text {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(32);
      font-weight: 300;
      line-height: rem-calc(40);
      margin-top:0;
      margin-bottom:rem-calc(30);
      text-align:left;
      @include breakpoint(large) {
        font-size: rem-calc(45);
        line-height: rem-calc(50);
        margin-bottom:0;

      }
    }

    .benefit-list {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(19);
      font-weight: 400;
      line-height: rem-calc(23);
      p {
        margin-bottom:rem-calc(5);
      }
      ul {
        margin-top:0;
        margin-left:rem-calc(17);
        li {
          font-size: rem-calc(19);
          font-weight: 400;
          line-height: rem-calc(23);
          margin-bottom:rem-calc(10);
        }
      }
    }

  }


}