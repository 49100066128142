.support-channels {
  .main-title {

    background: #298dff;
    min-height: auto;

    text-align: center;
    position: relative;
    @include breakpoint(large) {
      min-height: rem-calc(90);
      background: #0d7eff;
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(large) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(15);
      margin-left: rem-calc(-15);

      @include breakpoint(large) {
        border-width: rem-calc(30);
        margin-left: rem-calc(-30);
      }
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(18);
      margin-left: rem-calc(-18);
      @include breakpoint(large) {
        border-width: rem-calc(36);
        margin-left: rem-calc(-36);
      }
    }

    h5 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(36);
      padding: rem-calc(10);

      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: rem-calc(70);
        padding: rem-calc(10);
      }
    }
  }
  .channels {
    background: #298dff;
    padding-top: 0;
    padding-bottom:0;
    @include breakpoint(large) {
      padding-top: rem-calc(60);
      padding-bottom:rem-calc(60);
    }
    .channel {
      background:#fff;
      padding:rem-calc(40) rem-calc(30) 0 rem-calc(30);
      text-align:center;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
      margin-bottom:rem-calc(30);
      @include breakpoint(large) {
        margin-bottom:0;
      }
      .channel-icon {
        margin-bottom:rem-calc(25);
        img {
          height:rem-calc(89);
        }
      }
      .channel-details {
        h3 {
          color:#696969;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(24);
          font-weight: 700;
          line-height: rem-calc(23);
          margin-bottom:rem-calc(10);
          text-align:left;
        }
        p {
          color:#696969;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(19);
          font-weight: 400;
          line-height: rem-calc(23);
          text-align:left;
          min-height:rem-calc(79);
          &:empty {
            display:none;
          }
        }
      }



      .channel-cta {

        height:rem-calc(73);
        line-height:rem-calc(73);
        text-align:right;
        border-top:rem-calc(2) solid #ebebeb;
        a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(19);
          line-height: rem-calc(25);
          font-weight: 700;
          color:#696969;
          img {
            width: rem-calc(27);
            height: rem-calc(18.6);
            margin-left: rem-calc(6);
          }
          &:hover {
            opacity:0.7;
          }
        }

      }
    }
  }
}