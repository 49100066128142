.product-title-wrapper {
  padding:rem-calc(30) 0 rem-calc(10) 0;

  .product-title {
    h1 {
      line-height:rem-calc(45);
      font-size:rem-calc(40);
      font-weight:400;
      color:#1b4886;

      @include breakpoint(large) {
        line-height:rem-calc(60);
        font-size:rem-calc(60);
      }
    }
    .signup-intro-text {
      line-height:rem-calc(23);
      font-size:rem-calc(19);
      font-weight:600;
      color:#1b4886;
      margin:0;
      margin-top:rem-calc(30);
      margin-bottom:rem-calc(60);
    }

  }
}

.products-filters {
  padding:rem-calc(60) 0;
  background:#f5f5f5;

  .filters-form {
    background:#ebebeb;
    padding:rem-calc(30);
    margin-bottom:rem-calc(25);

    h3 {
      text-align:center;
      margin-top:0;
      margin-bottom:rem-calc(30);
      line-height:rem-calc(26);
      font-size:rem-calc(24);
      font-weight:700;
      color:#696969;
    }

    select {
      width:100%;
      margin-right:0;
      height:rem-calc(60);
      border:none;
      background-image: url('../img/arrow-down-g.svg');
      background-position: right rem-calc(-27px) center;
      background-size: rem-calc(20) rem-calc(12);
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(24);
      font-weight:700;
      color:#696969;
      padding-left:rem-calc(15);
      padding-right: rem-calc(47);

      @include breakpoint(large) {
        width:rem-calc(463);
        margin-right:rem-calc(30);
        padding-left:rem-calc(30);
      }
    }
    button {
      height:rem-calc(60);
      background:#696969;
      color:#fff;
      text-align:center;
      width:100%;
      border:none;
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(24);
      line-height:rem-calc(60);
      font-weight:700;

      @include breakpoint(large) {
        width:rem-calc(202);
      }

      &:hover {
        background:#555;
        cursor:pointer;
      }
    }
  }

  .category-accordion {
    margin-bottom:rem-calc(20);
    .count {
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(24);
      line-height:rem-calc(28);
      font-weight:700;
      color:#696969;
      padding-left:rem-calc(30);
    }
    .accordion {
      .accordion-item {
        .accordion-title {
          background:#1b4886;
          border:none;
          border-bottom:rem-calc(2) solid #f5f5f5;
          padding:rem-calc(15) rem-calc(60) rem-calc(15) rem-calc(30);

          @include breakpoint(large) {
            padding:rem-calc(30) rem-calc(180) rem-calc(30) rem-calc(30);
          }
          &[aria-expanded="true"] {
            background:#ebebeb;
            padding-bottom:0;
            @include breakpoint(large) {
              padding-bottom:rem-calc(30);
            }
            border-bottom:rem-calc(2) solid #ebebeb;
            &:before {
              background:url('../img/arrow-up-b.svg') no-repeat center center;
              -ms-transform: rotate(180deg); /* IE 9 */
              -webkit-transform: rotate(180deg); /* Safari */
              transform: rotate(180deg);
            }

            h3,p {
              color:#1b4886;
            }
          }
          &:before {
            background:url('../img/arrow-up-w.svg') no-repeat center center;
            top:0;
            content:'';
            width:rem-calc(20);
            height:auto;
            margin-top:0;
            position:absolute;
            bottom:0;
            background-size:rem-calc(20) rem-calc(12);
            -webkit-transition: all 0.2s; /* Safari */
            transition: all 0.2s;
            @include breakpoint(medium) {
              width:rem-calc(31);
              background-size:rem-calc(31) rem-calc(19);
            }
          }

          h3 {
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(24);
            font-weight: 700;
            line-height:rem-calc(26);
          }
          p {
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height:rem-calc(23);
            margin:0;
          }
        }
        .accordion-content {
          background: #ebebeb;
          border: none;
          padding: 0 rem-calc(30);
          border-bottom:rem-calc(4) solid #f5f5f5;
          @include breakpoint(large) {
            padding: rem-calc(60) rem-calc(30) rem-calc(60) rem-calc(30);
          }
          .prod-desc {
            color:#1b4886;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height:rem-calc(23);
            margin:0;
            padding-bottom:rem-calc(15);
          }
          .chart-container {
            position: relative;

            &.count-2 {
              position: relative;
              table.chart-table {
                tbody {
                  tr {
                    td {
                      &:nth-of-type(2) {
                        padding-right:rem-calc(60) !important;
                      }
                    }
                  }
                }
              }
              .shadow-0 {
                display:none;
                @include breakpoint(large) {
                  background: #fff;
                  position: absolute;
                  top: rem-calc(-30);
                  bottom: rem-calc(-30);
                  right: 0;
                  width: 40%;
                  display: block;
                  content: '';
                  z-index: 1;
                  box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
                }

              }
              .shadow-1 {
                display:none;
                @include breakpoint(large) {
                  background: #fff;
                  position: absolute;
                  top: rem-calc(-30);
                  bottom: rem-calc(-30);
                  right: 42%;
                  width: 38%;
                  display: block;
                  content: '';
                  z-index: 1;
                  box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
                }
              }
            }

            &.count-1 {
              .shadow-0 {
                display:none;
                @include breakpoint(large) {
                  display:block;
                  background: #fff;
                  position: absolute;
                  top: rem-calc(-30);
                  bottom: rem-calc(-30);
                  right: 0;
                  width: 80%;
                  display: block;
                  content: '';
                  z-index: 1;
                  box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
                }


              }

            }

            &.count-3 {
              position: relative;
              .shadow-0 {
                display:none;
                @include breakpoint(large) {
                  background: #fff;
                  position: absolute;
                  top: rem-calc(-30);
                  bottom: rem-calc(-30);
                  right: 0;
                  width: 25%;
                  display: block;
                  content: '';
                  z-index: 1;
                  box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
                }
              }
              .shadow-1 {
                display:none;
                @include breakpoint(large) {
                  background: #fff;
                  position: absolute;
                  top: rem-calc(-30);
                  bottom: rem-calc(-30);
                  right: 27%;
                  width: 25%;
                  display: block;
                  content: '';
                  z-index: 1;
                  box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
                }
              }

              .shadow-2 {
                display:none;
                @include breakpoint(large) {
                  background: #fff;
                  position: absolute;
                  top: rem-calc(-30);
                  bottom: rem-calc(-30);
                  right: 54%;
                  width: 25%;
                  display: block;
                  content: '';
                  z-index: 1;
                  box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
                }
              }
            }


            table.chart-table {
              position: relative;
              z-index: 2;
              tbody {
                background: transparent;

                tr.row {
                  background: transparent;
                  td {
                    vertical-align: top;
                    background-color: transparent;
                    padding-top: rem-calc(5);
                    padding-bottom: 0;
                    &.key {
                      color: #298dff;
                      font-family: 'Source Sans Pro', sans-serif;
                      font-size: rem-calc(16);
                      font-weight: 600;
                      padding-left: 0;
                      width:20%;
                      &:before {
                        content:' ';
                        display:block;
                        width:100%;
                        border-bottom:rem-calc(2) solid #dcdcdc;
                        vertical-align:bottom;
                        margin-bottom:rem-calc(5);
                      }
                    }
                    &.value {
                      color: #696969;
                      font-family: 'Source Sans Pro', sans-serif;
                      font-size: rem-calc(16);
                      font-weight: 400;
                      padding-left:rem-calc(30);
                      padding-right:rem-calc(30);
                      &:before {
                        content:' ';
                        display:block;
                        width:100%;
                        border-bottom:rem-calc(2) solid #ebebeb;
                        vertical-align:bottom;
                        margin-bottom:rem-calc(5);
                      }

                      h3 {
                        text-align:center;
                        font-size: rem-calc(19);
                        font-weight: 700;
                        line-height: rem-calc(23);
                        margin:0 0 rem-calc(10) 0;
                        a {
                          color:#298dff;

                          &:hover {
                            color:#1b4886;
                          }
                        }
                      }
                      p {
                        font-size: rem-calc(16);
                        font-weight: 600;
                        line-height: rem-calc(19);
                      }
                      &:last-child {

                      }
                    }
                    &.width-2 {
                      width:40%;


                    }
                    &.product-ctas {
                      padding-top:0;
                      padding-bottom:0;
                      padding-right:rem-calc(30);
                      padding-left:rem-calc(30);


                      &.width-2 {


                      }
                      &.first-one {
                        padding-left:rem-calc(30);
                        padding-right:rem-calc(30);

                        &:before {
                          content:' ';
                          display:block;
                          width:100%;
                          border-top:rem-calc(2) solid #ebebeb;
                          vertical-align:bottom;
                          margin-top:rem-calc(25);
                          padding-top:rem-calc(25)
                        }
                      }
                      a {
                        display:block;
                        text-align:center;
                        font-family: 'Source Sans Pro', sans-serif;
                        line-height:rem-calc(23);
                        font-size:rem-calc(19);
                        font-weight:600;
                        margin-bottom:rem-calc(25);
                        &.try-browser {
                          color:#298dff;
                          position:relative;
                          &:after {
                            position:absolute;
                            margin-left:rem-calc(7);
                            top:rem-calc(4);
                            content:' ';
                            display:inline-block;
                            width:rem-calc(27);
                            height:rem-calc(18.6);
                            background:url('../img/arrow-right-blue.svg') no-repeat center center;
                            background-size:rem-calc(27) rem-calc(18.6);
                          }

                          &:hover {
                            -webkit-filter: brightness(60%);
                            filter: brightness(60%);
                          }
                        }
                        &.trail-period {
                          border:rem-calc(2) solid #298dff;
                          border-radius:rem-calc(50);
                          height:rem-calc(50);
                          line-height:rem-calc(50);
                          color:#298dff;
                          &:hover {
                            color:#1b4886;
                            border-color:#1b4886;
                          }
                        }
                        &.buy-now {
                          background:#298dff;
                          border-radius:rem-calc(50);
                          height:rem-calc(50);
                          line-height:rem-calc(50);
                          color:#fff;
                          box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
                          &:hover {
                            background:#1b4886;
                          }
                        }
                      }

                    }

                  }
                  &:first-child {
                    td {
                      &:before {
                        content:'';
                        display:none;

                      }
                    }
                  }
                  &:last-child {
                    td {
                      &.key {
                        &:after {
                          content:' ';
                          display:block;
                          width:100%;
                          border-bottom:rem-calc(2) solid #dbdddc;
                          vertical-align:bottom;
                          margin-top:rem-calc(5);
                        }
                      }
                      &.value {
                        &:after {
                          content:' ';
                          display:block;
                          width:100%;
                          border-bottom:rem-calc(2) solid #ebebeb;
                          vertical-align:bottom;
                          margin-top:rem-calc(5);
                        }
                      }
                    }
                  }
                }
              }
            }

            table.chart-table-mobile {
              border-bottom:2px solid #dcdcdc;
              margin-bottom:0;
              padding-top:rem-calc(20);
              &:last-child {
                border-bottom:none;
              }
              tbody {
                background: transparent;

                tr {
                  background: transparent;
                  td {

                    background-color: transparent;
                    padding: rem-calc(8) 0 rem-calc(3) 0;
                    &.product {
                      h3 {
                        font-family: 'Source Sans Pro', sans-serif;
                        line-height:rem-calc(23);
                        font-size:rem-calc(19);
                        font-weight:700;

                        margin:0 0 rem-calc(15) 0;
                        text-align:center;
                        a {
                          color:#298dff;

                          &:hover {
                            color:#1b4886;
                          }
                        }
                      }
                      p{
                        font-family: 'Source Sans Pro', sans-serif;
                        line-height:rem-calc(23);
                        font-size:rem-calc(19);
                        font-weight:600;
                        color:#696969;
                        margin:0;
                      }
                    }
                    &.features {
                      h3 {
                        color: #298dff;
                        font-family: 'Source Sans Pro', sans-serif;
                        font-size: rem-calc(19);
                        font-weight: 600;
                        line-height: rem-calc(23);
                        margin:0;
                      }
                      p{
                        font-family: 'Source Sans Pro', sans-serif;
                        line-height:rem-calc(23);
                        font-size:rem-calc(19);
                        font-weight:400;
                        color:#696969;
                        margin:0;
                      }
                    }
                    &.ctas {
                      padding-top:0;
                      padding-bottom:0;



                      a {
                        display:block;
                        text-align:center;
                        font-family: 'Source Sans Pro', sans-serif;
                        line-height:rem-calc(23);
                        font-size:rem-calc(19);
                        font-weight:600;
                        margin-bottom:rem-calc(25);
                        &.try-browser {
                          color:#298dff;
                          position:relative;
                          margin-top:rem-calc(30);
                          &:after {
                            position:absolute;
                            margin-left:rem-calc(7);
                            top:rem-calc(4);
                            content:' ';
                            display:inline-block;
                            width:rem-calc(27);
                            height:rem-calc(18.6);
                            background:url('../img/arrow-right-blue.svg') no-repeat center center;
                            background-size:rem-calc(27) rem-calc(18.6);
                          }

                          &:hover {
                            -webkit-filter: brightness(60%);
                            filter: brightness(60%);
                          }
                        }
                        &.trail-period {
                          border:rem-calc(2) solid #298dff;
                          border-radius:rem-calc(50);
                          height:rem-calc(50);
                          line-height:rem-calc(50);
                          color:#298dff;
                          &:hover {
                            color:#1b4886;
                            border-color:#1b4886;
                          }
                        }
                        &.buy-now {
                          background:#298dff;
                          border-radius:rem-calc(50);
                          height:rem-calc(50);
                          line-height:rem-calc(50);
                          color:#fff;
                          box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
                          &:hover {
                            background:#1b4886;
                          }
                        }
                      }

                    }
                  }

                }
              }
            }
          }
        }
        
        &.is-active {
            .accordion-title {
                  background:#ebebeb;
                  padding-bottom:0;
                  @include breakpoint(large) {
                    padding-bottom:rem-calc(30);
                  }
                  border-bottom:rem-calc(2) solid #ebebeb;
                  &:before {
                    background:url('../img/arrow-up-b.svg') no-repeat center center;
                    -ms-transform: rotate(180deg); /* IE 9 */
                    -webkit-transform: rotate(180deg); /* Safari */
                    transform: rotate(180deg);
                  }

                  h3,p {
                    color:#1b4886;
                  }
            }
        }
      }
    }
  }
}

