.search-no-results {
  .the-filter-contents {
    background:#3190FC;
  }
}
.the-filter-title {
  padding:rem-calc(40) 0;

  h1 {
    font-size:rem-calc(45);
    line-height:rem-calc(50);
    font-weight:400;
    color:#1b4886;
    @include breakpoint(large) {
      font-size:rem-calc(60);
      line-height:rem-calc(60);
    }
  }
}
.the-filter-contents {
  background:#f5f5f5;
  padding:rem-calc(30) 0;
  .nothing-found {
    font-size:rem-calc(26);
    line-height:rem-calc(31);
    text-align:center;
    font-weight:700;
    color:#fff;
  }
  .searchform {
    position:relative;
    label {
      font-size:rem-calc(16);
      line-height:rem-calc(19);
      font-weight:400;
      color:#fff;
      margin-bottom:rem-calc(10);
    }
    input[type="text"] {
      width:100%;
      display:inline;
      float:left;
      border:none;
      box-shadow:none;
      height:rem-calc(50);
      padding-right:rem-calc(150);
      &:focus {
        border:none;
        box-shadow:none;
      }
    }
    input[type="submit"] {
      width:rem-calc(150);
      position:absolute;
      right:rem-calc(0);
      text-align:center;
      display:inline;
      height:rem-calc(50);
      line-height:rem-calc(50);
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(19);
      font-weight:600;
      color:#fff;
      border:none;
      background:#1b4987 url('../img/search-icon-w.svg') no-repeat 90% center;
      background-size:rem-calc(20) rem-calc(20);


      @include breakpoint(large) {
        font-size:rem-calc(24);
      }

      &:hover {
        opacity:0.8;
        cursor:hand;
      }
    }
  }
  .filter-results {
    list-style:none;
    margin:0;
    padding:0;
    li {
      padding:rem-calc(30) 0;
      border-bottom:rem-calc(2) solid #ebebeb;
      h3 {
        font-size:rem-calc(30);
        line-height:rem-calc(35);
        font-weight:700;
        a {
          color:#1b4886;

          &:hover {
            opacity:0.7;
          }
        }

        @include breakpoint(large) {
          font-size:rem-calc(45);
          line-height:rem-calc(50);
        }
      }
      .the-date {
        font-size:rem-calc(16);
        line-height:rem-calc(19);
        font-weight:600;
        font-style:italic;
        color:#696969;
        margin-bottom:rem-calc(10);
      }
      p {
        font-size:rem-calc(19);
        line-height:rem-calc(23);
        font-weight:400;
        color:#696969;
      }
      .read-more {
        font-family: 'Source Sans Pro', sans-serif;
        font-size:rem-calc(19);
        line-height: rem-calc(23);
        font-weight: 700;
        color:#696969;
        img {
          width: rem-calc(27);
          height: rem-calc(18.6);
          margin-left: rem-calc(6);
        }
        &:hover {
          opacity:0.7;
        }
      }
    }

  }
  .pagination {
    .page-numbers {
      display:none;
    }
    .next {
      display:block;
      text-align:center;
      color: #1b4886;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(28);

      &:hover {
        background:none;
        opacity:0.7;
      }

      &:after {
        content:url('../img/arrow-up-b.svg');
        width:rem-calc(21);
        height:rem-calc(13);
        display:inline-block;
      }
    }
  }
}