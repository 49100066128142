.bundles-box {
  .main-title {
    background: #0d7eff;
    min-height: auto;

    text-align: center;
    position: relative;
    @include breakpoint(medium) {
      min-height: rem-calc(90);
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(medium) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(15);
      margin-left: rem-calc(-15);

      @include breakpoint(medium) {
        border-width: rem-calc(30);
        margin-left: rem-calc(-30);
      }
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(18);
      margin-left: rem-calc(-18);
      @include breakpoint(medium) {
        border-width: rem-calc(36);
        margin-left: rem-calc(-36);
      }
    }

    h5 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      padding:rem-calc(20) 0 rem-calc(10) 0;

      @include breakpoint(medium) {
        font-size: rem-calc(24);
        line-height: rem-calc(70);
        padding: rem-calc(10);
      }
    }
  }
  .bundles {
    background: #298dff;
    padding-top: rem-calc(30);
    padding-bottom:rem-calc(30);
    @include breakpoint(medium) {
      padding-top:rem-calc(60);

    }
    .bundle {
      background:#fff;
      padding:rem-calc(40) rem-calc(30) rem-calc(30) rem-calc(30);
      text-align:center;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
      margin-bottom:rem-calc(30);
      .bundle-icon {
        margin-bottom:rem-calc(40);
        img {
          height:rem-calc(75);
        }
      }
      h4 {
        color:#696969;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(24);
        font-weight: 700;
        line-height: rem-calc(23);
        margin-bottom:rem-calc(20);
        text-align:left;
      }
      p {
        color:#696969;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 400;
        line-height: rem-calc(23);
        min-height:rem-calc(200);
        text-align:left;
        &:empty {
          display:none;
        }
      }

      .demo {
        display:block;
        margin-top:rem-calc(25);

        font-family: 'Source Sans Pro', sans-serif;
        font-size:rem-calc(19);
        font-weight:600;
        background:#c86b1f;
        border-radius:rem-calc(50);
        height:rem-calc(50);
        line-height:rem-calc(50);
        color:#fff;
        box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
        &:hover {
          background:#c85608;
        }
      }
      .learn-more {
        font-family: 'Source Sans Pro', sans-serif;
        font-size:rem-calc(19);
        line-height: rem-calc(23);
        font-weight:600;
        color:#696969;
        img {
          width: rem-calc(27);
          height: rem-calc(18.6);
          margin-left: rem-calc(6);
        }

        &:hover {
          opacity:0.7;
        }
      }
    }
  }

  .bundles-mobile {
    background: #0d7eff;
    padding-top: 0;
    padding-bottom:rem-calc(30);

    @include breakpoint(medium) {
      padding-left:rem-calc(30);
      padding-right:rem-calc(30);
    }
    .accordion {
      .accordion-item {
        border-bottom:rem-calc(2) solid #0d7eff;
        .accordion-title {
          background:#298dff;
          color:#fff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(24);
          font-weight: 700;
          border:none;

          height:rem-calc(78);
          line-height:rem-calc(78);
          padding-top:0;
          padding-bottom:0;
          padding-left:rem-calc(30);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &[aria-expanded="true"] {
            background:#298dff;
            color:#fff;
            border-bottom:rem-calc(2) solid #298dff;

            &:before {

              -ms-transform: rotate(180deg); /* IE 9 */
              -webkit-transform: rotate(180deg); /* Safari */
              transform: rotate(180deg);


            }
          }
          &:before {
            background:url('../img/arrow-up-w.svg') no-repeat center center;
            top:0;
            content:'';
            width:rem-calc(20);
            height:auto;
            margin-top:0;
            position:absolute;
            bottom:0;
            background-size:rem-calc(20) rem-calc(12);
            -webkit-transition: all 0.2s; /* Safari */
            transition: all 0.2s;
            @include breakpoint(large) {
              width:rem-calc(31);
              background-size:rem-calc(31) rem-calc(19);
            }
          }
        }
        .accordion-content {
          background:#298dff;
          border:none;
          padding:rem-calc(30);
          padding-top:0;
          text-align:center;

          p {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height:rem-calc(23);
            color:#fff;
            margin-bottom:rem-calc(20);
            text-align:left;
            &:empty {
              display:none;
            }
          }

          .white-cta {
            display:block;

            font-family: 'Source Sans Pro', sans-serif;
            font-size:rem-calc(19);
            font-weight:600;
            margin-bottom:rem-calc(20);
            background:#fff;
            border-radius:rem-calc(50);
            height:rem-calc(50);
            line-height:rem-calc(50);
            margin-top:rem-calc(20);
            color:#298dff;
            box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
          }

          .learn-more {
            font-family: 'Source Sans Pro', sans-serif;
            font-size:rem-calc(19);
            line-height: rem-calc(23);
            font-weight:600;
            color:#fff;

            img {
              width: rem-calc(27);
              height: rem-calc(18.6);
              margin-left: rem-calc(6);
            }
          }
        }
      }
    }
  }
}