.single-case-study {
  .menu-wrapper {
    #top-nav {
    ul {
      .resources-menu-item {
        & > a {
          position: relative;
          color: #298dff;
          &:before {
            display: block;
            position: absolute;
            bottom: rem-calc(17);
            left: 0;
            right: 0;
            height: 1px;
            border-bottom: 1px solid #298dff;
            content: '';
          }

          &:hover {
            &:before {
              display:none;
            }
          }
        }
      }
    }
  }
}
}
.single-post {
  .menu-wrapper {
    #top-nav {
      ul {
        .blog-menu-item {
          & > a {
            position: relative;
            color: #298dff;
            &:before {
              display: block;
              position: absolute;
              bottom: rem-calc(17);
              left: 0;
              right: 0;
              height: 1px;
              border-bottom: 1px solid #298dff;
              content: '';
            }

            &:hover {
              &:before {
                display:none;
              }
            }
          }
        }
      }
    }
  }
}
.single-news {
  .menu-wrapper {
    #top-nav {
      ul {
        .about-menu-item {
          & > a {
            position: relative;
            color: #298dff;
            &:before {
              display: block;
              position: absolute;
              bottom: rem-calc(17);
              left: 0;
              right: 0;
              height: 1px;
              border-bottom: 1px solid #298dff;
              content: '';
            }

            &:hover {
              &:before {
                display:none;
              }
            }
          }
        }
      }
    }
  }
}
.menu-wrapper {
  height:rem-calc(60);
  position:relative;
  background:#fff;
  z-index:9999;
  &.sticky-header {
    position: fixed;
    top : 0;
    left : 0;
    right : 0;
    box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
  }
  .left-menu {
    float:left;
    width:70%;

  }
  .left-menu-home {
    float:left;
    width:68%;
  }
  .right-menu {
    float:right;
    width:30%;
    text-align:right;
  }
  .right-menu-home {
    float:right;
    width:32%;
    text-align:right;
  }
  .menu-logo {
    float:left;
    margin-top:rem-calc(15);
    margin-right:rem-calc(15);
    a {
      img {
        width:rem-calc(152.1);
        height:rem-calc(30.4);
      }
      
      &:hover {
          opacity: 0.7;
      }
    }
  }
  #top-nav > ul{
    margin:0;
    padding:0;
    display:inline-block;
    list-style: none;
  }
  #top-nav > ul > li{
    display: inline-block;
    line-height:rem-calc(60);
    padding-right:rem-calc(15);

    &.current_page_item {
      > a {
        color: #298dff;
      }
    }


  }
  #top-nav > ul > li.thick > a {
    color:#1b4886;
    font-weight:700;

  }
  #top-nav > ul.thin {
    margin-left:0;
  }
  #top-nav > ul.thin > li > a {
    font-weight:400;
  }
  #top-nav > ul > li > a{
    display: block;
    color:#696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size:rem-calc(16);
    font-weight:600;
  }
  #top-nav {
      & > ul {
          li:hover,
          li.current-menu-parent {
              a {
                    color: #298dff;
              }
              
              .dropdown {
                  a {
                      color: #fff;
                  }
                    a:hover{
                            color: #ebebeb;
                      }
              }
          }
          
          & > li.current-menu-parent,
          & > li.current-menu-item {
              & > a {
                  position: relative;
                  color: #298dff;
                  
                  &:before {
                      display: block;
                      position: absolute;
                      bottom: rem-calc(17);
                      left: 0;
                      right: 0;
                      height: 1px;
                      border-bottom: 1px solid #298dff;
                      content: '';
                  }

                &:hover {
                  &:before {
                    display:none;
                  }
                }
              }

            &:hover {
              & > a {
                &:before {
                  display: none;
                }
              }
            }
          }
      }
  }

  /* Dropdown */
  li.menu-item-has-children{
    &.opened {
      > a {
        &:after {
          opacity: 1;
        }
      }

    }
    &:hover {
      ul.dropdown {
        opacity: 1;
        display:block;
      }
      > a {
        &:after {
          opacity: 1;
        }
      }
    }
    > a {
      position: relative;

      &:after {
        bottom: rem-calc(-1);
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        opacity: 0;
      }

      &:after {
        border-color: rgba(41, 141, 255, 0);
        border-bottom-color: #298dff;
        border-width: rem-calc(15);
        margin-left: rem-calc(-15);
      }
    }

    ul.dropdown {
      opacity: 0;
      display:none;
      position: absolute;
      background: #298dff;
      top: rem-calc(60);
      padding:rem-calc(30);
      z-index:999;
      margin:0;
      transform: translate(-50%,0);
      left:50%;
      box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
      width:rem-calc(250);
      list-style:none;

      li {

        a {
          color:#fff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(16);
          font-weight:600;
          display:block;
          line-height:1;
          margin-bottom:rem-calc(8);
        }
        &:last-child {
          a {
            margin-bottom:0;
          }
        }
      }
    }

  }

  #header-nav-1 {
    position: relative;

    li.menu-item-has-children {
      position: relative;
    }
  }
  .search-btn {
    display:inline-block;
    width:rem-calc(18.8);
    height:rem-calc(18.7);
    margin-left:rem-calc(10);
    position: relative;

    &:after,
    &:before {
      visibility:hidden;
      bottom:rem-calc(-24);
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(41, 141, 255, 0);
      border-bottom-color: #298dff;
      border-width: rem-calc(15);
      margin-left: rem-calc(-15);
    }
    &:before {
      border-color: rgba(41, 141, 255, 0);
      border-bottom-color: #298dff;
      border-width: rem-calc(20);
      margin-left: rem-calc(-20);
    }

    &.opened {
      &:after,
      &:before {
        visibility: visible;
      }
    }
    
    &:hover {
        img {
            opacity: 0.8;
        }
    }
  }
  .menu-cta {
    display:inline-block;
    width:rem-calc(130);
    height:rem-calc(30);
    background:#298dff;
    color:#fff;
    line-height:rem-calc(30);
    border-radius:rem-calc(50);
    text-align:center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size:rem-calc(16);
    font-weight:700;
    box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
    margin-left:rem-calc(20);

    &:hover {
      background:#153C6A;
    }
  }
}
.search-form {
  background:#298dff;
  position:absolute;
  top:rem-calc(60);
  left:0;
  right:0;
  height:rem-calc(80);
  display:none;
  z-index:999;
  box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
  form {
    padding:rem-calc(20) rem-calc(150) rem-calc(20) 0;
    .search-input {
      width:100%;
      display:inline;
      float:left;
      border:none;
      &:focus {
        border:none;
        box-shadow:none;
      }
    }
    .search-submit {
      background:#1b4987;
      width:rem-calc(150);
      position:absolute;
      text-align:center;
      display:inline;
      height:rem-calc(40);
      line-height:rem-calc(40);
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(24);
      font-weight:600;
      color:#fff;

      img {
        width:rem-calc(20);
        height:rem-calc(20);
        margin-left:rem-calc(6);
      }

      &:hover {
        opacity:0.8;
        cursor:hand;
      }
    }
  }
}

.mobile-header {
  height: rem-calc(75);
  position: fixed;
  left:0;
  right:0;
  top:0;
  z-index:9999;
  box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 0 rem-calc(30);
  .mobile-logo {
    line-height: rem-calc(75);
    display: inline;
    a {
      img {
        width: rem-calc(155.3);
      }
    }
  }
  .ham-button {
    float:right;


    .hamburger {
      width: rem-calc(30);
      height: rem-calc(28);
      position: relative;
      margin-top:rem-calc(15);
      @include breakpoint(medium) {
        margin-top:rem-calc(20);
      }
      @include breakpoint(large) {
        margin-top:rem-calc(25);
      }
      span {
        border-top: rem-calc(2) solid #298dff;
        display: block;
        opacity: 1;
        position: absolute;
        width: 100%;
        top: 0;
        -webkit-transition: all .6s ease;
        -moz-transition: all .6s ease;
        -o-transition: all .6s ease;
        transition: all .6s ease;

        &:first-of-type {
          top: 12px;
        }

        &:last-of-type {
          top: 24px;
        }

        &:after {
          display: none;
        }
      }
    }
    .expanded & {

      .hamburger {
        @include breakpoint(medium) {
          margin-top:rem-calc(20);
        }
        span:first-of-type {
          -ms-transform: rotate(45deg); /* IE 9 */
          -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
          transform: rotate(45deg);
          top: rem-calc(20);
        }

        span:last-of-type {
          -ms-transform: rotate(-45deg); /* IE 9 */
          -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
          transform: rotate(-45deg);
          top: rem-calc(20);
        }
      }
    }
  }
}

.mobile-nav {
  position: fixed;
  z-index: 9999;
  top: rem-calc(75);
  right: 0;
  background: #fff;
  overflow-x: hidden;
  width: 100%;
  margin-right:-100%;

  .expanded & {
    margin-right:0;
    position: absolute;
  }

  .mobile-search {
    background:#298dff;
    padding:rem-calc(25) rem-calc(30);

    form {
      padding:0 rem-calc(60) 0 0;
      margin:0;
      overflow:hidden;
      .search-input {
        width:100%;
        display:inline;
        float:left;
        border:none;
        height:rem-calc(50);
        margin:0;
        &:focus {
          border:none;
          box-shadow:none;
        }
      }
      .search-submit {
        background:#1b4987;
        width:rem-calc(60);
        position:absolute;
        text-align:center;
        display:inline;
        height:rem-calc(50);
        line-height:rem-calc(50);


        img {
          width:rem-calc(20);
          height:rem-calc(20);
        }
      }
    }
  }


  .mobile-menu {
    > ul {
      > li {
        background:#1b4886;

        > a {
          display:block;
          margin-left:rem-calc(30);
          margin-right:rem-calc(30);
          padding:rem-calc(15) 0;
          line-height:rem-calc(26);
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(24);
          font-weight:700;
          color:#fff;
          border-bottom:rem-calc(2) solid #153c6a;

        }

        &[aria-expanded="true"] {
          background:#fff;

          > a {

            color:#1b4886;
            border-bottom:rem-calc(2) solid #f5f5f5;
          }
        }


        &.menu-item-has-children {

          > a {
            position:relative;
            .arrow {
              background-image:url('../img/arrow-up-w.svg');
              background-size:rem-calc(20) rem-calc(12);
              background-repeat:no-repeat;
              background-position:center center;
              border:none;
              width:rem-calc(40);
              height:rem-calc(40);
              position:absolute;
              right:rem-calc(-10);
              top:rem-calc(11);
            }
            &:after {
              content:'';
              display:none;
            }
          }
        }
        &.menu-item-has-children[aria-expanded="true"] {

          > a {
            .arrow {
              background-image:url('../img/arrow-down-b.svg');
              transform:none;
            }
            &:after {
              content:'';
              display:none;
            }
          }
        }

        .is-accordion-submenu {
          background:#fff;
          margin:0;
          padding:0;
          li {
            a {
              margin-left:rem-calc(30);
              margin-right:rem-calc(30);
              line-height:rem-calc(26);
              font-family: 'Source Sans Pro', sans-serif;
              font-size:rem-calc(24);
              font-weight:400;
              color:#696969;
              padding:rem-calc(15) 0;
              border-bottom:rem-calc(2) solid #f5f5f5;
            }
          }
        }
      }
    }
  }
  .cta-button {
    background:#fff;
    padding:rem-calc(25) rem-calc(30);

    a {
      background:#298dff;
      color:#fff;

      display:block;
      height:rem-calc(50);
      font-family: 'Source Sans Pro', sans-serif;
      line-height:rem-calc(50);
      border-radius:rem-calc(50);
      text-align:center;
      font-size:rem-calc(24);
      font-weight:700;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);

      &:hover {
        background:#153C6A;
      }
    }
  }
}