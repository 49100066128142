.product-details {
  .main-title {
    background:#f5f5f5;
    min-height: auto;
    text-align: center;
    @include breakpoint(large) {
      background: #ebebeb;
      min-height: rem-calc(90);
      position: relative;
    }
      &:after,
      &:before {
        display:none;
        @include breakpoint(large) {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          display:block;
        }
      }

      &:after {
        border-color: rgba(21, 60, 106, 0);
        border-top-color: #ebebeb;
        border-width: rem-calc(30);
        margin-left: rem-calc(-30);
      }
      &:before {
        border-color: rgba(21, 60, 106, 0);
        border-top-color: #ebebeb;
        border-width: rem-calc(36);
        margin-left: rem-calc(-36);
      }



      h5 {
        color:#696969;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(24);
        font-weight: 700;
        line-height: rem-calc(36);
        padding: rem-calc(10);

        @include breakpoint(large) {
          font-size: rem-calc(24);
          line-height: rem-calc(70);
          padding: rem-calc(10);
        }
      }
    }

  .chart-accordion {
    background:#f5f5f5;
    padding-top:0;
    padding-bottom:rem-calc(30);
    @include breakpoint(large) {
      padding-top:rem-calc(60);
      padding-bottom:rem-calc(60);
    }
    .chart-container {
      position:relative;
      margin-top:rem-calc(30);
      &:before {
        background: #fff;
        position: absolute;
        top: rem-calc(-30);
        bottom: rem-calc(-30);
        right: 0;
        width: 65%;
        display: block;
        content: '';
        z-index: 1;
        box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
      }
      table.chart-table {
        position: relative;
        z-index: 2;
        tbody {
          background: transparent;

          tr {
            background: transparent;

            td {
              vertical-align: top;
              background-color: transparent;
              padding-top:rem-calc(5);
              padding-bottom:0;

              &.key {
                color: #298dff;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(16);
                font-weight: 600;
                line-height: rem-calc(19);
                padding-left:0;
                padding-right:0;

                &:before {
                  content:' ';
                  display:block;
                  width:100%;
                  border-bottom:rem-calc(2) solid #ebebeb;
                  vertical-align:bottom;
                  margin-bottom:rem-calc(5);
                }


              }
              &.value {
                padding-left:rem-calc(40);
                padding-right:rem-calc(30);
                color: #696969;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(16);
                font-weight: 400;
                line-height: rem-calc(19);
                &:before {
                  content:' ';
                  display:block;
                  width:100%;
                  border-bottom:rem-calc(2) solid #ebebeb;
                  vertical-align:bottom;
                  margin-bottom:rem-calc(5);
                }
              }
            }
          }
        }
      }
    }
    .promotion-question {
      width:65%;
      text-align:left;
      float:right;
      clear:both;
      margin-top:rem-calc(40);
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(19);
      font-weight: 600;
      line-height: rem-calc(23);

      a {
        color: #696969;
        text-decoration:underline;

        &:hover {
          opacity:0.7;
        }
      }
    }
    .product-accordion {
      .accordion {
        .accordion-item {
          .accordion-title {
            background:#1b4886;
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(24);
            font-weight: 700;
            border:none;
            border-bottom:rem-calc(2) solid #fff;
            height:auto;
            line-height:rem-calc(29);
            padding-top:rem-calc(20);
            padding-bottom:rem-calc(20);
            padding-left:rem-calc(30);
            padding-right:rem-calc(40);
            position:relative;
            @include breakpoint(large) {
              height:rem-calc(60);
              line-height:rem-calc(60);
              padding-top:0;
              padding-bottom:0;
            }
            &[aria-expanded="true"] {
              background:#ebebeb;
              color:#1b4886;
              border-bottom:rem-calc(2) solid #ebebeb;

              &:before {
                background:url('../img/arrow-up-b.svg') no-repeat center center;
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari */
                transform: rotate(180deg);

              }
            }
            &:before {
              background:url('../img/arrow-up-w.svg') no-repeat center center;
              height:auto;
              width:rem-calc(20);
              background-size:rem-calc(20) rem-calc(12);
              position:absolute;
              -webkit-transition: all 0.2s; /* Safari */
              transition: all 0.2s;
              margin-top:0;
              top:0;
              bottom:0;
              content:'';
              @include breakpoint(large) {
                height:rem-calc(60);
                width:rem-calc(31);
                background-size:rem-calc(31) rem-calc(19);
              }
            }
          }
          .accordion-content {
            background:#ebebeb;
            border:none;
            padding:rem-calc(30);
            padding-top:0;
            .part-wysiwyg {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(19);
              font-weight: 400;
              line-height:rem-calc(23);
              color:#696969;
              h4 {
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(19);
                font-weight: 700;
                line-height:rem-calc(23);
                margin:0;
                color:#696969;
              }
              p {
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(19);
                font-weight: 400;
                line-height:rem-calc(23);
                color:#696969;

              }
               a:not([class="buy-now"]) {
                color:#696969;
                text-decoration:underline;
                font-weight:600;
              }
            }
            .part-buttons-set {
              .dbutton-block {
                margin-bottom:rem-calc(10);
                h3 {
                  font-family: 'Source Sans Pro', sans-serif;
                  font-size: rem-calc(19);
                  font-weight: 700;
                  line-height:rem-calc(23);
                  color:#696969;
                }
                p {
                  font-family: 'Source Sans Pro', sans-serif;
                  font-size: rem-calc(19);
                  font-weight: 400;
                  line-height:rem-calc(23);
                  color:#696969;
                  a {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size:rem-calc(19);
                    line-height: rem-calc(23);
                    font-weight: 600;
                    color:#696969;
                    img {
                      width: rem-calc(27);
                      height: rem-calc(18.6);
                      margin-left: rem-calc(6);
                    }

                    &:hover {
                      opacity:0.7;
                    }
                  }
                }
                .cta {
                  display:block;
                  text-align:center;
                  font-family: 'Source Sans Pro', sans-serif;
                  font-size:rem-calc(19);
                  font-weight:600;
                  margin-bottom:rem-calc(20);
                  background:#298dff;
                  border-radius:rem-calc(50);
                  height:rem-calc(50);
                  line-height:rem-calc(50);
                  color:#fff;
                  box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
                  &:hover {
                    background:#1b4886;
                  }
                }
              }
            }
            .part-comparison {
              p {
                color:#696969;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(19);
                font-weight: 400;
                line-height: rem-calc(23);
              }
              table {
                border-collapse: collapse;
                border:none;
                thead {
                  background: transparent;
                  border:none;
                  tr {

                    th {
                      color:#1b4886;
                      font-family: 'Source Sans Pro', sans-serif;
                      font-size: rem-calc(16);
                      font-weight: 700;
                      line-height: rem-calc(28);
                      border-bottom:rem-calc(2) solid #e0e0e0;
                    }
                  }
                }
                tbody {
                  background: transparent;
                  border:none;

                  tr {
                    background: transparent;



                    td {
                      vertical-align: middle;
                      background-color: transparent;
                      padding-top:rem-calc(5);
                      padding-bottom:rem-calc(5);
                      line-height:1.2;
                      border-bottom:rem-calc(2) solid #e0e0e0;

                      &.partner-feature {
                        color:#1b4886;
                        font-family: 'Source Sans Pro', sans-serif;
                        font-size: rem-calc(16);
                        font-weight: 400;
                        line-height: rem-calc(19);

                      }
                      &.partner-check {
                        text-align:center;

                        .checked-feature {
                          width:rem-calc(12.2);
                          height:rem-calc(12.9);
                          display:inline-block;
                          background:url('../img/check-db.svg') no-repeat center center;
                        }

                        &:before {
                          content:' ';
                          border-top:rem-calc(2) solid #ebebeb;
                          display:block;
                          position:relative;
                          top:rem-calc(-10);
                        }
                      }
                      &.partner-type-cta {

                        a {
                          display:block;
                          text-align:center;
                          font-family: 'Source Sans Pro', sans-serif;
                          line-height:rem-calc(50);
                          font-size:rem-calc(19);
                          font-weight:600;
                          margin-top:rem-calc(25);
                          color:#fff;
                          height:rem-calc(50);
                          border-radius:rem-calc(50);
                        }

                        &:before {
                          content:' ';
                          border-top:rem-calc(2) solid #ebebeb;
                          display:block;
                          position:relative;
                          top:rem-calc(-10);
                        }
                      }

                      &.partner-type-cta-empty {



                        &:before {
                          content:' ';
                          border-top:rem-calc(2) solid #dbdddc;
                          display:block;
                          position:relative;
                          top:rem-calc(-10);
                          right:rem-calc(10);
                        }
                      }
                    }
                  }
                }
              }
            }
            table.chart-table {
              position: relative;
              z-index: 2;
              tbody {
                background: transparent;

                tr {
                  background: transparent;
                  td {
                    vertical-align: top;
                    background-color: transparent;
                    padding-top:rem-calc(5);
                    padding-bottom:rem-calc(5);
                    &.key {
                      color: #298dff;
                      font-family: 'Source Sans Pro', sans-serif;
                      font-size: rem-calc(19);
                      font-weight: 600;
                      line-height: rem-calc(23);
                      padding-left:0;
                    }
                    &.value {
                      padding-left:0;
                      color: #696969;
                      font-family: 'Source Sans Pro', sans-serif;
                      font-size: rem-calc(19);
                      font-weight: 400;
                      line-height: rem-calc(23);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .buy-now {
    display: block;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: rem-calc(19);
    font-weight: 600;
    background:#696969;
    border-radius: rem-calc(50);
    height: rem-calc(50);
    line-height: rem-calc(50);
    color: #fff;
    box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
    text-align: center;
    position:relative;
    &:after {
      background:url('../img/download-w.svg') no-repeat center center;
      width:rem-calc(12.1);
      height:rem-calc(16.2);
      content:'';
      display:inline-block;
      margin-left:rem-calc(5);
      margin-bottom:rem-calc(-3);

    }
    &:hover {
      background:#444;
      color:#eee;
    }
    &:focus {
      color:#eee;
    }
  }


}