.report-highlights {
  .main-title {
    background: #0d7eff;
    min-height: auto;
    text-align: center;
    position: relative;
    @include breakpoint(large) {
      min-height: rem-calc(90);
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(large) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(30);
      margin-left: rem-calc(-30);
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(36);
      margin-left: rem-calc(-36);
    }

    h5 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(36);
      padding: rem-calc(10);

      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: rem-calc(70);
        padding: rem-calc(10);
      }
    }
  }
  .highlights {
    background: #298dff;
    padding-top: rem-calc(30);
    padding-bottom:rem-calc(30);
    @include breakpoint(large) {
      padding-top: rem-calc(60);
      padding-bottom:rem-calc(60);
    }
    .col-text {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(19);
      font-weight: 400;
        p {
          line-height:rem-calc(23);
          padding-left:rem-calc(20);
        }

        ul {
          li {
            line-height:rem-calc(23);
            margin-bottom:rem-calc(15);
          }
        }
     }
    .movr-sub-form {
      margin-top:rem-calc(30);
      margin-bottom:0;
      .movr-subscription-form-label {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(18);
        font-weight: 700;
        line-height:rem-calc(24);
        float:none;
        text-align:center;
        margin-bottom:rem-calc(15);
        @include breakpoint(large) {
          line-height:rem-calc(50);
          float:right;
          font-size: rem-calc(24);
          text-align:right;
          margin:0;
        }
      }
    }

  }
}
.movr-widget {
  .highlights {
    .movr-sub-form {
      margin-top:0;
    }
  }
}