.partner-program {
  padding:rem-calc(30) 0 rem-calc(30) 0;
  @include breakpoint(large) {
    padding:rem-calc(30) 0 rem-calc(60) 0;
  }
  position:relative;
  .page-title {
    h1 {
      margin-bottom:rem-calc(16);
      line-height:rem-calc(45);
      font-size:rem-calc(40);
      font-weight:400;
      color:#1b4886;

      @include breakpoint(medium) {
        line-height:rem-calc(60);
        font-size:rem-calc(60);
      }
    }
  }
  .page-intro {
    margin-bottom:rem-calc(20);

    p {
      line-height:rem-calc(23);
      font-size:rem-calc(19);
      font-weight:400;
      color:#696969;
      margin-bottom:rem-calc(30);
      strong {
        font-weight:600;
      }

      &:empty {
        display:none;
      }
    }

  }

  .cta-button {

    .become-partner {
      display:block;
      text-align:center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(19);
      font-weight:600;
      background:#1b4886;
      border-radius:rem-calc(50);
      height:rem-calc(50);
      line-height:rem-calc(50);
      color:#fff;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
      &:hover {
        background:#1b55b0;
      }
    }
  }

}
