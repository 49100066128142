body {
  .signup-form {
    padding: rem-calc(60) 0;
    background: #f5f5f5;
    .gform_wrapper {
      margin: 0;
      input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
      select {
        padding-left:rem-calc(15);
        height:rem-calc(50);
      }
      form {
        position: relative;
        z-index: 99;
        padding: 0;
        .validation_error {
          border:none;
          color:red;
          font-size: rem-calc(19);
          font-weight: 700;
          line-height: rem-calc(23);
        }
        .gform_body {
          .gform_fields {
            .gf_right_half + li.gsection {
              padding:0;
            }
            .gfield {
              position:relative;
              .top_label {
                .ginput_container {
                  margin:0;
                }
              }
              .ginput_container {
                input {
                  margin: 0;
                  box-shadow: none;
                  border:none;
                  color:#298dff;
                  padding-left:rem-calc(8);
                }
              }
              .gfield_label {
                font-size: rem-calc(19);
                font-weight: 700;
                line-height: rem-calc(23);
                color: #696969;
                margin-bottom:rem-calc(10);
                .gfield_required {
                  color: #696969;
                }
              }
              .gfield_description {

                background: #fff;
                padding: rem-calc(30) rem-calc(30) rem-calc(60) rem-calc(30);
                font-size: rem-calc(14);
                font-weight: 400;
                line-height: rem-calc(17);
                .gds_description {
                  height: rem-calc(193);
                  overflow-y: auto;
                  padding-right:rem-calc(30);
                }
                .open-window {
                  float:right;
                  margin-top:rem-calc(20);
                  font-size: rem-calc(16);
                  font-weight: 400;
                  line-height: rem-calc(22);
                  color:#696969;
                  border-bottom:rem-calc(1) solid #696969;
                }
              }
              .ginput_container_text {
                margin: 0;

                input {
                  margin: 0;
                  box-shadow: none;
                  border:none;
                  color:#298dff;

                }
              }
              .ginput_container_multiselect {
                &:after {
                  @include breakpoint(small only) {
                    content: url('../img/arrow-down-g.svg');
                    width: rem-calc(20);
                    height: rem-calc(12);
                    display:block;
                    right:rem-calc(15);
                    top:rem-calc(45);
                    position:absolute;
                  }
                }
                select {
                  height:rem-calc(50) !important;
                  border-color:#fff;
                  box-shadow:none;
                  background:#fff;
                  background-size: rem-calc(20) rem-calc(12);
                  @include breakpoint(medium) {
                    background:#fff url('../img/arrow-down-g.svg') no-repeat 97% center;
                    background-size: rem-calc(20) rem-calc(12);
                  }
                }
                .chosen-container {
                  max-width:100%;
                  .chosen-choices {
                    height:rem-calc(50) !important;
                    border-color:#fff;
                    box-shadow:none;
                    background:#fff;
                    background-size: rem-calc(20) rem-calc(12);
                    @include breakpoint(medium) {
                      background:#fff url('../img/arrow-down-g.svg') no-repeat 97% center;
                      background-size: rem-calc(20) rem-calc(12);
                    }
                    .search-field {
                      input {
                        line-height:rem-calc(50);
                        height:rem-calc(50);
                      }
                    }
                    .search-choice {
                      height:rem-calc(40);
                      margin:rem-calc(5);
                      background:#ebebeb;
                      line-height:rem-calc(30);
                      border-radius:0;
                      color:#298dff;

                      a {
                        top:rem-calc(15);
                      }
                    }
                  }
                  .chosen-drop {
                    border-color:#ebebeb;
                    box-shadow:none;
                  }
                }
              }
              .ginput_container {
                margin:0;

                input {
                  padding-left:rem-calc(8);
                }
              }
              .ginput_container_textarea {
                textarea {
                  border:none;
                  background: #fff;
                  padding: rem-calc(5);
                  font-size: rem-calc(14);
                  font-weight: 400;
                  line-height: rem-calc(17);
                  box-shadow:none;
                  color:#298dff;
                  margin: 0;
                  width:100%;
                }


              }
              .ginput_container_select {
                margin: 0;
                select {
                  background-image: url('../img/arrow-down-g.svg');
                  background-position: 99% center;
                  background-size: rem-calc(20) rem-calc(12);
                  border:none;
                  color:#298dff;
                  margin-bottom:0;
                  padding-right:rem-calc(15);
                  option {
                    color:#696969;
                  }
                }
              }

              .ginput_container_checkbox {
                ul {
                  li {
                    label {
                      font-family: 'Source Sans Pro', sans-serif;
                      font-size: rem-calc(19);
                      font-weight: 600;
                      color: #696969;
                    }
                    padding:rem-calc(15) 0 !important;
                    [type="checkbox"]:not(:checked),
                    [type="checkbox"]:checked {
                      position: absolute;
                      left: -9999px;

                    }
                    [type="checkbox"]:not(:checked) + label,
                    [type="checkbox"]:checked + label {
                      position: relative;
                      padding-left: rem-calc(25);
                      cursor: pointer;
                      @include breakpoint(medium) {
                        padding-left: rem-calc(35);
                      }
                    }

                    /* checkbox aspect */
                    [type="checkbox"]:not(:checked) + label:before,
                    [type="checkbox"]:checked + label:before {
                      content: '';
                      position: absolute;
                      left: 0;
                      top: rem-calc(2);
                      width: rem-calc(25);
                      height: rem-calc(25);
                      border: 2px solid #696969;
                      background: none;


                    }
                    /* checked mark aspect */
                    [type="checkbox"]:not(:checked) + label:after,
                    [type="checkbox"]:checked + label:after {
                      content: '×';
                      position: absolute;
                      top: rem-calc(-16);
                      left: 4px;
                      font-size: rem-calc(34);
                      color: #298dff;
                      line-height: 1.75;
                      -webkit-transition: all .2s;
                      -moz-transition: all .2s;
                      -ms-transition: all .2s;
                      transition: all .2s;
                    }
                    /* checked mark aspect changes */
                    [type="checkbox"]:not(:checked) + label:after {
                      opacity: 0;
                      -webkit-transform: scale(0);
                      -moz-transform: scale(0);
                      -ms-transform: scale(0);
                      transform: scale(0);
                    }
                    [type="checkbox"]:checked + label:after {
                      opacity: 1;
                      -webkit-transform: scale(1);
                      -moz-transform: scale(1);
                      -ms-transform: scale(1);
                      transform: scale(1);
                    }
                    /* disabled checkbox */
                    [type="checkbox"]:disabled:not(:checked) + label:before,
                    [type="checkbox"]:disabled:checked + label:before {
                      box-shadow: none;
                      border-color: #bbb;
                      background-color: #ddd;
                    }
                    [type="checkbox"]:disabled:checked + label:after {
                      color: #999;
                    }
                    [type="checkbox"]:disabled + label {
                      color: #aaa;
                    }
                    /* accessibility */
                    [type="checkbox"]:checked:focus + label:before,
                    [type="checkbox"]:not(:checked):focus + label:before {
                      border: 1px solid #fff;
                    }
                  }
                }
              }
              .ginput_complex {

                .ginput_right {
                  width:100%;
                  margin-left:0;
                  margin-top:rem-calc(24);
                  @include breakpoint(medium) {
                    margin-top:0;
                    width:49.3%;
                    margin-left:rem-calc(8);
                  }
                }

                label {
                  font-size: rem-calc(19);
                  font-weight: 700;
                  line-height: rem-calc(23);
                  color: #696969;
                  margin-bottom:rem-calc(10);
                  .gfield_required {
                    color: #696969;
                  }
                }

              }
              &.field-terms {
                label {
                  display:none;
                }
              }
              &.field_captcha {
                label {
                  display:none;
                }
              }
              &.hide-label {
                > label {
                  display:none;
                }
              }
            }
            .gsection {
              border-bottom:none;
              margin:0;
              padding:0;
              h2 {
                color:#298dff;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(24);
                font-weight: 700;
                line-height:rem-calc(26);
                margin:0;
                padding:0;
              }
              &.divider-only {
                padding:0;
                margin:0 !important;
              }
            }
            .gfield_error {
              background:none;
              border:none;
              padding-top:0;
              .validation_message {
                display:none;
                padding:0;
                height:0;
              }
              .gfield_label {
                color:#ff0000;
                .gfield_required {
                  color:#ff0000;
                }
              }
              label {
                margin-top:0;
                padding-top:0;
                color:#ff0000;
              }

              input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), .gform_wrapper li.gfield_error textarea {

                border: rem-calc(1) solid red;

              }
            }
          }
          }
          .gform_footer {
            input.button {
              padding: 0;
              margin: 0;
              width: 100%;
              height: rem-calc(50);
              line-height: rem-calc(50);
              border-radius: rem-calc(50);
              background: #298dff;
              text-align: center;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(19);
              font-weight: 600;
              color: #fff;
              box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
              &:hover {
                background: #1b4886;
              }
            }
          }
      }
    }
  }
}
