.product-resources {
  .main-title {
    background:#ebebeb;
    min-height: auto;
    text-align: center;
    @include breakpoint(medium) {
      min-height: rem-calc(90);
      position: relative;
    }
    &:after,
    &:before {

      display:none;
      @include breakpoint(medium) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(30);
      margin-left: rem-calc(-30);
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(36);
      margin-left: rem-calc(-36);
    }

    h5 {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(36);
      padding: rem-calc(10);

      @include breakpoint(medium) {
        font-size: rem-calc(24);
        line-height: rem-calc(70);
        padding: rem-calc(10);
      }
    }
  }
  .resources {
    background: #ebebeb;
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(60);

    @include breakpoint(large) {
      background: #f5f5f5;
    }
    .columns {

      border-top:rem-calc(2) solid #ebebeb;
      border-left:none;

      &:first-child {
        border-left:none;
        border-top:none;
      }

      @include breakpoint(medium) {
        border-left:rem-calc(2) solid #ebebeb;
        border-top:none;
      }
    }
    .resource {
      padding: 0 rem-calc(30);
      .resource-icon {
        text-align: center;
        margin-bottom: rem-calc(25);
        img {
          height: rem-calc(89);

          @include breakpoint(small only) {
            display: none;
          }
        }
      }
        .resource-details {
          min-height: rem-calc(175);
          h3 {
            font-size: rem-calc(24);
            font-weight: 700;
            line-height: rem-calc(26);
            color: #1b4886;
          }
          p {
            font-size: rem-calc(19);
            font-weight: 400;
            line-height: rem-calc(23);
            color: #1b4886;
          }
        }
        .resource-cta {
          text-align: right;
          a {
            font-size: rem-calc(19);
            font-weight: 700;
            line-height: rem-calc(23);
            color: #1b4886;
            img {
              width: rem-calc(27);
              height: rem-calc(18.6);
              margin-left: rem-calc(6);
            }
          }
        }
      &:hover {
        opacity:0.8;
        cursor:pointer;
      }
    }
  }

  .resources-mobile {
    background: #ebebeb;
    padding-top: rem-calc(0);
    padding-bottom: rem-calc(30);
    @include breakpoint(medium) {
      padding-left:rem-calc(30);
      padding-right:rem-calc(30);
    }
    .accordion {
      .accordion-item {
        border-bottom:rem-calc(2) solid #ebebeb;
        .accordion-title {
          background:#f5f5f5;
          color:#1b4886;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(24);
          font-weight: 700;
          border:none;

          height:rem-calc(78);
          line-height:rem-calc(78);
          padding-top:0;
          padding-bottom:0;
          padding-left:rem-calc(30);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position:relative;
          &:before {

            content:'';

          }


          &.full {
            &:before {

              background:url('../img/arrow-up-b.svg') no-repeat center center;
              top:0;
              content:'';
              width:rem-calc(20);
              height:auto;
              margin-top:0;
              position:absolute;
              bottom:0;
              background-size:rem-calc(20) rem-calc(12);
              -webkit-transition: all 0.2s; /* Safari */
              transition: all 0.2s;
              @include breakpoint(medium) {
                width:rem-calc(31);
                background-size:rem-calc(31) rem-calc(19);
              }

            }
            &[aria-expanded="true"] {



              &:before {
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari */
                transform: rotate(180deg);

              }
            }
          }
        }

        .accordion-content {
          background:#f5f5f5;
          border:none;
          padding:rem-calc(30);
          padding-top:0;

          h4 {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 700;
            line-height:rem-calc(23);
            margin:0;
            color:#696969;
          }
          p {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height:rem-calc(23);
            color:#696969;
          }
          .resource-cta {
            text-align: right;
            a {
              font-size: rem-calc(19);
              font-weight: 700;
              line-height: rem-calc(23);
              color: #1b4886;
              img {
                width: rem-calc(27);
                height: rem-calc(18.6);
                margin-left: rem-calc(6);
              }

              &:hover {
                  opacity:0.7;
              }
            }
          }
        }
      }
    }
  }
}