.current-report {
  .main-title {
    background: #1b4886;
    min-height: auto;

    text-align: center;
    position: relative;
    @include breakpoint(large) {
      background: #153c6a;
      min-height: rem-calc(90);
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(large) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #153c6a;
      border-width: rem-calc(30);
      margin-left: rem-calc(-30);
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #153c6a;
      border-width: rem-calc(36);
      margin-left: rem-calc(-36);
    }

    h5 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(36);
      padding: rem-calc(10);

      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: rem-calc(70);
        padding: rem-calc(10);
      }
    }
  }
  .reports {
    background: #1b4886;
    padding-top: rem-calc(30);
    padding-bottom:rem-calc(30);

    @include breakpoint(large) {

      padding-top: rem-calc(60);
      padding-bottom:rem-calc(60);
    }

    .columns {
      border-top:rem-calc(2) solid #153c6a;
      border-left:none;
      &:first-child {
        border-top:none;
        border-left:none;
      }

      @include breakpoint(large) {
        border-left:rem-calc(2) solid #153c6a;
        border-top:none;
        &:first-child {
          border-left:none;
          border-top:none;
        }
      }
    }
    .report {
      padding:rem-calc(30);
      @include breakpoint(large) {
        padding:0 rem-calc(30);

      }
      .report-title {
        min-height:auto;
        margin-bottom:0;
        color:#fff;
        font-family: 'Source Sans Pro', sans-serif;

        @include breakpoint(large) {
          min-height:rem-calc(220);
          margin-bottom:rem-calc(20);

        }

        h3 {
          font-size: rem-calc(24);
          line-height: rem-calc(26);
          font-weight:700;
        }
        p {
          font-size: rem-calc(19);
          line-height: rem-calc(23);
          font-weight:400;
        }
      }
      .report-cta {
        text-align:right;
        a {
          color:#fff;
          font-size: rem-calc(19);
          line-height: rem-calc(23);
          font-weight:600;
          img {

            margin-left:rem-calc(10);
            &.view {
              height:rem-calc(18.6);
              width:rem-calc(27);
            }
            &.download {
              height:rem-calc(18.6);
            }
          }

          &:hover {
            opacity:0.7;
          }
        }
      }
    }

  }
  .reports-mobile {
    background: #1b4886;
    padding-top: 0;
    padding-bottom: rem-calc(30);

    @include breakpoint(large) {
      padding-top: rem-calc(60);
      padding-bottom: rem-calc(60);
    }

    .accordion {
      .accordion-item {
        border-bottom:rem-calc(2) solid #1b4886;

        .accordion-title {
          background: #183D68;
          color:#fff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(21);
          font-weight: 700;
          border:none;
          height:auto;
          line-height:rem-calc(28);
          padding-top:rem-calc(15);
          padding-bottom:rem-calc(15);
          padding-left:rem-calc(20);
          padding-right:rem-calc(50);
          overflow:hidden;
          position:relative;
          @include breakpoint(large) {
            height:rem-calc(78);
            line-height:rem-calc(78);
            font-size: rem-calc(24);
            padding-top:0;
            padding-bottom:0;
          }
          &[aria-expanded="true"] {
            background: #183D68;
            border-bottom:rem-calc(2) solid #183D68;

            &:before {
              -ms-transform: rotate(180deg); /* IE 9 */
              -webkit-transform: rotate(180deg); /* Safari */
              transform: rotate(180deg);

            }
          }
          &:before {
            background:url('../img/arrow-up-w.svg') no-repeat center center;
            top:0;
            content:'';
            width:rem-calc(20);
            height:auto;
            margin-top:0;
            position:absolute;
            bottom:0;
            background-size:rem-calc(20) rem-calc(12);
            -webkit-transition: all 0.2s; /* Safari */
            transition: all 0.2s;
            @include breakpoint(medium) {
              width:rem-calc(31);
              background-size:rem-calc(31) rem-calc(19);
            }
          }
        }
        .accordion-content {
          background: #183D68;
          border:none;
          color:#fff;
          padding:rem-calc(30) rem-calc(20);
          padding-top:0;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(19);
          font-weight: 400;
          line-height:rem-calc(23);



              a {
                font-size: rem-calc(19);
                font-weight: 700;
                display:block;
                color:#fff;
                text-align:right;
                position:relative;
                right:rem-calc(0);
                img {
                  margin-left:rem-calc(5);
                  &.download {
                    width:rem-calc(16.6);

                  }
                  &.view {
                    width:rem-calc(25.1);
                    height:rem-calc(19.6);
                  }
                }
              }


        }
      }
    }
  }
}