.partners-types {
  .main-title {
    background:#f5f5f5;
    min-height: auto;
    text-align: center;
    position: relative;
    @include breakpoint(large) {
      background:#ebebeb;
      min-height: rem-calc(90);
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(large) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(30);
      margin-left: rem-calc(-30);
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(36);
      margin-left: rem-calc(-36);
    }

    h5 {
      color:#696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(30);
      padding: rem-calc(35) 0 0 0;

      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: rem-calc(30);
      }
    }

    p {
      color:#696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(19);
      font-weight: 400;
      line-height: rem-calc(23);
    }
  }
  .partner-type-content {
    background:#f5f5f5;
    padding-top: 0;
    padding-bottom:0;
    @include breakpoint(large) {
      padding-top: rem-calc(60);
      padding-bottom:rem-calc(60);
    }
    .comparison-table-container {
      position:relative;
      margin-top:rem-calc(30);
      &:before {
        display:none;
        @include breakpoint(large) {
          background: #fff;
          position: absolute;
          top: rem-calc(-30);
          bottom: rem-calc(-30);
          right: 0;
          width: 33%;
          display: block;
          content: '';
          z-index: 2;
          box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
        }

      }
      &:after {
        display:none;
        @include breakpoint(large) {
          background: #fff;
          position: absolute;
          top: rem-calc(-30);
          bottom: rem-calc(-30);
          right: calc(33% + 30px);
          width: 33%;
          display: block;
          content: '';
          z-index: 2;
          box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
        }
      }
    }
    .comparison-table-container-mobile {
          padding:rem-calc(30) rem-calc(30) rem-calc(10) rem-calc(30);
          background: #fff;
          box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
          margin-bottom:rem-calc(30);
    }
    table.comparison-table {
      position: relative;
      z-index: 3;
      tbody {
        background: transparent;

        tr {
          background: transparent;
          td {
            vertical-align: middle;
            background-color: transparent;
            padding-top:rem-calc(3);
            padding-bottom:rem-calc(3);
            line-height:1.2;


            &:nth-child(2) {
              position:relative;
              right:rem-calc(25);
              padding-left:rem-calc(30);
              padding-right:rem-calc(30);

            }
            &:nth-child(3) {
              position:relative;
              right:0;
              padding-left:rem-calc(30);
              padding-right:rem-calc(30);
            }
            &.partner-type-name {
              h4 {
                color:#298dff;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(19);
                font-weight: 700;
                line-height: rem-calc(23);
                text-align:center;
              }
              p {
                color:#696969;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(16);
                font-weight: 600;
                line-height: rem-calc(19);
              }
            }

            &.partner-feature {
              color:#298dff;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(16);
              font-weight: 600;
              line-height: rem-calc(19);

              &:before {
                content:' ';
                border-top:rem-calc(2) solid #dbdddc;
                display:block;
                position:relative;
                top:rem-calc(-10);
                right:rem-calc(10);
              }

            }
            &.partner-check {
              text-align:center;

              .checked-feature {
                width:rem-calc(12.2);
                height:rem-calc(12.9);
                display:inline-block;
                background:url('../img/check-b.svg') no-repeat center center;
              }

              &:before {
                content:' ';
                border-top:rem-calc(2) solid #ebebeb;
                display:block;
                position:relative;
                top:rem-calc(-10);
              }
            }
            &.partner-type-cta {

              a {
                display:block;
                text-align:center;
                font-family: 'Source Sans Pro', sans-serif;
                line-height:rem-calc(50);
                font-size:rem-calc(19);
                font-weight:600;
                margin-top:rem-calc(25);
                color:#fff;
                height:rem-calc(50);
                border-radius:rem-calc(50);
              }

              &:before {
                content:' ';
                border-top:rem-calc(2) solid #ebebeb;
                display:block;
                position:relative;
                top:rem-calc(-10);
              }
            }

            &.partner-type-cta-empty {



              &:before {
                content:' ';
                border-top:rem-calc(2) solid #dbdddc;
                display:block;
                position:relative;
                top:rem-calc(-10);
                right:rem-calc(10);
              }
            }
          }
        }
      }
    }
    table.comparison-table-mobile {

      tbody {
        background: transparent;

        tr {
          background: transparent;
          td {
            background-color: transparent;
            padding-top:rem-calc(8);
            padding-bottom:rem-calc(8);
            line-height:1.5;



            &.partner-type-name {
              h4 {
                color:#298dff;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(19);
                font-weight: 700;
                line-height: rem-calc(23);
                text-align:center;
              }
              p {
                color:#696969;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(16);
                font-weight: 600;
                line-height: rem-calc(19);
              }
            }

            &.partner-feature {
              color:#298dff;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(16);
              font-weight: 600;
              line-height: rem-calc(19);
              border-top:rem-calc(2) solid #ebebeb;


            }
            &.partner-check {
              text-align:left;

              .checked-feature {
                width:rem-calc(12.2);
                height:rem-calc(12.9);
                display:inline-block;
                background:url('../img/check-b.svg') no-repeat center center;
              }

            }
            &.partner-type-cta {
              border-top:rem-calc(2) solid #ebebeb;
              padding-top:rem-calc(30);
              a {
                display:block;
                text-align:center;
                font-family: 'Source Sans Pro', sans-serif;
                line-height:rem-calc(50);
                font-size:rem-calc(19);
                font-weight:600;
                color:#fff;
                height:rem-calc(50);
                border-radius:rem-calc(50);

              }


            }


          }
        }
      }
    }
  }


}