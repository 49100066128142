.footer {
  background:#3c3c3c;
  .columns {
    @include breakpoint(small only) {
      padding-left:0;
      padding-right:0;
    }
  }
  .footer-navs {
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(40);
    @include breakpoint(large) {
      padding-top: rem-calc(100);
      padding-bottom: rem-calc(80);
    }
    .footer-menu {
      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        > li {
          margin: 0;
          padding: 0;

          > a {
            line-height: rem-calc(19);
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(16);
            font-weight: 600;
            color: #fff;

            &:hover {
              color:#ddd;
            }
          }
        }
      }
      .sub-menu {
        list-style: none;
        margin: 0 0 rem-calc(10) 0;
        padding: 0;
        > li {
          margin: 0;
          padding: 0;
          margin-bottom:rem-calc(5);
          line-height:rem-calc(18);
          > a {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(16);
            font-weight: 300;
            color: #fff;
            margin: 0;
            padding: 0;

            &:hover {
              color:#ddd;
            }
          }
        }
      }
    }
    .footer-mobile-menu {
      position:relative;
      top:rem-calc(10);
      @include breakpoint(medium) {
        top:0;
      }

      ul {
        list-style:none;
        margin:0;
        padding:0;
        li {
          display:inline-block;
          width:49%;
          a {
            line-height: rem-calc(25);
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(16);
            font-weight: 600;
            color: #fff;

            &:hover {
              color:#eee;
            }
          }
        }
      }
      .social-icons {
        position:absolute;
        left:49%;
        bottom:rem-calc(0);
      }
    }
    .contact-info {
      line-height: rem-calc(19);
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(16);
      font-weight: 300;
      color: #fff;
      margin-bottom: 0;
    }
    .social-twitter {
      display:inline-block;
      margin-right:rem-calc(6);
      img {
        width: rem-calc(17.5);
        height: rem-calc(14.2);
      }

      &:hover {
        opacity:0.6;
      }
    }
    .subscription-form {

      h3 {
        line-height: rem-calc(19);
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(16);
        font-weight: 600;
        color: #fff;
        margin-bottom:rem-calc(4);
        @include breakpoint(medium) {
          margin-bottom:rem-calc(4);
        }
      }
      
      .gform_button:hover {
          background:#e5e5e5;
      }
    }
  }
  .footer-rights {
    color:#fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size:rem-calc(14);
    font-weight:300;
    margin-top:rem-calc(15);
    @include breakpoint(medium) {
      margin-top:0;
    }
    .copyrights {
      line-height:rem-calc(17);
      margin-bottom:0;
      @include breakpoint(medium) {
        line-height:rem-calc(23);
      }
    }
    .created-by {
      text-align:left;
      font-weight:600;

      @include breakpoint(medium) {
        text-align:right;
        font-weight:300;
      }

      a {
        color:#fff;
        text-decoration:underline;

        &:hover {
          color:#ddd;
        }
      }
    }
  }
}