.related-widgets {
  padding:rem-calc(20) 0 rem-calc(25) 0;
  .widget {
    padding:rem-calc(25) rem-calc(30) 0 rem-calc(30);
    box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
    .widget-inner {

      .widget-type {
        font-size:rem-calc(16);
        font-weight:700;
        line-height:rem-calc(20);
        color:#fff;
        margin-bottom:rem-calc(10);
      }
      .widget-title {
        font-size:rem-calc(24);
        font-weight:700;
        line-height:rem-calc(26);
        color:#fff;
        margin-bottom:rem-calc(10);

        &.testimonial {
          position:relative;
          &:before {
            content:'“';
            position:absolute;
            left:rem-calc(-15);
          }
          &:after {
            content:'”';
          }
        }
      }

      .widget-image {
        background:#fff;
        margin:rem-calc(25) 0;
        img {
          padding:rem-calc(20);
        }
      }
      .widget-excerpt {
        font-size:rem-calc(19);
        font-weight:400;
        line-height:rem-calc(23);
        color:#fff;
      }
      .widget-report-date {
        font-size:rem-calc(16);
        font-weight:600;
        line-height:rem-calc(19.2);
        color:#fff;
      }
      .widget-author {
         font-size:rem-calc(16);
         font-weight:600;
         line-height:rem-calc(19.2);
         color:#fff;
       }
      .widget-position {
        font-size:rem-calc(16);
        font-weight:600;
        font-style:italic;
        line-height:rem-calc(19.2);
        color:#fff;
      }
    }
    .widget-footer {
      height:rem-calc(68.6);
      line-height:rem-calc(68.6);
      text-align:right;
      font-size:rem-calc(19);
      font-weight:700;
      border-top:rem-calc(2) solid rgba(0,0,0,0.1);

      &:empty {
        border-top:none;
      }
      a {
        color:#fff;

        img {
          width:rem-calc(27);
          height:rem-calc(18.6);
          margin-left:rem-calc(6);
          display:inline;
        }
      }
    }
  }
  .no-hover {
    &:hover {
      background:#696969;
    }
  }
  .see-more {
    text-align:right;
    margin-top:rem-calc(10);
    a {
      line-height:rem-calc(23);
      font-size:rem-calc(16);
      font-weight:700;
      color:#696969;
      img {
        width:rem-calc(9.9);
        height:rem-calc(14.7);
        margin-left:rem-calc(5);
      }
      &:hover {
        opacity:0.7;
      }
    }


  }
}

.related-widgets-mobile {
  .accordion {
    .accordion-item {

      .accordion-title {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(24);
        font-weight: 700;
        border:none;
        color:#fff;
        height:rem-calc(78);
        line-height:rem-calc(78);
        padding-top:0;
        padding-bottom:0;
        padding-left:rem-calc(30);
        position:relative;
        &:hover,
        &:focus {
          background:none;
        }
        &[aria-expanded="true"] {
          background:none;
          border:none;
          &:hover,
          &:focus {
            background:none;
            border:none;
          }
          &:before {
            background:url('../img/arrow-up-w.svg') no-repeat center center;
            -ms-transform: rotate(180deg); /* IE 9 */
            -webkit-transform: rotate(180deg); /* Safari */
            transform: rotate(180deg);
          }
        }
        &:before {
          background:url('../img/arrow-up-w.svg') no-repeat center center;
          top:0;
          content:'';
          width:rem-calc(20);
          height:auto;
          margin-top:0;
          position:absolute;
          bottom:0;
          background-size:rem-calc(20) rem-calc(12);
          -webkit-transition: all 0.2s; /* Safari */
          transition: all 0.2s;
          @include breakpoint(medium) {
            width:rem-calc(31);
            background-size:rem-calc(31) rem-calc(19);
          }
        }
      }
      .accordion-content {
        background:none;
        padding:0 rem-calc(30) rem-calc(30) rem-calc(30);
        border:none;
        margin:0;
        .widget-inner {
          margin-bottom:rem-calc(20);

          .widget-title {
            font-size:rem-calc(24);
            font-weight:700;
            line-height:rem-calc(26);
            color:#fff;
            margin-bottom:rem-calc(10);

            &.testimonial {
              position:relative;
              &:before {
                content:'“';
                position:absolute;
                left:rem-calc(-15);
              }
              &:after {
                content:'”';
              }
            }
          }

          .widget-image {
            background:#fff;

            margin:rem-calc(25) 0;
            @include breakpoint(medium only) {
              width:33%;
            }
          }
          .widget-excerpt {
            font-size:rem-calc(19);
            font-weight:400;
            line-height:rem-calc(23);
            color:#fff;
          }
          .widget-report-date {
            font-size:rem-calc(16);
            font-weight:600;
            line-height:rem-calc(19.2);
            color:#fff;
          }
          .widget-author {
            font-size:rem-calc(16);
            font-weight:600;
            line-height:rem-calc(19.2);
            color:#fff;
          }
          .widget-position {
            font-size:rem-calc(16);
            font-weight:600;
            font-style:italic;
            line-height:rem-calc(19.2);
            color:#fff;
          }
        }
        .widget-footer {
          padding-top:rem-calc(20);
          text-align:right;
          font-size:rem-calc(19);
          font-weight:700;
          border-top:rem-calc(2) solid rgba(0,0,0,0.1);

          &:empty {
            border-top:none;
          }
          a {
            color:#fff;

            img {
              width:rem-calc(27);
              height:rem-calc(18.6);
              margin-left:rem-calc(6);
            }
          }
        }
      }
    }
  }
}