.cta-box {
  padding:rem-calc(80) 0;
  .cta-title {
    font-weight:300;
    color:#6a8e2c;
    font-size:rem-calc(45);
    line-height:rem-calc(50);
    text-align:center;
    float:none;
    @include breakpoint(large) {
      line-height:rem-calc(60);
      font-size:rem-calc(60);
      text-align:left;
      float:left;
    }
  }
  .cta-button {
    float:none;
    width:100%;
    @include breakpoint(large) {
      float:right;
      width:40%;
    }
    a {
      display:block;
      text-align:center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(19);
      font-weight:600;
      background:#6a8e2c;
      border-radius:rem-calc(50);
      height:rem-calc(50);
      line-height:rem-calc(50);
      margin-top:rem-calc(40);
      color:#fff;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);

      &:hover {
        background:#4c8e2c;
      }
    }
  }
}
