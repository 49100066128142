.swycd-plans {
  .main-title {
    background: #0d7eff;
    min-height: auto;

    text-align: center;
    position: relative;
    @include breakpoint(medium) {
      min-height: rem-calc(90);
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(medium) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(30);
      margin-left: rem-calc(-30);
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(36);
      margin-left: rem-calc(-36);
    }

    h5 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(36);
      padding: rem-calc(10);

      @include breakpoint(medium) {
        font-size: rem-calc(24);
        line-height: rem-calc(24);
        padding-top: rem-calc(30);
        padding-bottom:0;
      }
    }
    p {
      color:#fff;
      font-size: rem-calc(19);
      line-height: rem-calc(23);
      font-weight:400;
    }
  }
  .plans {
    background: #298dff;
    padding-top: rem-calc(30);
    padding-bottom:0;
    @include breakpoint(medium) {
      padding-top: rem-calc(60);
      padding-bottom:rem-calc(60);
    }
    .plan {
      background:#fff;
      padding:rem-calc(30);
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
      margin-bottom:rem-calc(30);
      @include breakpoint(medium) {
        margin-bottom:0;
      }
      h3 {
        color:#298dff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(24);
        font-weight: 700;
        line-height: rem-calc(23);
        margin:0 0 rem-calc(10) 0;
      }
      h4 {
        color:#298dff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 700;
        line-height: rem-calc(26);
        margin:0;
      }
      h5 {
        color:#298dff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 400;
        line-height: rem-calc(23);
        margin:0;
      }
      p {
        color:#298dff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 400;
        line-height: rem-calc(23);
        margin:rem-calc(10) 0 rem-calc(15) 0;
        &:empty {
          display:none;
        }
        > a {
          color:#298dff;
        }
      }
      .features {
        list-style:none;
        margin:0;
        padding:0;

        li {
          position:relative;
          color:#298dff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(19);
          font-weight: 400;
          line-height: rem-calc(23);
          padding:rem-calc(5) rem-calc(15);
          border-bottom:rem-calc(2) solid #ebebeb;

          &:first-child {
            border-top:rem-calc(2) solid #ebebeb;
          }
          img {
            position:absolute;
            left:rem-calc(-20);
            top:rem-calc(12);
            width:rem-calc(12);
            height:rem-calc(12);
          }
        }
      }

      .demo,
      .buy-now{
        display:block;
        text-align:center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size:rem-calc(19);
        font-weight:600;
        background:#298dff;
        border-radius:rem-calc(50);
        height:rem-calc(50);
        line-height:rem-calc(50);
        color:#fff;
        box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
        &:hover {
          background:#1b4886;
        }

        &.demo {
          margin-top:rem-calc(30);
          background:#fff;
          border:rem-calc(2) solid #298dff;
          color:#298dff;
          box-shadow:none;
          margin-bottom:rem-calc(20);

          &:hover {
            background:#fff;
            border:rem-calc(2) solid #1b4886;
            color:#1b4886;
          }
        }
      }
    }
  }

  .plans-mobile {
    background: #0d7eff;
    padding-top: 0;
    padding-bottom:rem-calc(30);
    @include breakpoint(medium) {
      padding-left:rem-calc(30);
      padding-right:rem-calc(30);
    }
    .accordion {
      .accordion-item {
        border-bottom:rem-calc(2) solid #0d7eff;
        .accordion-title {
          background:#298dff;
          color:#fff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(24);
          font-weight: 700;
          border:none;
          position:relative;
          height:rem-calc(78);
          line-height:rem-calc(78);
          padding-top:0;
          padding-bottom:0;
          padding-left:rem-calc(30);
          &[aria-expanded="true"] {
            background:#298dff;
            color:#fff;


            &:before {
              -ms-transform: rotate(180deg); /* IE 9 */
              -webkit-transform: rotate(180deg); /* Safari */
              transform: rotate(180deg);

            }
          }
          &:before {
            background:url('../img/arrow-up-w.svg') no-repeat center center;
            top:0;
            content:'';
            width:rem-calc(20);
            height:auto;
            margin-top:0;
            position:absolute;
            bottom:0;
            background-size:rem-calc(20) rem-calc(12);
            -webkit-transition: all 0.2s; /* Safari */
            transition: all 0.2s;
            @include breakpoint(medium) {
              width:rem-calc(31);
              background-size:rem-calc(31) rem-calc(19);
            }
          }
        }
        .accordion-content {
          background:#298dff;
          border:none;
          padding:rem-calc(30);
          padding-top:0;

          h3 {
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(24);
            font-weight: 700;
            line-height: rem-calc(23);
            margin:0 0 rem-calc(10) 0;
          }
          h4 {
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 700;
            line-height: rem-calc(26);
            margin:0;
          }
          h5 {
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height: rem-calc(23);
            margin:0;
          }
          p {
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height: rem-calc(23);
            min-height:rem-calc(80);
            margin:rem-calc(10) 0 rem-calc(15) 0;
            &:empty {
              display:none;
            }
            > a {
              color:#298dff;
            }
          }
          .features {
            list-style:none;
            margin:0;
            padding:0;


            li {
              position:relative;
              color:#fff;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(19);
              font-weight: 400;
              line-height: rem-calc(23);
              padding:rem-calc(5) rem-calc(15);
              border-bottom:rem-calc(2) solid #1D81FB;

              &:first-child {
                border-top:rem-calc(2) solid #1D81FB;
              }
              img {
                position:absolute;
                left:rem-calc(-20);
                top:rem-calc(12);
                width:rem-calc(12);
                height:rem-calc(12);
              }
            }
          }

          .demo,
          .buy-now{
            display:block;
            text-align:center;
            font-family: 'Source Sans Pro', sans-serif;
            font-size:rem-calc(19);
            font-weight:600;
            background:#fff;
            border-radius:rem-calc(50);
            height:rem-calc(50);
            line-height:rem-calc(50);
            color:#298dff;
            box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
            &:hover {
              color:#1b4886;
            }

            &.demo {
              margin-top:rem-calc(30);
              background:#298dff;
              border:rem-calc(2) solid #fff;
              color:#fff;
              box-shadow:none;
              margin-bottom:rem-calc(20);

              &:hover {
                background:#298dff;
                border:rem-calc(2) solid #eee;
                color:#eee;
              }
            }
          }
        }
      }
    }
  }

}