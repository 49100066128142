.contact-map {
  .main-title {
    background: #f5f5f5;
    min-height: auto;
    text-align: center;
    @include breakpoint(large) {
      background: #ebebeb;
      min-height: rem-calc(90);
      position: relative;
    }
    &:after,
    &:before {
      display: none;
      @include breakpoint(large) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(30);
      margin-left: rem-calc(-30);
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(36);
      margin-left: rem-calc(-36);
    }

    h5 {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(36);
      padding: rem-calc(10);

      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: rem-calc(70);
        padding: rem-calc(10);
      }
    }
  }

  .sections {
    background: #f5f5f5;
    padding-top: 0;
    padding-bottom: rem-calc(30);
    @include breakpoint(large) {
      padding-top: rem-calc(60);
      padding-bottom: rem-calc(60);
    }

    .section {
      background:#ebebeb;
      padding:rem-calc(30);
      padding-bottom:0;
      &:last-child {
        margin-top:rem-calc(30);
        margin-bottom:rem-calc(30);
        @include breakpoint(large) {
          margin-top:rem-calc(30);
          margin-bottom:0;
        }
      }

      .section-details {
        h3 {
          color: #696969;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(24);
          font-weight: 700;
          line-height: rem-calc(23);
          margin-bottom:rem-calc(12);
        }
        p {
          color: #696969;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(19);
          font-weight: 400;
          line-height: rem-calc(23);
          margin-bottom:rem-calc(24);
        }
      }
      .section-cta {
        text-align:right;
        border-top:rem-calc(2) solid #dcdcdc;
        height:rem-calc(73);
        line-height:rem-calc(73);

        a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(19);
          line-height: rem-calc(25);
          font-weight: 700;
          color:#696969;
          img {
            width: rem-calc(27);
            height: rem-calc(18.6);
            margin-left: rem-calc(6);
          }
          &:hover {
            opacity:0.7;
          }
        }
      }
    }
    .the-map {
      iframe {
        height:rem-calc(400);
        @include breakpoint(large) {
          height:auto;
        }
      }
    }
  }
}