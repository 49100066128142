body {
  overflow-x:hidden;
  .subscription-form {
    .gform_confirmation_wrapper {
      .gform_confirmation_message {
        border:none;
        color:#fff;
        text-align:left;
        padding:rem-calc(10) 0;
        margin-top:rem-calc(10);
        font-weight:700;
      }
    }
    .gform_wrapper {
        ul.gform_fields  {
          li.gfield.gfield_error + li.gfield.gfield_error {
            margin-top:0;
          }
        }
    }
  .gform_wrapper {
    margin:0;
    .validation_error {
      border:none;
      color:#FF3422;
      text-align:left;
      padding:0;
      margin:0 0 rem-calc(8) 0;
    }
    form {
      position:relative;
      z-index:99;
      label {
        &:empty {
          display:none;
        }
      }
      .gform_body {
        .gform_fields {
          .gfield {
            margin:0;
            padding:0;
            .ginput_container_checkbox {

              margin:0;
              .gfield_checkbox {
                margin:0 0 rem-calc(10);
                width:100%;
                &:after {
                    display: block;
                    content: '';
                    clear: both;
                }
                li {

                  display:block;
                  margin:0 rem-calc(15) 0 0;
                  float: left;

                  @include breakpoint(large) {


                  }
                  label {
                    font-family: 'Source Sans Pro', sans-serif;
                    font-size: rem-calc(16);
                    font-weight: 300;
                    color: #fff;
                    max-width:100%;
                    width:100%;
                  }
                  [type="checkbox"]:not(:checked),
                  [type="checkbox"]:checked {
                    position: absolute;
                    left: -9999px;

                  }
                  [type="checkbox"]:not(:checked) + label,
                  [type="checkbox"]:checked + label {
                    position: relative;
                    padding-left: rem-calc(25);
                    cursor: pointer;
                    @include breakpoint(medium) {
                      padding-left: rem-calc(35);
                    }
                  }

                  /* checkbox aspect */
                  [type="checkbox"]:not(:checked) + label:before,
                  [type="checkbox"]:checked + label:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: rem-calc(2);
                    width: rem-calc(20);
                    height: rem-calc(20);
                    border: 1px solid #fff;
                    background: #3c3c3c;


                  }
                  /* checked mark aspect */
                  [type="checkbox"]:not(:checked) + label:after,
                  [type="checkbox"]:checked + label:after {
                    content: '×';
                    position: absolute;
                    top: rem-calc(-13);
                    left: 3px;
                    font-size: rem-calc(28);
                    color: #fff;
                    line-height: 1.75;
                    -webkit-transition: all .2s;
                    -moz-transition: all .2s;
                    -ms-transition: all .2s;
                    transition: all .2s;
                  }
                  /* checked mark aspect changes */
                  [type="checkbox"]:not(:checked) + label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    -moz-transform: scale(0);
                    -ms-transform: scale(0);
                    transform: scale(0);
                  }
                  [type="checkbox"]:checked + label:after {
                    opacity: 1;
                    -webkit-transform: scale(1);
                    -moz-transform: scale(1);
                    -ms-transform: scale(1);
                    transform: scale(1);
                  }
                  /* disabled checkbox */
                  [type="checkbox"]:disabled:not(:checked) + label:before,
                  [type="checkbox"]:disabled:checked + label:before {
                    box-shadow: none;
                    border-color: #bbb;
                    background-color: #ddd;
                  }
                  [type="checkbox"]:disabled:checked + label:after {
                    color: #999;
                  }
                  [type="checkbox"]:disabled + label {
                    color: #aaa;
                  }
                  /* accessibility */
                  [type="checkbox"]:checked:focus + label:before,
                  [type="checkbox"]:not(:checked):focus + label:before {
                    border: 1px solid #fff;
                  }
                }
              }
            }
            .ginput_container_text {
              margin:0;

              input {
                background:none;
                height:rem-calc(40);
                border-color:#fff;
                box-shadow:none;
                color:#fff;
                margin:0;
                &:focus {
                  box-shadow:none;
                }
              }
            }
            .ginput_container_email {
              margin:0;

              input {
                background:none;
                height:rem-calc(40);
                border-color:#fff;
                box-shadow:none;
                color:#fff;
                margin:0;
                &:focus {
                  box-shadow:none;
                }
              }
            }
            .gfield_required {
              display:none;
            }
            .gfield_label {
              display:none;
            }
          }
          .gfield_error {
            background:none;
            border:none;
            margin-bottom:0 !important;
            margin-top:0;
            .ginput_container {
              input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
                border-color:red;
              }
            }
            .ginput_container_checkbox {
              .gfield_checkbox {
                li {
                  [type="checkbox"]:not(:checked) + label:before,
                  [type="checkbox"]:checked + label:before {

                    border:1px solid red;



                  }
                }
              }
            }

            .validation_message {
              display:none;
            }

          }
        }
      }
      .gform_footer {
        position:relative;
        margin:0;
        padding:0;
        height:0;
        input.button {
          position:absolute;
          padding:0;
          margin:0;
          right:0;
          bottom:rem-calc(0);
          width:rem-calc(105);
          height:rem-calc(40);
          display:inline;
          background:#fff;
          text-align:center;
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(16);
          font-weight:600;
          color:#3c3c3c;

          @include breakpoint(medium) {

          }
        }
      }
    }
  }
  }
  
  .movr-subscription-form {
      position: relative;
      
      .gform_confirmation_message {
          font-size: rem-calc(19);
          max-width: rem-calc(1000);
          color: #fff;
          margin: 0 auto;
          line-height: rem-calc(23);
          font-family: 'Source Sans Pro', sans-serif;
      }
          
      .gform_anchor {
          display: block;
          position: absolute;
          top: rem-calc(-80);
      }
      
      .gform_wrapper,
      .gform_wrapper.gform_validation_error {
          margin: 0;
          
          form {
              max-width: rem-calc(1000);
              
              .validation_error {
                  color: #8E0005;
                  border: none;
                  font-size: rem-calc(19);
                  margin: 0;
                  padding-top: 0;
                  line-height: rem-calc(23);
                  font-family: 'Source Sans Pro', sans-serif;
              }
              
              .gform_body {
                  ul.gform_fields {
                      width: 100%;
                      
                      li.gfield,
                      li.gfield.gfield_error {
                          max-width: 100% !important;
                          position: relative;
                          background: none;
                          border: none;
                          margin: 0 !important;
                          padding: 0;
                          width: 100%;
                          
                          &:after {
                              display: block;
                              content: '';
                              clear: both;
                          }
                          
                          .gfield_label,
                          .ginput_container {
                              display: block;
                              vertical-align: top;
                          }
                          .gfield_label {
                                color: #fff;
                                font-family: 'Source Sans Pro', sans-serif;
                                font-size: rem-calc(18);
                                font-weight: 700;
                                line-height:rem-calc(24);
                                margin-bottom:rem-calc(15);
                                
                                @include breakpoint(large) {
                                    float: left;
                                    width: 42%;
                                    line-height:rem-calc(50);
                                    font-size: rem-calc(24);
                                    margin:0;
                                }
                                
                                .gfield_required {
                                    display: none;
                                }
                          }
                          
                          div.ginput_container {
                              padding-right: rem-calc(119);
                              width: 100%;
                              margin: 0;
                              
                              @include breakpoint(large) {
                                padding-right: rem-calc(179);
                                float: right;
                                width: 58%;
                              }
                              
                              input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
                                    background:#fff;
                                    line-height:rem-calc(48);
                                    height:rem-calc(50);
                                    width:100%;
                                    border-color:#fff;
                                    box-shadow:none;
                                    color:#696969;
                                    margin:0;
                                    font-weight:600;
                                    font-size:rem-calc(19);
                                    padding: 0 rem-calc(33);
                                    width: 100%;

                                    &:focus {
                                      box-shadow:none;
                                    }

                                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                                      color: #298dff;
                                      opacity: 1; /* Firefox */
                                      font-weight:600;
                                      font-size:rem-calc(19);
                                    }
                              }
                          }
                          
                          &.gfield_error {
                              div.ginput_container {
                                  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
                                      border-color: #8E0005;
                                  }
                              }
                          }
                          
                          .validation_message {
                              position: absolute;
                              top: rem-calc(50);
                              width: 100%;
                              text-align: center;
                              top: rem-calc(50);
                              right: 0;
                              left: 0;
                              padding-right: 0;
                              color: #8E0005;
                              font-size: rem-calc(19);
                              font-weight: normal;
                              display: none;
                              line-height: rem-calc(23);
                              font-family: 'Source Sans Pro', sans-serif;
                          }
                      }
                  }
              }
              
              .gform_footer {
                position:relative;
                margin:0;
                padding:0;
                input.button {
                  position:absolute;
                  padding:0;
                  right:0;
                  top: rem-calc(-50);
                  width:rem-calc(119);
                  height:rem-calc(50);
                  line-height:1;
                  display:inline;
                  background:#1b4886;
                  text-align:center;
                  font-family: 'Source Sans Pro', sans-serif;
                  font-size:rem-calc(19);
                  font-weight:600;
                  color:#fff;
                  margin:0;
                  @include breakpoint(large) {
                    line-height:rem-calc(50);
                    width:rem-calc(179);
                  }

                  &:hover {
                    background:#2a5996;
                  }
                }
              }
          }
      }
  }
}