
.bg-green {
  background:#6a8e2c;
  &:hover {
    background: #1f6e08;
  }
}
.bg-orange {
  background:#c86b1f;
  &:hover {
    background: #d14704;
  }
}
.bg-navy {
  background:#547b96;
  &:hover {
    background: #1c64a1;
  }
}
.bg-blue {
  background:#1b4886;
  &:hover {
    background:#0a3a74;
  }
}
.bg-gray {
  background:#696969;
  &:hover {
    background:#444;
  }
}

.text-green {
  color:#6a8e2c;
}
.text-orange {
  color:#c86b1f;
}
.text-navy {
  color:#547b96;
}
.text-blue {
  color:#1b4886;
}
.text-gray {
  color:#696969;
}

