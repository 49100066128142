.featured-widgets {
  background:#fff;
  padding-top:0;
  padding-bottom:0;
  @include breakpoint(medium) {
    padding-top:rem-calc(60);
    padding-bottom:rem-calc(30);
  }
  @include breakpoint(large) {
    padding-top:rem-calc(60);
    padding-bottom:rem-calc(60);
  }
  .row {
    @include breakpoint(small only) {
      padding:0;
      margin:0;
    }
    .large-4 {
      @include breakpoint(small only) {
        padding:0;
        margin:0;
      }

    }
  }
  .widget {
    box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
    margin-bottom:rem-calc(30);
    @include breakpoint(large) {
      margin-bottom:0;
    }
    .widget-image {
      height:auto;
      overflow:hidden;
      float:none;
      width:auto;
      margin-right:0;
      @include breakpoint(medium) {
        height:rem-calc(245);
        width:rem-calc(400);
        float:left;
        margin-right:rem-calc(20);
      }
      @include breakpoint(large) {
        height:rem-calc(200);
        float:none;
        width:auto;
        margin-right:0;
      }
      img {
        min-height:100%;
        width:100%;
        @include breakpoint(medium) {

          height:rem-calc(245);
          width:100%;
        }
        @include breakpoint(large) {
          height:100%;
          max-width:100%;
        }
      }
    }
    .widget-contents {
      .widget-details {
        padding:rem-calc(30) rem-calc(30) rem-calc(20) rem-calc(30);
        height:auto;
        @include breakpoint(medium) {
          height:rem-calc(195);
          padding:rem-calc(30) rem-calc(10);
        }
        @include breakpoint(large) {
          height:rem-calc(195);
          padding:rem-calc(30);
        }
        h5 {
          font-size:rem-calc(16);
          font-weight:700;
          line-height:rem-calc(20);
          color:#fff;
          margin:0 0 rem-calc(5) 0;
        }

        h2 {
          font-size:rem-calc(24);
          font-weight:700;
          color:#fff;
          line-height:rem-calc(26);
        }
        span {
          font-size:rem-calc(16);
          font-weight:600;
          font-style:italic;
          color:#fff;
          line-height:rem-calc(19.2);
        }
      }
      .widget-footer {
        height:rem-calc(70);
        line-height:rem-calc(70);
        text-align:right;
        font-size:rem-calc(19);
        font-weight:700;
        margin-right:rem-calc(30);
        margin-left:rem-calc(30);
        border-top:rem-calc(2) solid rgba(0,0,0,0.1);
        @include breakpoint(medium) {
          height:rem-calc(50);
          line-height:rem-calc(40);
          margin-left:rem-calc(420);
        }
        @include breakpoint(large) {
          height:rem-calc(70);
          line-height:rem-calc(70);
          margin-left:rem-calc(30);
        }

        a {
          color:#fff;

          img {
            width:rem-calc(27);
            height:rem-calc(18.6);
            margin-left:rem-calc(6);
          }
        }

      }
    }
  }
}