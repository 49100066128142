
.header {

  background-color:#eaeaea;
  @include breakpoint(medium) {

  }
  .header-intro {
    padding-top:rem-calc(45);
    text-align:center;
    @include breakpoint(large) {
      text-align:left;
      padding-top:rem-calc(135);
    }
    .logo {
      margin-bottom:rem-calc(10);
      a {
        img {
          height:rem-calc(47.2);
          width:rem-calc(236);
        }
      }
    }
    h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(34);
      color:#298dff;
      font-weight:600;
      line-height:rem-calc(39);
      margin-bottom:rem-calc(15);

      @include breakpoint(large) {
        line-height:rem-calc(55);
        font-size:rem-calc(55);
        color:#1b4886;
        margin-bottom:rem-calc(20);
      }
    }
    h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(19);
      color:#696969;
      font-weight:600;
      line-height:rem-calc(22.8);
      margin-bottom:rem-calc(20);
      @include breakpoint(large) {
        font-size:rem-calc(24);
        line-height:rem-calc(28.8);
      }
    }
    .header-cta {
      background:#298dff;
      color:#fff;
      width:rem-calc(235);
      display:inline-block;
      height:rem-calc(50);
      font-family: 'Source Sans Pro', sans-serif;
      line-height:rem-calc(50);
      border-radius:rem-calc(50);
      text-align:center;
      font-size:rem-calc(19);
      font-weight:600;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);

      &:hover {
        background:#153C6A;
      }

      @include breakpoint(large) {
        width:rem-calc(391.3);
      }
    }
  }
 .phone-bg {
   height:auto;
   position:static;
   margin-bottom:rem-calc(60);
   margin-top:rem-calc(45);
   margin-left:rem-calc(15);
   margin-right:rem-calc(15);

   img {
     position:static;
     bottom:0;
     right:0;
     @include breakpoint(medium) {
       position:absolute;
     }
   }

   @include breakpoint(medium) {
     position:relative;
     margin:0;
     height:rem-calc(412);
   }
   @include breakpoint(large) {

     height:rem-calc(672);
   }
 }
}

