.error-page {
  background:#f5f5f5;
  padding-top:rem-calc(50);
  padding-bottom:rem-calc(50);
  h1 {
    line-height:rem-calc(50);
    font-family: 'Source Sans Pro', sans-serif;
    font-size:rem-calc(45);
    font-weight:700;
    color:#696969;
  }
  p {
    line-height:rem-calc(23);
    font-family: 'Source Sans Pro', sans-serif;
    font-size:rem-calc(19);
    font-weight:400;
    color:#696969;
  }
  .searchform {
    position:relative;
    label {
      font-size:rem-calc(16);
      line-height:rem-calc(19);
      font-weight:400;
      color:#696969;
      margin-bottom:rem-calc(10);
    }
    input[type="text"] {
      width:100%;
      display:inline;
      float:left;
      border:none;
      box-shadow:none;
      height:rem-calc(50);
      padding-right:rem-calc(150);
      &:focus {
        border:none;
        box-shadow:none;
      }
    }
    input[type="submit"] {
      width:rem-calc(150);
      position:absolute;
      right:rem-calc(0);
      text-align:center;
      display:inline;
      height:rem-calc(50);
      line-height:rem-calc(50);
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(19);
      font-weight:600;
      color:#fff;
      border:none;
      background:#1b4987 url('../img/search-icon-w.svg') no-repeat 90% center;
      background-size:rem-calc(20) rem-calc(20);


      @include breakpoint(large) {
        font-size:rem-calc(24);
      }

      &:hover {
        opacity:0.8;
        cursor:hand;
      }
    }
  }
}