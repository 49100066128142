.leaders-trust {
  .main-title {
    background:#0d7eff;
    min-height:rem-calc(90);

    text-align:center;
    position: relative;

    &:after,
    &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(15);
      margin-left: rem-calc(-15);

      @include breakpoint(large) {
        border-width: rem-calc(30);
        margin-left: rem-calc(-30);
      }
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(18);
      margin-left: rem-calc(-18);
      @include breakpoint(large) {
        border-width: rem-calc(36);
        margin-left: rem-calc(-36);
      }
    }

      h5 {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(24);
        font-weight: 700;
        line-height: rem-calc(26);
        padding:rem-calc(20) 0 rem-calc(10) 0;

        @include breakpoint(large) {
          font-size: rem-calc(24);
          line-height: rem-calc(70);
          padding: rem-calc(10);
        }
      }
  }
  .logos-quotes {
    background: #298dff;
    padding-top:rem-calc(30);

    @include breakpoint(medium) {
      padding-top:rem-calc(60);

    }
    .logos {
      margin-bottom:0;
      padding-bottom:rem-calc(10);
      @include breakpoint(medium) {
        margin-bottom:rem-calc(30);
        padding-bottom:0;
      }
      .single-logo {

      }
    }
    .quotes {
      .quote-border {
        border-top:rem-calc(2) solid #0d7eff;
      }
      .quote {

        padding-top:rem-calc(25);
        padding-bottom:rem-calc(25);
        @include breakpoint(medium) {
          padding-top:rem-calc(25);
          padding-bottom:rem-calc(55);
        }
        .quote-author {
          margin-bottom:rem-calc(20);
          @include breakpoint(medium) {
            margin-bottom:0;
          }
          h5 {
            font-family: 'Source Sans Pro', sans-serif;
            font-size:rem-calc(19);
            font-weight:600;
            line-height:rem-calc(23);
            color:#fff;
            margin:0;
          }
          span {
            font-family: 'Source Sans Pro', sans-serif;
            font-size:rem-calc(19);
            font-weight:600;
            line-height:rem-calc(23);
            color:#fff;
            font-style:italic;
          }
        }
        .clear {
          clear:both;
        }
        .quote-text {

          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(19);
          font-weight:400;
          line-height:rem-calc(23);
          color:#fff;
          position:relative;



          @include breakpoint(medium) {
            margin-top:0;
            font-size:rem-calc(24);
            line-height:rem-calc(30);
          }

          &:before {
            content:'“ ';
            position:absolute;
            left:rem-calc(-14);

          }
          &:after {
            content:'”';
          }
        }
      }
    }
  }
}