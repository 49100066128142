.customized-solutions {
  .main-title {
    background:#ebebeb;
    min-height:auto;

    text-align:center;
    position: relative;

    @include breakpoint(large) {
      min-height:rem-calc(90);
    }

    &:after,
    &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(15);
      margin-left: rem-calc(-15);

      @include breakpoint(large) {
        border-width: rem-calc(30);
        margin-left: rem-calc(-30);
      }
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(18);
      margin-left: rem-calc(-18);
      @include breakpoint(large) {
        border-width: rem-calc(36);
        margin-left: rem-calc(-36);
      }
    }

      h5 {
        color:#696969;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(24);
        font-weight: 700;
        line-height: rem-calc(26);
        padding:rem-calc(20) 0 rem-calc(20) 0;

        @include breakpoint(large) {
          font-size: rem-calc(24);
          line-height: rem-calc(70);
          padding: rem-calc(10);
        }
      }
  }
  .features {
    background:#f5f5f5;
    padding-bottom:rem-calc(60);
    padding-top:rem-calc(25);
    text-align:center;

    @include breakpoint(large) {
      padding-top:rem-calc(60);

    }

    > .row {
      padding-left:0;
      padding-right:0;
       .row {
        padding-left:rem-calc(15);
        padding-right:rem-calc(15);

      }
    }

    h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(40);
      font-weight:300;
      line-height:rem-calc(45);
      margin-bottom:rem-calc(20);
      margin-top:rem-calc(25);
      @include breakpoint(large) {
        line-height:rem-calc(50);
        font-size:rem-calc(45);
      }
    }
    .feature {
      background:#fff;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
      margin-bottom:rem-calc(30);
      padding:rem-calc(25) rem-calc(25);
      text-align:left;
      display:table;
      width:100%;


      @include breakpoint(medium) {
        display:block;
        text-align:center;
        padding:0 0 rem-calc(30) 0;
      }
      .feature-icon {
        padding:0 0 0 0;
        display:table-cell;
        vertical-align:middle;
        width:rem-calc(62);
        @include breakpoint(medium) {
          padding:rem-calc(30) 0 rem-calc(15) 0;
          display:block;
          width:100%;
        }
        img {
          height:rem-calc(44);
          @include breakpoint(medium) {
            height:rem-calc(54);
          }
        }

      }
      .feature-title {
        height:auto;
        display:table-cell;
        text-align:left;
        vertical-align:middle;
        margin-bottom:0;
        padding-left:rem-calc(10);

        @include breakpoint(medium) {
          width:100%;
          height:rem-calc(55);
          text-align:center;
          display:table;
          margin-bottom:rem-calc(15);
          padding-left:0;
        }
        p {
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(19);
          font-weight:700;
          line-height:rem-calc(23);
          display:inline-block;
          vertical-align:middle;
          text-align:left;
          margin-bottom:0;

          @include breakpoint(medium) {
            display:table-cell;
            vertical-align:middle;
            text-align:center;
          }

          a {
            color:#696969;
            text-decoration:underline;
            @include breakpoint(medium) {
              text-decoration:none;
            }
            &:hover {
              color:#999;
            }
          }

        }


      }
      .feature-cta {
        padding:0;
        display:table-cell;
        text-align:right;
        vertical-align:middle;
        @include breakpoint(medium) {
          float:none;
          display:block;
          padding:rem-calc(10) rem-calc(20) 0 rem-calc(20);
        }
        a {
          height:rem-calc(50);
          line-height:rem-calc(50);
          border-radius:rem-calc(50);
          color:#fff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(19);
          text-align:center;
          font-weight:600;
          display:inline-block;
          width:rem-calc(104);
          @include breakpoint(medium) {
            display:block;
            width:100%;
          }
        }
      }
    }
    .cta-button {
      width:100%;
      height:rem-calc(50);
      line-height:rem-calc(50);
      color:#fff;
      border-radius:rem-calc(50);
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(19);
      font-weight:600;
      text-align:center;
      display:inline-block;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);

      @include breakpoint(medium) {
        width:rem-calc(388);
      }
    }

    .cta-text {
      line-height:rem-calc(45);
      font-size:rem-calc(40);
      font-weight:300;
      text-align:center;
      margin-top:rem-calc(20);
      margin-bottom:rem-calc(20);
      @include breakpoint(medium) {
        line-height:rem-calc(60);
        font-size:rem-calc(50);
      }
    }
  }
}