.movr-title-wrapper {
  padding: rem-calc(30) 0;

  .movr-title {
    h1 {
      line-height: rem-calc(45);
      font-size: rem-calc(40);
      font-weight: 400;
      color: #1b4886;

      @include breakpoint(large) {
        line-height: rem-calc(60);
        font-size: rem-calc(60);
      }
    }
  }
}

.movr-intro {
  padding-bottom:rem-calc(50);
  position:relative;

  .movr-details {
    margin-bottom:rem-calc(30);
    p {
      font-family: 'Source Sans Pro', sans-serif;
      line-height:rem-calc(23);
      font-size:rem-calc(19);
      font-weight:600;
      color:#1b4886;
      margin:0;
      strong {
        font-weight:600;
      }
    }
  }
  .movr-cta {

    width:auto;
    @include breakpoint(large) {

      width:rem-calc(495);
    }
    a {
      display:block;
      text-align:center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(19);
      font-weight:600;
      margin-bottom:rem-calc(20);
      border-radius:rem-calc(50);
      height:rem-calc(50);
      line-height:rem-calc(50);
      color:#fff;
      box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);

    }
  }
}

