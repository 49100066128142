.about-us {
  padding:rem-calc(30) 0 0 0;
  background:#fff;

  .page-intro {
    padding-bottom:rem-calc(50);
    h1 {
      margin-bottom:rem-calc(20);
      line-height:rem-calc(45);
      font-size:rem-calc(40);
      font-weight:400;
      color:#1b4886;

      @include breakpoint(medium) {
        line-height:rem-calc(60);
        font-size:rem-calc(60);
      }
    }
    p {
      line-height:rem-calc(23);
      font-size:rem-calc(19);
      font-weight:400;
      color:#696969;
      margin-bottom:rem-calc(10);
      strong {
        font-weight:600;
      }

      &:empty {
        display:none;
      }
    }
.intro-cta {
  a {
    display:block;
    text-align:center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size:rem-calc(19);
    font-weight:600;
    margin-top:rem-calc(20);
    border-radius:rem-calc(50);
    height:rem-calc(50);
    line-height:rem-calc(50);
    color:#fff;
    box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
  }
}
  }
  .team {
    background:#f5f5f5;

    .member {
      padding-top:rem-calc(60);
      padding-bottom:rem-calc(45);
      border-top:rem-calc(2) solid #ebebeb;
      text-align:center;

      @include breakpoint(medium) {
        text-align:left;
      }
      &:first-child {
        border-top:none;
      }

      .member-picture {
        margin-bottom:rem-calc(20);
        img {
          width:100%;
          height:auto;
          background:#fff;
          border:rem-calc(1) solid #ebebeb;
          padding:rem-calc(3);
          @include breakpoint(medium) {
            border-radius:0;
            border:none;
            padding:0;
          }
        }
      }
      .member-name {
        color:#298dff;
        line-height:rem-calc(28);
        font-size:rem-calc(24);
        font-weight:700;
      }
      .member-position {
        color:#696969;
        line-height:rem-calc(28);
        font-size:rem-calc(24);
        font-weight:400;
        margin-bottom:rem-calc(20);

      }
      .member-bio {
        color:#696969;

        font-size:rem-calc(19);
        font-weight:400;
        text-align:left;

        p {
          line-height:rem-calc(23);
          margin-bottom:rem-calc(10);
        }
      }

      .member-bio-right {
        color:#696969;
        margin-top:0;
        font-size:rem-calc(19);
        font-weight:400;
        text-align:left;
        @include breakpoint(large) {
          margin-top:rem-calc(76);
        }
        p {
          line-height:rem-calc(23);
          margin-bottom:rem-calc(10);
        }
      }
    }
  }

  .customers {
    background:#f5f5f5;
    padding:rem-calc(60) 0 rem-calc(30) 0;

    .customer {
      margin-bottom:rem-calc(30);
      background:#ffffff;
      height:rem-calc(117);
      text-align:center;

      img {
        max-height:100%;
      }
    }
  }
}
