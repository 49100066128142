.blog-page {
  padding:rem-calc(60) 0;
  background:#f5f5f5;
  .recent-posts {
    .count {
      font-family: 'Source Sans Pro', sans-serif;
      font-size:rem-calc(24);
      line-height:rem-calc(28);
      font-weight:700;
      color:#696969;

    }
    h3.recent-posts-title {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      color:#696969;
      margin-bottom:rem-calc(-12);
    }
    .post-item {
      padding:rem-calc(28) 0;
      border-bottom:rem-calc(2) solid #dcdcdc;

      .row {
        padding-left:0;
        padding-right:0;
        @include breakpoint(large) {
          padding-left:0;
          padding-right:0;
        }
      }
      .post-thumbnail {
        margin-bottom:rem-calc(20);
        @include breakpoint(large) {
          margin-bottom:0;
        }
        img {
          height:auto;
          min-width:100%;
          @include breakpoint(large) {
            height:rem-calc(160);
            min-width:100%;
          }
          &:hover {
              opacity:0.8;
          }
        }


      }

      .post-details {
        h3 {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(24);
          font-weight: 700;
          line-height: rem-calc(26);
          margin-bottom:0;
          a {
            color:#1b4886;

            &:hover {
              color:#2D5F86;
            }
          }
        }

        h5 {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(16);
          font-weight: 600;
          line-height: rem-calc(19);
          font-style:italic;
          color:#696969;
        }

        p {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(19);
          font-weight: 400;
          line-height: rem-calc(23);
          color:#696969;
          margin-bottom:rem-calc(10);
        }

        .read-more {
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(19);
          line-height: rem-calc(23);
          font-weight: 700;
          color:#696969;
          img {
            width: rem-calc(27);
            height: rem-calc(18.6);
            margin-left: rem-calc(6);
          }

          &:hover {
            opacity:0.7;
          }
        }

      }

      .row {
        .medium-4 {
          padding-left:rem-calc(20);
          padding-right:rem-calc(20);
          @include breakpoint(large) {
            padding-left:rem-calc(5);
          }
        }
        .medium-8 {
          padding-left:rem-calc(20);
          padding-right:rem-calc(20);
          @include breakpoint(large) {
            padding-right:0;
          }
        }
      }
    }

    .pagination {
      .page-numbers {
        display:none;
      }
      .next {
        display:block;
        text-align:center;
        color: #1b4886;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(24);
        font-weight: 700;
        line-height: rem-calc(28);

        &:hover {
          background:none;
          opacity:0.7;
        }

        &:after {
          content:url('../img/arrow-up-b.svg');
          width:rem-calc(21);
          height:rem-calc(13);
          display:inline-block;
        }
      }
    }

    .accordion {
      .accordion-item {
        .accordion-title {
          background:#1b4886;

          border:none;
          border-bottom:rem-calc(2) solid #f5f5f5;

          padding:rem-calc(15) rem-calc(50) rem-calc(15) rem-calc(30);
          &[aria-expanded="true"] {
            background:#ebebeb;

            border-bottom:rem-calc(2) solid #ebebeb;

            &:before {
              content:url('../img/arrow-up-b.svg');
              -ms-transform: rotate(180deg); /* IE 9 */
              -webkit-transform: rotate(180deg); /* Safari */
              transform: rotate(180deg);
            }

            h3,p {
              color:#1b4886;
            }
          }
          &:before {
            content:url('../img/arrow-up-w.svg');
            width:rem-calc(20);
            height:rem-calc(12);
            top:45%;
            right:rem-calc(20);
            -webkit-transition: all 0.2s; /* Safari */
            transition: all 0.2s;
            @include breakpoint(large) {
              width:rem-calc(31);
              height:rem-calc(19);
            }
          }

          h3 {
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(24);
            font-weight: 700;
            line-height:rem-calc(26);
          }
          p {
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height:rem-calc(23);
            margin:0;
          }
        }
        .accordion-content {
          background: #ebebeb;
          border: none;
          padding: 0 rem-calc(30) rem-calc(20) rem-calc(30);

          .features {
            .feature {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(19);
              font-weight: 400;
              line-height:rem-calc(23);
              color:#696969;
              margin-bottom:rem-calc(5);
              strong {

                font-weight: 700;

              }
              a {
                color:#696969;
                text-decoration:underline;
              }
              .star {
                width:rem-calc(14);
                height:rem-calc(13.5);
                display:inline-block;
                background:url('../img/star.svg') no-repeat center center;
                margin-right:0;
                padding-right:0;
              }
            }
          }
          .accordion-cta-box {

            margin-top:rem-calc(30);
            padding-bottom:rem-calc(30);
            .intro {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(24);
              font-weight: 700;
              line-height:rem-calc(50);
              color:#6a8e2c;
              float:none;
              text-align:center;
              @include breakpoint(large) {
                float:left;
                text-align:left;
              }
            }
            .cta {
              float:none;
              @include breakpoint(large) {
                float:right;
              }
              a {
                width:100%;
                height:rem-calc(50);
                display:block;
                text-align:center;
                border-radius:rem-calc(50);
                border:none;
                font-family: 'Source Sans Pro', sans-serif;
                font-size:rem-calc(19);
                line-height:rem-calc(50);
                font-weight:600;
                background:#6a8e2c;
                color:#fff;
                box-shadow: rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0, 0, 0, 0.2);
                @include breakpoint(large) {
                  width:rem-calc(372);
                }
                &:hover {
                  background: #1f6e08;
                }
              }
            }
          }
        }
      }
    }
  }

  .search-box {
    background:#ebebeb;
    padding:rem-calc(20);
    margin-bottom:rem-calc(30);
    @include breakpoint(large) {
      padding:rem-calc(30);
    }
    h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      margin:0 0 rem-calc(20) 0;
      padding:0;
    }
    form {
      position:relative;
      margin-bottom:0;
      input {
        height:rem-calc(50);
        line-height:rem-calc(50);
        background:#fff;
        border:none;
        box-shadow:none;
        margin-bottom:0;
      }
      button {
        position:absolute;
        right:rem-calc(15);
        top:rem-calc(15);
        margin-bottom:0;
        img {
          width:rem-calc(18.8);
          height:rem-calc(18.7);
        }
      }
      &:hover {
        button {
          opacity:0.7;
        }

      }
    }
  }

  .filters-box {
    background: #ebebeb;
    padding: rem-calc(20) rem-calc(20) 0 rem-calc(20);
    margin-bottom:rem-calc(30);
    @include breakpoint(large) {
      padding:rem-calc(30) rem-calc(30) 0 rem-calc(30);
    }
    h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      margin: 0 0 0 0;
      padding: 0 0 rem-calc(15) 0;
      border-bottom: rem-calc(2) solid #dcdcdc;
    }
    .filters-box-content {
      .filters-accordion {
        list-style: none;
        margin: 0;
        padding: 0;
        .accordion-item {
          padding:rem-calc(15) 0 rem-calc(15) 0;
          border-top: rem-calc(2) solid #dcdcdc;
          &:first-child {
            border-top:none;
          }
          .accordion-title {
            background: none;
            color: #298dff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 700;
            border: none;
            text-transform:uppercase;
            line-height: rem-calc(23);
            padding: 0;
            position:relative;
            &[aria-expanded="true"] {

              color:#298dff;


              &:before {
                background:url('../img/arrow-up-sb.svg') no-repeat center center;
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari */
                transform: rotate(180deg);
              }
            }
            &:before {
              background:url('../img/arrow-up-sb.svg') no-repeat center center;
              top:0;
              content:'';
              width:rem-calc(20);
              height:auto;
              margin-top:0;
              position:absolute;
              bottom:0;
              right: 0;
              background-size:rem-calc(20) rem-calc(12);
              -webkit-transition: all 0.2s; /* Safari */
              transition: all 0.2s;
            }
          }
          .accordion-content {
            background: none;
            border: none;
            padding:rem-calc(15) 0 0 0;
            margin:0;

            .filter-item {
              margin-bottom:rem-calc(2);
              label {
                color: #696969;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(19);
                font-weight: 400;
                line-height: rem-calc(23);
                margin-left:0;
              }

              [type="checkbox"]:not(:checked),
              [type="checkbox"]:checked {
                position: absolute;
                left: rem-calc(-9999);
              }
              [type="checkbox"]:not(:checked) + label,
              [type="checkbox"]:checked + label {
                position: relative;
                padding-left: rem-calc(25);
                cursor: pointer;
              }

              /* checkbox aspect */
              [type="checkbox"]:not(:checked) + label:before,
              [type="checkbox"]:checked + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 2px;
                width: rem-calc(17);
                height: rem-calc(17);
                border: rem-calc(2) solid #696969;
                background: none;

              }
              /* checked mark aspect */
              [type="checkbox"]:not(:checked) + label:after,
              [type="checkbox"]:checked + label:after {
                content: '×';
                position: absolute;
                top: rem-calc(-2);
                left: rem-calc(1.6);
                font-size: rem-calc(26);
                font-weight:bold;
                color: #696969;
                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                -ms-transition: all .2s;
                transition: all .2s;
              }
              /* checked mark aspect changes */
              [type="checkbox"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                -ms-transform: scale(0);
                transform: scale(0);
              }
              [type="checkbox"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
              }
              /* disabled checkbox */
              [type="checkbox"]:disabled:not(:checked) + label:before,
              [type="checkbox"]:disabled:checked + label:before {
                box-shadow: none;
                border-color: #bbb;
                background-color: #ddd;
              }
              [type="checkbox"]:disabled:checked + label:after {
                color: #999;
              }
              [type="checkbox"]:disabled + label {
                color: #aaa;
              }
              /* accessibility */
              [type="checkbox"]:checked:focus + label:before,
              [type="checkbox"]:not(:checked):focus + label:before {
                border: 1px solid #fff;
              }
            }
          }
        }
      }
    }
  }

  .archives-box {
    background: #ebebeb;
    padding: rem-calc(20) rem-calc(20) rem-calc(5) rem-calc(20);
    margin-bottom:rem-calc(30);
    @include breakpoint(large) {
      padding:rem-calc(30) rem-calc(30) rem-calc(5) rem-calc(30);
    }
    h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      margin: 0;
      padding: 0 0 rem-calc(15) 0;
    }
    .archives-box-content {
      .filter-item {
        margin-bottom:rem-calc(10);
        label {
          color: #696969;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(19);
          font-weight: 400;
          line-height: rem-calc(23);
          margin-left:0;
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          position: absolute;
          left: rem-calc(-9999);
        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          position: relative;
          padding-left: rem-calc(25);
          cursor: pointer;
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          width: rem-calc(17);
          height: rem-calc(17);
          border: rem-calc(2) solid #696969;
          background: none;

        }
        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content: '×';
          position: absolute;
          top: rem-calc(-2);
          left: rem-calc(1.6);
          font-size: rem-calc(26);
          font-weight:bold;
          color: #696969;
          -webkit-transition: all .2s;
          -moz-transition: all .2s;
          -ms-transition: all .2s;
          transition: all .2s;
        }
        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          transform: scale(0);
        }
        [type="checkbox"]:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
        }
        /* disabled checkbox */
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
          box-shadow: none;
          border-color: #bbb;
          background-color: #ddd;
        }
        [type="checkbox"]:disabled:checked + label:after {
          color: #999;
        }
        [type="checkbox"]:disabled + label {
          color: #aaa;
        }
        /* accessibility */
        [type="checkbox"]:checked:focus + label:before,
        [type="checkbox"]:not(:checked):focus + label:before {
          border: 1px solid #fff;
        }
      }
    }
  }
}
.single-post-page {
  background:#f5f5f5;
  padding:rem-calc(30) 0 rem-calc(80) 0;
  .margin-top {
    margin-top:rem-calc(15);
  }
  .single-post {
    h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(40);
      font-weight: 400;
      line-height: rem-calc(45);
      color:#1b4886;
      @include breakpoint(large) {
        line-height:rem-calc(60);
        font-size:rem-calc(60);
      }
    }

    h5 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(16);
      font-weight: 600;
      line-height: rem-calc(19);
      color:#696969;
      font-style:italic;
    }
  }
  .single-post-content {
    .post-subtitle {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(19);
      font-weight: 600;
      line-height: rem-calc(23);
      color:#1b4886;
      margin-bottom:rem-calc(30);
    }
    .post-image {
      margin-bottom:rem-calc(30);
    }
    .post-boxed-paragraph {
      background:#fff;
      padding:rem-calc(30);
      margin-bottom:rem-calc(30);
      > h3 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 700;
        line-height: rem-calc(23);
        color:#1b4886;
        text-transform:uppercase;
      }
      .boxed-paragraph-content {
        ul {
          margin-bottom:0;
          padding-bottom:0;
          li {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height: rem-calc(23);
            color:#696969;
            margin-top:rem-calc(10);
          }
        }

      }
    }
    .post-plain-paragraph {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(19);
      font-weight: 400;
      line-height: rem-calc(23);
      color:#696969;
      margin-bottom:rem-calc(30);
      p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 400;
        line-height: rem-calc(23);
        color:#696969;
      }
      h3 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 700;
        line-height: rem-calc(23);
        color:#1b4886;
        text-transform:uppercase;
      }
      h4 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 700;
        line-height: rem-calc(23);
        color:#696969;
      }
    }
    .post-quote-box {
      width:100%;
      margin:0 auto rem-calc(30) auto;
      @include breakpoint(large) {
        width:85%;
      }
      p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(30);
        font-weight: 300;
        line-height: rem-calc(35);
        color:#298dff;
        @include breakpoint(large) {
          line-height:rem-calc(50);
          font-size:rem-calc(45);
        }
      }
      h5 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 600;
        line-height: rem-calc(23);
        color:#298dff;
      }
    }
  }
  .related {
    background: #ebebeb;
    padding: rem-calc(30);
    margin-bottom:rem-calc(30);
    margin-top:rem-calc(30);
    @include breakpoint(large) {
      margin-top:rem-calc(0);
    }

    h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      margin: 0;
      padding: 0 0 rem-calc(20) 0;

    }
    ul {
      list-style:none;
      margin:0;
      padding:0;
      li {
        border-top: rem-calc(2) solid #dcdcdc;
        padding:rem-calc(20) 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(19);
        font-weight: 600;
        line-height: rem-calc(23);
        a {
          color: #298dff;

          &:hover {
            color:#1b4886;
          }
        }
      }
    }
  }
}
.share {
  border-top:rem-calc(2) solid #dcdcdc;
  padding-top:rem-calc(20);
  span {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: rem-calc(24);
    font-weight: 700;
    color:#298dff;
    display:inline-block;
    margin-right:rem-calc(10);
    float:left;
  }
  .attachment {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: rem-calc(24);
    font-weight: 700;
    color:#298dff;
    display:block;
    margin-left:0;
    float:none;
    clear:both;
    img {
      width:rem-calc(19.6);
      height:rem-calc(26.7);
      margin-left:rem-calc(10);
    }

    @include breakpoint(large) {
      float:left;
      display:inline-block;
      margin-left:rem-calc(40);
      clear: none;
    }

    &:hover {
      color:#195599;
      img {
        -webkit-filter: brightness(60%);
        filter: brightness(60%);
      }
    }
  }
  ul {
    list-style:none;
    display:inline;
    margin:0;
    padding:0;
    float:none;
    @include breakpoint(medium) {
      float:left;
    }
    li {
      display:inline-block;
      margin:0 rem-calc(10);
      a {
        &:hover {
          img {


          -webkit-filter: brightness(60%);
          filter: brightness(60%);
          }
        }


        &.twitter {
          img {
            width:rem-calc(27.22);
            height:rem-calc(22.99);
            margin-top:rem-calc(4);
          }
        }
        &.facebook {
          img {
            width:rem-calc(12.7);
            height:rem-calc(27.22);
          }
        }
        &.linkedin {
          img {
            width:rem-calc(27.22);
            height:rem-calc(27.22);
          }
        }
        &.email {
          img {
            width:rem-calc(29.4);
            height:rem-calc(20.18);
            margin-top:rem-calc(6.8);
          }
        }
      }
    }
  }
}