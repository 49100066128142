.product-intro {
  padding-bottom:rem-calc(50);
  position:relative;

  .product-details {
    margin-bottom:rem-calc(30);
    font-family: 'Source Sans Pro', sans-serif;
    line-height:rem-calc(23);
    font-size:rem-calc(19);
    p {
      line-height:rem-calc(23);
      font-weight:400;
      color:#696969;

      a {
        color:#696969;
        font-weight:400;
        text-decoration:underline;
        &:hover {
          opacity:0.7;
        }
      }
      &:first-child {
        color:#1b4886;
        font-weight:600;
        margin-bottom:rem-calc(10);
        a {
          color:#1b4886;
          font-weight:600;
          text-decoration:underline;
          &:hover {
            opacity:0.7;
          }
        }
      }



    }
    ul {
      li {
        line-height:rem-calc(23);
        font-size:rem-calc(19);
        color:#696969;
      }
    }
  }
  .product-ctas {

    width:auto;

    @include breakpoint(large) {

      width:rem-calc(495);
    }
       a {
         display:block;
         text-align:center;
         font-family: 'Source Sans Pro', sans-serif;
         line-height:rem-calc(23);
         font-size:rem-calc(19);
         font-weight:600;
         margin-bottom:rem-calc(20);
         width:100%;
         &.try-browser {
           color:#298dff;
           position:relative;
           &:after {
             position:absolute;
             margin-left:rem-calc(7);
             top:rem-calc(4);
             content:' ';
             display:inline-block;
             width:rem-calc(27);
             height:rem-calc(18.6);
             background:url('../img/arrow-right-blue.svg') no-repeat center center;
             background-size:rem-calc(27) rem-calc(18.6);
           }

           &:hover {
             -webkit-filter: brightness(60%);
             filter: brightness(60%);
           }
         }
         &.trail-period {
           border:rem-calc(2) solid #298dff;
           border-radius:rem-calc(50);
           height:rem-calc(50);
           line-height:rem-calc(50);
           color:#298dff;
           &:hover {
             color:#1b4886;
             border-color:#1b4886;
           }
         }
         &.buy-now {
           background:#298dff;
           border-radius:rem-calc(50);
           height:rem-calc(50);
           line-height:rem-calc(50);
           color:#fff;
           box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
           &:hover {
             background:#1b4886;
           }
         }
       }

  }
}