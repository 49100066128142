.login-page {
  padding-top:0;
  @include breakpoint(large) {
    padding-top:rem-calc(60);

  }
  .main-title {
    background:#298dff;
    min-height: auto;
    text-align: center;
    position: relative;
    @include breakpoint(large) {
      background: #0d7eff;
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(large) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(15);
      margin-left: rem-calc(-15);

      @include breakpoint(large) {
        border-width: rem-calc(30);
        margin-left: rem-calc(-30);
      }
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: rem-calc(18);
      margin-left: rem-calc(-18);
      @include breakpoint(large) {
        border-width: rem-calc(36);
        margin-left: rem-calc(-36);
      }
    }

    h5 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      padding:rem-calc(20) 0 rem-calc(5) 0;
      margin:0;
      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: rem-calc(30);
        padding: rem-calc(20) 0 rem-calc(20) 0;
      }
    }

    p {
      color:#fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(19);
      font-weight: 600;
      line-height: rem-calc(23);
      margin-top:0;
      @include breakpoint(large) {
        margin-top:rem-calc(-15);
      }


      &:empty {
        display:none;
        margin:0;
        padding:0;
        height:0;
      }
    }
  }
  .login-page-form {
    background:#298dff;
    padding-top:rem-calc(20);
    @include breakpoint(large) {
      padding-top:rem-calc(60);
    }
    .tml-login {
      .message {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(18);
        font-weight: 700;
        line-height:rem-calc(24);
        margin-left:0;
        text-align:center;
        background:none;
        border:none;
      }
      .tml-user-login-wrap {

        label {
          font-weight:600;
          color:#fff;
          font-size: rem-calc(19);
          line-height: rem-calc(23);
        }
        input {
          box-shadow:none;
          border:none;
        }
      }
      .tml-user-pass-wrap {

        label {
          font-weight:600;
          color:#fff;
          font-size: rem-calc(19);
          line-height: rem-calc(23);
        }
        input {
          box-shadow:none;
          border:none;
        }
      }
      .tml-action-links {
        float:right;
        li {
          a {
            font-weight:600;
            color:#fff;
            font-size: rem-calc(19);
            line-height: rem-calc(23);

            &:after {
              content: '?';
            }

            &:hover {
              opacity:0.7;
            }
          }
        }
      }
      .tml-submit-wrap {
        clear:both;
        display:block;

        input {
          display:block;
          width:100%;
          height:rem-calc(50);
          line-height:rem-calc(50);
          color:#fff;
          font-size: rem-calc(19);
          font-weight:600;
          border-radius:rem-calc(50);
          background:#1b4886;
          border:none;
          font-family: 'Source Sans Pro', sans-serif;
          box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);

          &:hover {
            background:#0a3a74;
            cursor:pointer;
          }
        }
      }
      .tml-rememberme-wrap {
        float:left;
        &:hover {
          opacity:0.7;
        }
        label {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: rem-calc(19);
          font-weight: 600;
          color: #fff;

        }
        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          position: absolute;
          left: -9999px;

        }
        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
          position: relative;
          padding-left: rem-calc(25);
          cursor: pointer;
          @include breakpoint(large) {
            padding-left: rem-calc(35);
          }
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: rem-calc(2);
          width: rem-calc(20);
          height: rem-calc(20);
          border: 2px solid #fff;
          background: none;


        }
        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
          content: '×';
          position: absolute;
          top: rem-calc(-14);
          left: rem-calc(2.7);
          font-size: rem-calc(28);
          color: #fff;
          -webkit-transition: all .2s;
          -moz-transition: all .2s;
          -ms-transition: all .2s;
          transition: all .2s;
        }
        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          transform: scale(0);
        }
        [type="checkbox"]:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          transform: scale(1);
        }
        /* disabled checkbox */
        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:checked + label:before {
          box-shadow: none;
          border-color: #bbb;
          background-color: #ddd;
        }
        [type="checkbox"]:disabled:checked + label:after {
          color: #999;
        }
        [type="checkbox"]:disabled + label {
          color: #aaa;
        }
        /* accessibility */
        [type="checkbox"]:checked:focus + label:before,
        [type="checkbox"]:not(:checked):focus + label:before {
          border: 1px solid #fff;
        }

      }
    }

  }

  .tml {
    max-width:100%;
  }

  .register-block {

    padding:rem-calc(80) 0;

    .cta-text {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(45);
      font-weight: 300;
      line-height: rem-calc(50);
      color:#1b4886;
      float:none;
      margin-left:0;
      text-align:center;

      @include breakpoint(large) {
        float:left;
        margin-left:rem-calc(-30);
        text-align:left;
        font-size: rem-calc(60);
        line-height: rem-calc(60);
      }
    }
    .cta-button {
      float:none;
      margin-top:rem-calc(20);
      @include breakpoint(large) {
        float:right;
        margin-top:0;
      }
      a {
        display:block;
        width:100%;
        height:rem-calc(50);
        line-height:rem-calc(50);
        color:#fff;
        font-size: rem-calc(19);
        font-weight:600;
        border-radius:rem-calc(50);
        background:#1b4886;
        border:none;
        text-align:center;
        font-family: 'Source Sans Pro', sans-serif;
        box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
        &:hover {
          background:#0a3a74;
          cursor:pointer;
        }

        @include breakpoint(large) {
          width:rem-calc(564);
        }
      }
    }
  }
}