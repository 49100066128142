.documentation {
  padding:rem-calc(30) 0 0 0;

  .page-intro {

    h1 {
      margin-bottom:rem-calc(20);
      line-height:rem-calc(45);
      font-size:rem-calc(40);
      font-weight:400;
      color:#1b4886;

      @include breakpoint(large) {
        line-height:rem-calc(60);
        font-size:rem-calc(60);
      }
    }


  }
  .page-documentation {
    padding-bottom:rem-calc(50);
    p {
      line-height:rem-calc(23);
      font-size:rem-calc(19);
      font-weight:400;
      color:#696969;
      strong {
        font-weight:600;
      }

      &:empty {
        display:none;
      }
    }
  }

}

.documentation-content {
  background: #f5f5f5;
  padding-top: rem-calc(30);
  padding-bottom:0;

  @include breakpoint(large) {
    padding-top: rem-calc(60);
    padding-bottom:rem-calc(30);
  }
  
  & > .row {
      & > div {
          @include breakpoint(medium only) {
              &:nth-of-type(2n+1) {
                  clear: both;
              }
          }
          
          @include breakpoint(large) {
            &:nth-of-type(3n+1) {
                  clear: both;
            }
          }
      }
  }

  .doc-block {
    background:#ebebeb;
    margin-bottom:rem-calc(30);
    padding:rem-calc(30);
    position:relative;
    .doc-block-content {
      h3 {
        font-size: rem-calc(24);
        line-height: rem-calc(26);
        font-weight:700;
        color:#298dff;
        padding-bottom:rem-calc(10);
      }
      > ul {
        list-style:none;
        margin:0 0 rem-calc(30) 0;
        padding:0 0 rem-calc(100) 0;
        > li {
          border-top:rem-calc(2) solid #dcdcdc;
          text-transform:uppercase;

          > a {
            color:#298dff;
            position:relative;
            display:block;
            padding:rem-calc(10) 0;
            font-size: rem-calc(19);
            font-weight:700;
            img {
              position:absolute;
              right:0;
              top:rem-calc(18);
              &.view {
                width:rem-calc(25.1);
                height:rem-calc(19.6);
              }
              &.download {
                width:rem-calc(19.6);
                top:rem-calc(13);
              }
            }

            &:hover {
              -webkit-filter: brightness(60%);
              filter: brightness(60%);
            }
          }

          .accordion {
            background:none;
            border:none;
            .accordion-item {

              .accordion-title {
                background:none;
                border:none;
                color:#298dff;
                position:relative;
                display:block;
                padding:rem-calc(15) rem-calc(30) rem-calc(15) 0;
                font-size: rem-calc(19);
                font-weight:700;
                text-transform:uppercase;
                &[aria-expanded="true"] {
                  background:none;
                  color:#298dff;
                  &:before {
                    -ms-transform: rotate(180deg); /* IE 9 */
                    -webkit-transform: rotate(180deg); /* Safari */
                    transform: rotate(180deg);
                  }
                }
                &:before {
                  background:url('../img/arrow-up-sb.svg') no-repeat center center;
                  top:0;
                  content:'';
                  width:rem-calc(20);
                  height:auto;
                  margin-top:0;
                  position:absolute;
                  bottom:0;
                  right:0;
                  background-size:rem-calc(20) rem-calc(12);
                  -webkit-transition: all 0.2s; /* Safari */
                  transition: all 0.2s;
                  @include breakpoint(large) {
                    width:rem-calc(24);
                    background-size:rem-calc(24) rem-calc(14);
                  }
                }
                &:hover {
                  -webkit-filter: brightness(60%);
                  filter: brightness(60%);
                }
              }
              .accordion-content {
                background:none;
                border:none;

                padding:0;
                > ul {
                  list-style: none;
                  margin: 0;
                  padding: 0;
                  > li {
                    border-top: rem-calc(2) solid #dcdcdc;
                    > a {
                      color: #696969;
                      position: relative;
                      display: block;
                      padding: rem-calc(10) rem-calc(30) 0 0;
                      font-size: rem-calc(19);
                      font-weight: 400;
                      img {
                        position: absolute;
                        right: 0;
                        top: rem-calc(18);
                        &.view {
                          width: rem-calc(25.1);
                          height: rem-calc(19.6);
                        }
                        &.download {
                          width: rem-calc(19.6);
                          top: rem-calc(13);
                        }
                      }

                      &:hover {
                        -webkit-filter: brightness(60%);
                        filter: brightness(60%);
                      }
                    }
                    ul.accordion {
                      li {
                        a {
                          color: #696969;
                          font-weight: 400;

                          &:before {
                            background: url('../img/arrow-down-g.svg') no-repeat center center;
                          }
                        }
                        .accordion-content {
                          text-transform:none;
                          p {
                            text-transform:none;
                            color: #696969;
                            a {
                              color: #696969;
                              text-decoration:underline;
                            }
                          }
                          ul {
                            margin-bottom:rem-calc(15);
                            li {
                              color: #696969;
                              border:none;
                              text-transform:none;
                              a {
                                color: #696969;
                              }
                            }
                          }
                        }

                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .doc-cta {
      position:absolute;
      bottom:rem-calc(30);
      left:0;
      right:0;

      a {
        display:block;
        text-align:center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size:rem-calc(19);
        font-weight:600;
        color:#fff;
        border-radius:rem-calc(50);
        height:rem-calc(50);
        line-height:rem-calc(50);
        background:#298dff;
        margin: 0 rem-calc(30);
        box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
        &:hover {
          background:#1b4886;
        }
      }
    }
  }
}
