.solutions-in-action {
  .main-title {
    background:#153c6a;
    min-height:rem-calc(90);

    text-align:center;
    position: relative;

    &:after,
    &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #153c6a;
      border-width: rem-calc(15);
      margin-left: rem-calc(-15);

      @include breakpoint(large) {
        border-width: rem-calc(30);
        margin-left: rem-calc(-30);
      }
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #153c6a;
      border-width: rem-calc(18);
      margin-left: rem-calc(-18);
      @include breakpoint(large) {
        border-width: rem-calc(36);
        margin-left: rem-calc(-36);
      }
    }

      h5 {
        color:#fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size:rem-calc(24);
        font-weight:700;
        line-height:rem-calc(26);
        padding:rem-calc(20) 0 rem-calc(10) 0;

        @include breakpoint(medium) {
          font-size:rem-calc(24);
          line-height:rem-calc(70);
          padding:rem-calc(10);
        }
      }
  }
  .video-blocks {
    background:#1b4886;
    padding-top:rem-calc(25);
    padding-bottom:rem-calc(5);
    @include breakpoint(medium) {
      padding-top:rem-calc(30);
      padding-bottom:rem-calc(30);
    }
    @include breakpoint(large) {
      padding-top:rem-calc(60);
    }
      .video-details {
        margin-bottom:rem-calc(30);
        h3 {
          color:#fff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(30);
          font-weight:600;
          text-transform:uppercase;
          line-height:rem-calc(35);
          margin-top:rem-calc(5);
          margin-bottom:rem-calc(20);
          @include breakpoint(medium) {
            margin-top:rem-calc(20);
          }
          @include breakpoint(large) {
            margin-top:rem-calc(5);
          }
        }
        p {
          color:#fff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size:rem-calc(19);
          font-weight:400;
          line-height:rem-calc(23);
        }
        a.video-block-cta {
          margin-top:rem-calc(25);
          background:#298dff;
          color:#fff;
          display:block;
          height:rem-calc(50);
          font-family: 'Source Sans Pro', sans-serif;
          line-height:rem-calc(50);
          border-radius:rem-calc(50);
          text-align:center;
          font-size:rem-calc(19);
          font-weight:600;
          box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);

          &:hover {
            background:#153C6A;
          }
        }

        @include breakpoint(large) {
          margin-bottom:0;
        }
      }
      .responsive-embed {
        box-shadow:rem-calc(6.428) rem-calc(7.66) rem-calc(15) rgba(0,0,0,0.2);
        margin-bottom:rem-calc(0);
        @include breakpoint(medium) {
          margin-bottom:0;
        }
      }
      .bordered-top {
        border-top:rem-calc(2) solid #153c6a;
        margin:rem-calc(60) rem-calc(15) 0 rem-calc(15);
        padding-top:rem-calc(20);
        display:none;
        @include breakpoint(medium) {
          display:block;
        }
      }

    .bordered-bottom {
      border-bottom:rem-calc(2) solid #153c6a;
      margin:0 rem-calc(15) rem-calc(60) rem-calc(15);
      padding-bottom:rem-calc(15);
      display:none;
      @include breakpoint(medium) {
        display:block;
      }
    }
      .quote {

        text-align:left;


        h2 {
          font-family: 'Source Sans Pro', sans-serif;
          line-height:rem-calc(50);
          color:#fff;
          font-size:rem-calc(45);
          font-weight:300;
          margin:0;

          strong {
            font-weight:600;
          }

          span {
            font-size:rem-calc(16);
            font-weight:600;
            font-style:italic;
          }
        }

      }

  }
}