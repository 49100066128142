.the-page {
  padding:rem-calc(30) 0 0 0;

  .page-intro {
    padding-bottom:rem-calc(50);
    h1 {
      margin-bottom:rem-calc(30);
      line-height:rem-calc(45);
      font-size:rem-calc(40);
      font-weight:400;
      color:#1b4886;

      @include breakpoint(large) {
        line-height:rem-calc(60);
        font-size:rem-calc(60);
      }
    }
    p {
      line-height:rem-calc(23);
      font-size:rem-calc(19);
      font-weight:600;
      color:#1b4886;
      strong {
        font-weight:600;
      }

      &:empty {
        display:none;
      }
    }

  }
}
