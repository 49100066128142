.previous-report {
  .main-title {
    background: #f5f5f5;
    min-height: auto;
    text-align: center;
    position: relative;
    @include breakpoint(large) {
      background: #ebebeb;
      min-height: rem-calc(90);
    }
    &:after,
    &:before {
      display:none;
      @include breakpoint(large) {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display:block;
      }
    }

    &:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(30);
      margin-left: rem-calc(-30);
    }
    &:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #ebebeb;
      border-width: rem-calc(36);
      margin-left: rem-calc(-36);
    }

    h5 {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(36);
      padding: rem-calc(10);

      @include breakpoint(large) {
        font-size: rem-calc(24);
        line-height: rem-calc(70);
        padding: rem-calc(10);
      }
    }
  }
  .reports {
    background: #f5f5f5;
    padding-top: rem-calc(0);
    padding-bottom:rem-calc(30);
    @include breakpoint(large) {
      padding-top: rem-calc(60);
      padding-bottom:rem-calc(60);
    }
    .copying-notes {
      margin-top:rem-calc(15);
      p {
        color:#696969;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(14);
        line-height:rem-calc(17);
        font-weight: 400;
        margin-bottom:rem-calc(12);
      }
      strong {
        color:#696969;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: rem-calc(14);
        line-height:rem-calc(17);
        font-weight: 700;
      }
    }
    .years {
      .year {
        h3 {
          font-size: rem-calc(24);
          font-weight: 700;
          line-height:rem-calc(26);
          color:#696969;
        }
        .report-accordion {
          .accordion {
            .accordion-item {
              border-bottom:rem-calc(2) solid #f5f5f5;
              .accordion-title {
                background:#1b4886;
                color:#fff;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(21);
                font-weight: 700;
                border:none;
                position:relative;
                height:auto;
                line-height:rem-calc(28);
                padding-top:rem-calc(15);
                padding-bottom:rem-calc(15);
                padding-left:rem-calc(30);
                @include breakpoint(large) {
                  height:rem-calc(60);
                  line-height:rem-calc(60);
                  font-size: rem-calc(24);
                  padding-top:0;
                  padding-bottom:0;
                }
                &[aria-expanded="true"] {
                  background:#ebebeb;
                  color:#1b4886;
                  border-bottom:rem-calc(2) solid #ebebeb;

                  &:before {
                    background:url('../img/arrow-up-b.svg') no-repeat center center;
                    -ms-transform: rotate(180deg); /* IE 9 */
                    -webkit-transform: rotate(180deg); /* Safari */
                    transform: rotate(180deg);

                  }
                }
                &:before {
                  background:url('../img/arrow-up-w.svg') no-repeat center center;
                  top:0;
                  content:'';
                  width:rem-calc(20);
                  height:auto;
                  margin-top:0;
                  position:absolute;
                  bottom:0;
                  right: rem-calc(30);
                  background-size:rem-calc(20) rem-calc(12);
                  -webkit-transition: all 0.2s; /* Safari */
                  transition: all 0.2s;
                  @include breakpoint(large) {
                    width:rem-calc(31);
                    background-size:rem-calc(31) rem-calc(19);
                  }
                }
              }
              .accordion-content {
                background:#ebebeb;
                border:none;
                padding: 0 rem-calc(30);

                ul {
                  list-style:none;
                  margin:0;
                  padding:0;
                  li {
                    border-top:rem-calc(2) solid #ddd;
                    font-size: rem-calc(19);
                    font-weight: 600;
                    line-height:rem-calc(23);
                    padding:rem-calc(15) 0;



                    a {


                      display:block;
                      color:#696969;
                      position:relative;

                      img {
                        position:absolute;
                        right:rem-calc(0);
                        &.download {
                          width:rem-calc(19.6);
                          height:rem-calc(26.7);
                        }
                        &.view {
                          width:rem-calc(25.1);
                          height:rem-calc(19.6);
                        }
                      }

                      &:hover {
                        -webkit-filter: brightness(60%);
                        filter: brightness(60%);
                      }
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }
}