body:not(.home){
  background:#fff;
  font-family: 'Source Sans Pro', sans-serif;
  padding-top:rem-calc(75);
  @include breakpoint(large) {

    padding-top:rem-calc(60);
  }
}
body.home {
  padding-top:rem-calc(75);
  @include breakpoint(large) {
    padding-top:0;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Source Sans Pro', sans-serif;
}
.row {
  margin-right:rem-calc(30);
  margin-left:rem-calc(30);
  @include breakpoint(large) {
    padding-right:rem-calc(10);
    padding-left:rem-calc(10);
    margin-right:auto;
    margin-left:auto;
  }

  .columns {
    @include breakpoint(small only) {
      padding-left:0;
      padding-right:0;
    }
  }
}
.columns:last-child:not(:first-child) {
  float: left;
}
.main-page {
  background:#fff;

}
.breadcrumbs {
  margin-bottom:rem-calc(10);
  li {
    line-height:rem-calc(19);
    font-size:rem-calc(14);
    font-weight:400;
    text-transform:none;
    a {

      color:#696969;
    }
    &:not(:last-child) {
      &:after {
        content:'>';
        margin-left:rem-calc(5);
        margin-right:rem-calc(5);
      }
    }
  }
}

.pagination {
  margin-top:rem-calc(30);
  .page-numbers {
    display:inline-block;
  }
}

.nowrap {
  white-space: nowrap;
}
.clear {
  clear: both;
}
.m-b-20 {
  margin-bottom:rem-calc(20);
}

#posts-container.loading {
    
    &:before {
        display: block;
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        opacity: 0.7;
        z-index: 10;
    }
    
    &:after {
        display: block;
        content: '';
        position: fixed;
        top: 50%;
        left: 50%;
        background: url('../img/spinner.svg');
        position: 50% 50%;
        width: rem-calc(200);
        height: rem-calc(200);
        background-size: rem-calc(200) rem-calc(200);
        transform: translate(-50%, -50%);
        z-index: 11;
    }
}