@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=90em&xxlarge=100em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.875rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1468a0; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 80rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #0a0a0a;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #ffffff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #ffffff; }
  .button.secondary {
    background-color: #767676;
    color: #ffffff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #ffffff; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #ffffff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #ffffff; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #ffffff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #ffffff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #ffffff; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #ffffff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #ffffff; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #ffffff;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #ffffff;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #ffffff;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #ffffff; }
  .badge.primary {
    background: #1779ba;
    color: #ffffff; }
  .badge.secondary {
    background: #767676;
    color: #ffffff; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #ffffff; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #ffffff; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #ffffff; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #ffffff; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #ffffff; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #ffffff; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #ffffff; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #ffffff;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #1779ba;
    color: #ffffff; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #ffffff;
    box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #ffffff;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #ffffff; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #ffffff; }
  .label.primary {
    background: #1779ba;
    color: #ffffff; }
  .label.secondary {
    background: #767676;
    color: #ffffff; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #ffffff; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(255, 255, 255, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(250px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-250px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(10, 10, 10, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #ffffff; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #ffffff; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #ffffff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #ffffff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 80rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #ffffff;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f2f2f2;
    background-color: #ffffff; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f9f9f9;
    color: #0a0a0a; }
  table tfoot {
    background: #f2f2f2;
    color: #0a0a0a; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #ffffff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #ffffff; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #ffffff; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #ffffff;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #ffffff;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #ffffff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #ffffff; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #ffffff; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 89.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 89.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 90em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

.header {
  background-color: #eaeaea; }
  .header .header-intro {
    padding-top: 2.8125rem;
    text-align: center; }
    @media print, screen and (min-width: 64em) {
      .header .header-intro {
        text-align: left;
        padding-top: 8.4375rem; } }
    .header .header-intro .logo {
      margin-bottom: 0.625rem; }
      .header .header-intro .logo a img {
        height: 2.95rem;
        width: 14.75rem; }
    .header .header-intro h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 2.125rem;
      color: #298dff;
      font-weight: 600;
      line-height: 2.4375rem;
      margin-bottom: 0.9375rem; }
      @media print, screen and (min-width: 64em) {
        .header .header-intro h1 {
          line-height: 3.4375rem;
          font-size: 3.4375rem;
          color: #1b4886;
          margin-bottom: 1.25rem; } }
    .header .header-intro h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      color: #696969;
      font-weight: 600;
      line-height: 1.425rem;
      margin-bottom: 1.25rem; }
      @media print, screen and (min-width: 64em) {
        .header .header-intro h3 {
          font-size: 1.5rem;
          line-height: 1.8rem; } }
    .header .header-intro .header-cta {
      background: #298dff;
      color: #fff;
      width: 14.6875rem;
      display: inline-block;
      height: 3.125rem;
      font-family: 'Source Sans Pro', sans-serif;
      line-height: 3.125rem;
      border-radius: 3.125rem;
      text-align: center;
      font-size: 1.1875rem;
      font-weight: 600;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
      .header .header-intro .header-cta:hover {
        background: #153C6A; }
      @media print, screen and (min-width: 64em) {
        .header .header-intro .header-cta {
          width: 24.45625rem; } }
  .header .phone-bg {
    height: auto;
    position: static;
    margin-bottom: 3.75rem;
    margin-top: 2.8125rem;
    margin-left: 0.9375rem;
    margin-right: 0.9375rem; }
    .header .phone-bg img {
      position: static;
      bottom: 0;
      right: 0; }
      @media print, screen and (min-width: 40em) {
        .header .phone-bg img {
          position: absolute; } }
    @media print, screen and (min-width: 40em) {
      .header .phone-bg {
        position: relative;
        margin: 0;
        height: 25.75rem; } }
    @media print, screen and (min-width: 64em) {
      .header .phone-bg {
        height: 42rem; } }

.footer {
  background: #3c3c3c; }
  @media screen and (max-width: 39.9375em) {
    .footer .columns {
      padding-left: 0;
      padding-right: 0; } }
  .footer .footer-navs {
    padding-top: 3.125rem;
    padding-bottom: 2.5rem; }
    @media print, screen and (min-width: 64em) {
      .footer .footer-navs {
        padding-top: 6.25rem;
        padding-bottom: 5rem; } }
    .footer .footer-navs .footer-menu > ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .footer .footer-navs .footer-menu > ul > li {
        margin: 0;
        padding: 0; }
        .footer .footer-navs .footer-menu > ul > li > a {
          line-height: 1.1875rem;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-weight: 600;
          color: #fff; }
          .footer .footer-navs .footer-menu > ul > li > a:hover {
            color: #ddd; }
    .footer .footer-navs .footer-menu .sub-menu {
      list-style: none;
      margin: 0 0 0.625rem 0;
      padding: 0; }
      .footer .footer-navs .footer-menu .sub-menu > li {
        margin: 0;
        padding: 0;
        margin-bottom: 0.3125rem;
        line-height: 1.125rem; }
        .footer .footer-navs .footer-menu .sub-menu > li > a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-weight: 300;
          color: #fff;
          margin: 0;
          padding: 0; }
          .footer .footer-navs .footer-menu .sub-menu > li > a:hover {
            color: #ddd; }
    .footer .footer-navs .footer-mobile-menu {
      position: relative;
      top: 0.625rem; }
      @media print, screen and (min-width: 40em) {
        .footer .footer-navs .footer-mobile-menu {
          top: 0; } }
      .footer .footer-navs .footer-mobile-menu ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .footer .footer-navs .footer-mobile-menu ul li {
          display: inline-block;
          width: 49%; }
          .footer .footer-navs .footer-mobile-menu ul li a {
            line-height: 1.5625rem;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 1rem;
            font-weight: 600;
            color: #fff; }
            .footer .footer-navs .footer-mobile-menu ul li a:hover {
              color: #eee; }
      .footer .footer-navs .footer-mobile-menu .social-icons {
        position: absolute;
        left: 49%;
        bottom: 0; }
    .footer .footer-navs .contact-info {
      line-height: 1.1875rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1rem;
      font-weight: 300;
      color: #fff;
      margin-bottom: 0; }
    .footer .footer-navs .social-twitter {
      display: inline-block;
      margin-right: 0.375rem; }
      .footer .footer-navs .social-twitter img {
        width: 1.09375rem;
        height: 0.8875rem; }
      .footer .footer-navs .social-twitter:hover {
        opacity: 0.6; }
    .footer .footer-navs .subscription-form h3 {
      line-height: 1.1875rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
      margin-bottom: 0.25rem; }
      @media print, screen and (min-width: 40em) {
        .footer .footer-navs .subscription-form h3 {
          margin-bottom: 0.25rem; } }
    .footer .footer-navs .subscription-form .gform_button:hover {
      background: #e5e5e5; }
  .footer .footer-rights {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 0.875rem;
    font-weight: 300;
    margin-top: 0.9375rem; }
    @media print, screen and (min-width: 40em) {
      .footer .footer-rights {
        margin-top: 0; } }
    .footer .footer-rights .copyrights {
      line-height: 1.0625rem;
      margin-bottom: 0; }
      @media print, screen and (min-width: 40em) {
        .footer .footer-rights .copyrights {
          line-height: 1.4375rem; } }
    .footer .footer-rights .created-by {
      text-align: left;
      font-weight: 600; }
      @media print, screen and (min-width: 40em) {
        .footer .footer-rights .created-by {
          text-align: right;
          font-weight: 300; } }
      .footer .footer-rights .created-by a {
        color: #fff;
        text-decoration: underline; }
        .footer .footer-rights .created-by a:hover {
          color: #ddd; }

.single-case-study .menu-wrapper #top-nav ul .resources-menu-item > a {
  position: relative;
  color: #298dff; }
  .single-case-study .menu-wrapper #top-nav ul .resources-menu-item > a:before {
    display: block;
    position: absolute;
    bottom: 1.0625rem;
    left: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid #298dff;
    content: ''; }
  .single-case-study .menu-wrapper #top-nav ul .resources-menu-item > a:hover:before {
    display: none; }

.single-post .menu-wrapper #top-nav ul .blog-menu-item > a {
  position: relative;
  color: #298dff; }
  .single-post .menu-wrapper #top-nav ul .blog-menu-item > a:before {
    display: block;
    position: absolute;
    bottom: 1.0625rem;
    left: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid #298dff;
    content: ''; }
  .single-post .menu-wrapper #top-nav ul .blog-menu-item > a:hover:before {
    display: none; }

.single-news .menu-wrapper #top-nav ul .about-menu-item > a {
  position: relative;
  color: #298dff; }
  .single-news .menu-wrapper #top-nav ul .about-menu-item > a:before {
    display: block;
    position: absolute;
    bottom: 1.0625rem;
    left: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid #298dff;
    content: ''; }
  .single-news .menu-wrapper #top-nav ul .about-menu-item > a:hover:before {
    display: none; }

.menu-wrapper {
  height: 3.75rem;
  position: relative;
  background: #fff;
  z-index: 9999;
  /* Dropdown */ }
  .menu-wrapper.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
  .menu-wrapper .left-menu {
    float: left;
    width: 70%; }
  .menu-wrapper .left-menu-home {
    float: left;
    width: 68%; }
  .menu-wrapper .right-menu {
    float: right;
    width: 30%;
    text-align: right; }
  .menu-wrapper .right-menu-home {
    float: right;
    width: 32%;
    text-align: right; }
  .menu-wrapper .menu-logo {
    float: left;
    margin-top: 0.9375rem;
    margin-right: 0.9375rem; }
    .menu-wrapper .menu-logo a img {
      width: 9.50625rem;
      height: 1.9rem; }
    .menu-wrapper .menu-logo a:hover {
      opacity: 0.7; }
  .menu-wrapper #top-nav > ul {
    margin: 0;
    padding: 0;
    display: inline-block;
    list-style: none; }
  .menu-wrapper #top-nav > ul > li {
    display: inline-block;
    line-height: 3.75rem;
    padding-right: 0.9375rem; }
    .menu-wrapper #top-nav > ul > li.current_page_item > a {
      color: #298dff; }
  .menu-wrapper #top-nav > ul > li.thick > a {
    color: #1b4886;
    font-weight: 700; }
  .menu-wrapper #top-nav > ul.thin {
    margin-left: 0; }
  .menu-wrapper #top-nav > ul.thin > li > a {
    font-weight: 400; }
  .menu-wrapper #top-nav > ul > li > a {
    display: block;
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: 600; }
  .menu-wrapper #top-nav > ul li:hover a,
  .menu-wrapper #top-nav > ul li.current-menu-parent a {
    color: #298dff; }
  .menu-wrapper #top-nav > ul li:hover .dropdown a,
  .menu-wrapper #top-nav > ul li.current-menu-parent .dropdown a {
    color: #fff; }
  .menu-wrapper #top-nav > ul li:hover .dropdown a:hover,
  .menu-wrapper #top-nav > ul li.current-menu-parent .dropdown a:hover {
    color: #ebebeb; }
  .menu-wrapper #top-nav > ul > li.current-menu-parent > a,
  .menu-wrapper #top-nav > ul > li.current-menu-item > a {
    position: relative;
    color: #298dff; }
    .menu-wrapper #top-nav > ul > li.current-menu-parent > a:before,
    .menu-wrapper #top-nav > ul > li.current-menu-item > a:before {
      display: block;
      position: absolute;
      bottom: 1.0625rem;
      left: 0;
      right: 0;
      height: 1px;
      border-bottom: 1px solid #298dff;
      content: ''; }
    .menu-wrapper #top-nav > ul > li.current-menu-parent > a:hover:before,
    .menu-wrapper #top-nav > ul > li.current-menu-item > a:hover:before {
      display: none; }
  .menu-wrapper #top-nav > ul > li.current-menu-parent:hover > a:before,
  .menu-wrapper #top-nav > ul > li.current-menu-item:hover > a:before {
    display: none; }
  .menu-wrapper li.menu-item-has-children.opened > a:after {
    opacity: 1; }
  .menu-wrapper li.menu-item-has-children:hover ul.dropdown {
    opacity: 1;
    display: block; }
  .menu-wrapper li.menu-item-has-children:hover > a:after {
    opacity: 1; }
  .menu-wrapper li.menu-item-has-children > a {
    position: relative; }
    .menu-wrapper li.menu-item-has-children > a:after {
      bottom: -0.0625rem;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      opacity: 0; }
    .menu-wrapper li.menu-item-has-children > a:after {
      border-color: rgba(41, 141, 255, 0);
      border-bottom-color: #298dff;
      border-width: 0.9375rem;
      margin-left: -0.9375rem; }
  .menu-wrapper li.menu-item-has-children ul.dropdown {
    opacity: 0;
    display: none;
    position: absolute;
    background: #298dff;
    top: 3.75rem;
    padding: 1.875rem;
    z-index: 999;
    margin: 0;
    transform: translate(-50%, 0);
    left: 50%;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    width: 15.625rem;
    list-style: none; }
    .menu-wrapper li.menu-item-has-children ul.dropdown li a {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      display: block;
      line-height: 1;
      margin-bottom: 0.5rem; }
    .menu-wrapper li.menu-item-has-children ul.dropdown li:last-child a {
      margin-bottom: 0; }
  .menu-wrapper #header-nav-1 {
    position: relative; }
    .menu-wrapper #header-nav-1 li.menu-item-has-children {
      position: relative; }
  .menu-wrapper .search-btn {
    display: inline-block;
    width: 1.175rem;
    height: 1.16875rem;
    margin-left: 0.625rem;
    position: relative; }
    .menu-wrapper .search-btn:after, .menu-wrapper .search-btn:before {
      visibility: hidden;
      bottom: -1.5rem;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .menu-wrapper .search-btn:after {
      border-color: rgba(41, 141, 255, 0);
      border-bottom-color: #298dff;
      border-width: 0.9375rem;
      margin-left: -0.9375rem; }
    .menu-wrapper .search-btn:before {
      border-color: rgba(41, 141, 255, 0);
      border-bottom-color: #298dff;
      border-width: 1.25rem;
      margin-left: -1.25rem; }
    .menu-wrapper .search-btn.opened:after, .menu-wrapper .search-btn.opened:before {
      visibility: visible; }
    .menu-wrapper .search-btn:hover img {
      opacity: 0.8; }
  .menu-wrapper .menu-cta {
    display: inline-block;
    width: 8.125rem;
    height: 1.875rem;
    background: #298dff;
    color: #fff;
    line-height: 1.875rem;
    border-radius: 3.125rem;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-left: 1.25rem; }
    .menu-wrapper .menu-cta:hover {
      background: #153C6A; }

.search-form {
  background: #298dff;
  position: absolute;
  top: 3.75rem;
  left: 0;
  right: 0;
  height: 5rem;
  display: none;
  z-index: 999;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
  .search-form form {
    padding: 1.25rem 9.375rem 1.25rem 0; }
    .search-form form .search-input {
      width: 100%;
      display: inline;
      float: left;
      border: none; }
      .search-form form .search-input:focus {
        border: none;
        box-shadow: none; }
    .search-form form .search-submit {
      background: #1b4987;
      width: 9.375rem;
      position: absolute;
      text-align: center;
      display: inline;
      height: 2.5rem;
      line-height: 2.5rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 600;
      color: #fff; }
      .search-form form .search-submit img {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.375rem; }
      .search-form form .search-submit:hover {
        opacity: 0.8;
        cursor: hand; }

.mobile-header {
  height: 4.6875rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 0 1.875rem; }
  .mobile-header .mobile-logo {
    line-height: 4.6875rem;
    display: inline; }
    .mobile-header .mobile-logo a img {
      width: 9.70625rem; }
  .mobile-header .ham-button {
    float: right; }
    .mobile-header .ham-button .hamburger {
      width: 1.875rem;
      height: 1.75rem;
      position: relative;
      margin-top: 0.9375rem; }
      @media print, screen and (min-width: 40em) {
        .mobile-header .ham-button .hamburger {
          margin-top: 1.25rem; } }
      @media print, screen and (min-width: 64em) {
        .mobile-header .ham-button .hamburger {
          margin-top: 1.5625rem; } }
      .mobile-header .ham-button .hamburger span {
        border-top: 0.125rem solid #298dff;
        display: block;
        opacity: 1;
        position: absolute;
        width: 100%;
        top: 0;
        -webkit-transition: all .6s ease;
        -moz-transition: all .6s ease;
        -o-transition: all .6s ease;
        transition: all .6s ease; }
        .mobile-header .ham-button .hamburger span:first-of-type {
          top: 12px; }
        .mobile-header .ham-button .hamburger span:last-of-type {
          top: 24px; }
        .mobile-header .ham-button .hamburger span:after {
          display: none; }
    @media print, screen and (min-width: 40em) {
      .expanded .mobile-header .ham-button .hamburger {
        margin-top: 1.25rem; } }
    .expanded .mobile-header .ham-button .hamburger span:first-of-type {
      -ms-transform: rotate(45deg);
      /* IE 9 */
      -webkit-transform: rotate(45deg);
      /* Chrome, Safari, Opera */
      transform: rotate(45deg);
      top: 1.25rem; }
    .expanded .mobile-header .ham-button .hamburger span:last-of-type {
      -ms-transform: rotate(-45deg);
      /* IE 9 */
      -webkit-transform: rotate(-45deg);
      /* Chrome, Safari, Opera */
      transform: rotate(-45deg);
      top: 1.25rem; }

.mobile-nav {
  position: fixed;
  z-index: 9999;
  top: 4.6875rem;
  right: 0;
  background: #fff;
  overflow-x: hidden;
  width: 100%;
  margin-right: -100%; }
  .expanded .mobile-nav {
    margin-right: 0;
    position: absolute; }
  .mobile-nav .mobile-search {
    background: #298dff;
    padding: 1.5625rem 1.875rem; }
    .mobile-nav .mobile-search form {
      padding: 0 3.75rem 0 0;
      margin: 0;
      overflow: hidden; }
      .mobile-nav .mobile-search form .search-input {
        width: 100%;
        display: inline;
        float: left;
        border: none;
        height: 3.125rem;
        margin: 0; }
        .mobile-nav .mobile-search form .search-input:focus {
          border: none;
          box-shadow: none; }
      .mobile-nav .mobile-search form .search-submit {
        background: #1b4987;
        width: 3.75rem;
        position: absolute;
        text-align: center;
        display: inline;
        height: 3.125rem;
        line-height: 3.125rem; }
        .mobile-nav .mobile-search form .search-submit img {
          width: 1.25rem;
          height: 1.25rem; }
  .mobile-nav .mobile-menu > ul > li {
    background: #1b4886; }
    .mobile-nav .mobile-menu > ul > li > a {
      display: block;
      margin-left: 1.875rem;
      margin-right: 1.875rem;
      padding: 0.9375rem 0;
      line-height: 1.625rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      color: #fff;
      border-bottom: 0.125rem solid #153c6a; }
    .mobile-nav .mobile-menu > ul > li[aria-expanded="true"] {
      background: #fff; }
      .mobile-nav .mobile-menu > ul > li[aria-expanded="true"] > a {
        color: #1b4886;
        border-bottom: 0.125rem solid #f5f5f5; }
    .mobile-nav .mobile-menu > ul > li.menu-item-has-children > a {
      position: relative; }
      .mobile-nav .mobile-menu > ul > li.menu-item-has-children > a .arrow {
        background-image: url("../img/arrow-up-w.svg");
        background-size: 1.25rem 0.75rem;
        background-repeat: no-repeat;
        background-position: center center;
        border: none;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        right: -0.625rem;
        top: 0.6875rem; }
      .mobile-nav .mobile-menu > ul > li.menu-item-has-children > a:after {
        content: '';
        display: none; }
    .mobile-nav .mobile-menu > ul > li.menu-item-has-children[aria-expanded="true"] > a .arrow {
      background-image: url("../img/arrow-down-b.svg");
      transform: none; }
    .mobile-nav .mobile-menu > ul > li.menu-item-has-children[aria-expanded="true"] > a:after {
      content: '';
      display: none; }
    .mobile-nav .mobile-menu > ul > li .is-accordion-submenu {
      background: #fff;
      margin: 0;
      padding: 0; }
      .mobile-nav .mobile-menu > ul > li .is-accordion-submenu li a {
        margin-left: 1.875rem;
        margin-right: 1.875rem;
        line-height: 1.625rem;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.5rem;
        font-weight: 400;
        color: #696969;
        padding: 0.9375rem 0;
        border-bottom: 0.125rem solid #f5f5f5; }
  .mobile-nav .cta-button {
    background: #fff;
    padding: 1.5625rem 1.875rem; }
    .mobile-nav .cta-button a {
      background: #298dff;
      color: #fff;
      display: block;
      height: 3.125rem;
      font-family: 'Source Sans Pro', sans-serif;
      line-height: 3.125rem;
      border-radius: 3.125rem;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
      .mobile-nav .cta-button a:hover {
        background: #153C6A; }

body:not(.home) {
  background: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  padding-top: 4.6875rem; }
  @media print, screen and (min-width: 64em) {
    body:not(.home) {
      padding-top: 3.75rem; } }

body.home {
  padding-top: 4.6875rem; }
  @media print, screen and (min-width: 64em) {
    body.home {
      padding-top: 0; } }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Source Sans Pro', sans-serif; }

.row {
  margin-right: 1.875rem;
  margin-left: 1.875rem; }
  @media print, screen and (min-width: 64em) {
    .row {
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      margin-right: auto;
      margin-left: auto; } }
  @media screen and (max-width: 39.9375em) {
    .row .columns {
      padding-left: 0;
      padding-right: 0; } }

.columns:last-child:not(:first-child) {
  float: left; }

.main-page {
  background: #fff; }

.breadcrumbs {
  margin-bottom: 0.625rem; }
  .breadcrumbs li {
    line-height: 1.1875rem;
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: none; }
    .breadcrumbs li a {
      color: #696969; }
    .breadcrumbs li:not(:last-child):after {
      content: '>';
      margin-left: 0.3125rem;
      margin-right: 0.3125rem; }

.pagination {
  margin-top: 1.875rem; }
  .pagination .page-numbers {
    display: inline-block; }

.nowrap {
  white-space: nowrap; }

.clear {
  clear: both; }

.m-b-20 {
  margin-bottom: 1.25rem; }

#posts-container.loading:before {
  display: block;
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  opacity: 0.7;
  z-index: 10; }

#posts-container.loading:after {
  display: block;
  content: '';
  position: fixed;
  top: 50%;
  left: 50%;
  background: url("../img/spinner.svg");
  position: 50% 50%;
  width: 12.5rem;
  height: 12.5rem;
  background-size: 12.5rem 12.5rem;
  transform: translate(-50%, -50%);
  z-index: 11; }

.solutions-in-action .main-title {
  background: #153c6a;
  min-height: 5.625rem;
  text-align: center;
  position: relative; }
  .solutions-in-action .main-title:after, .solutions-in-action .main-title:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .solutions-in-action .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #153c6a;
    border-width: 0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 64em) {
      .solutions-in-action .main-title:after {
        border-width: 1.875rem;
        margin-left: -1.875rem; } }
  .solutions-in-action .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #153c6a;
    border-width: 1.125rem;
    margin-left: -1.125rem; }
    @media print, screen and (min-width: 64em) {
      .solutions-in-action .main-title:before {
        border-width: 2.25rem;
        margin-left: -2.25rem; } }
  .solutions-in-action .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    padding: 1.25rem 0 0.625rem 0; }
    @media print, screen and (min-width: 40em) {
      .solutions-in-action .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.solutions-in-action .video-blocks {
  background: #1b4886;
  padding-top: 1.5625rem;
  padding-bottom: 0.3125rem; }
  @media print, screen and (min-width: 40em) {
    .solutions-in-action .video-blocks {
      padding-top: 1.875rem;
      padding-bottom: 1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .solutions-in-action .video-blocks {
      padding-top: 3.75rem; } }
  .solutions-in-action .video-blocks .video-details {
    margin-bottom: 1.875rem; }
    .solutions-in-action .video-blocks .video-details h3 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.875rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 2.1875rem;
      margin-top: 0.3125rem;
      margin-bottom: 1.25rem; }
      @media print, screen and (min-width: 40em) {
        .solutions-in-action .video-blocks .video-details h3 {
          margin-top: 1.25rem; } }
      @media print, screen and (min-width: 64em) {
        .solutions-in-action .video-blocks .video-details h3 {
          margin-top: 0.3125rem; } }
    .solutions-in-action .video-blocks .video-details p {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem; }
    .solutions-in-action .video-blocks .video-details a.video-block-cta {
      margin-top: 1.5625rem;
      background: #298dff;
      color: #fff;
      display: block;
      height: 3.125rem;
      font-family: 'Source Sans Pro', sans-serif;
      line-height: 3.125rem;
      border-radius: 3.125rem;
      text-align: center;
      font-size: 1.1875rem;
      font-weight: 600;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
      .solutions-in-action .video-blocks .video-details a.video-block-cta:hover {
        background: #153C6A; }
    @media print, screen and (min-width: 64em) {
      .solutions-in-action .video-blocks .video-details {
        margin-bottom: 0; } }
  .solutions-in-action .video-blocks .responsive-embed {
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-bottom: 0; }
    @media print, screen and (min-width: 40em) {
      .solutions-in-action .video-blocks .responsive-embed {
        margin-bottom: 0; } }
  .solutions-in-action .video-blocks .bordered-top {
    border-top: 0.125rem solid #153c6a;
    margin: 3.75rem 0.9375rem 0 0.9375rem;
    padding-top: 1.25rem;
    display: none; }
    @media print, screen and (min-width: 40em) {
      .solutions-in-action .video-blocks .bordered-top {
        display: block; } }
  .solutions-in-action .video-blocks .bordered-bottom {
    border-bottom: 0.125rem solid #153c6a;
    margin: 0 0.9375rem 3.75rem 0.9375rem;
    padding-bottom: 0.9375rem;
    display: none; }
    @media print, screen and (min-width: 40em) {
      .solutions-in-action .video-blocks .bordered-bottom {
        display: block; } }
  .solutions-in-action .video-blocks .quote {
    text-align: left; }
    .solutions-in-action .video-blocks .quote h2 {
      font-family: 'Source Sans Pro', sans-serif;
      line-height: 3.125rem;
      color: #fff;
      font-size: 2.8125rem;
      font-weight: 300;
      margin: 0; }
      .solutions-in-action .video-blocks .quote h2 strong {
        font-weight: 600; }
      .solutions-in-action .video-blocks .quote h2 span {
        font-size: 1rem;
        font-weight: 600;
        font-style: italic; }

.leaders-trust .main-title {
  background: #0d7eff;
  min-height: 5.625rem;
  text-align: center;
  position: relative; }
  .leaders-trust .main-title:after, .leaders-trust .main-title:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .leaders-trust .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 64em) {
      .leaders-trust .main-title:after {
        border-width: 1.875rem;
        margin-left: -1.875rem; } }
  .leaders-trust .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 1.125rem;
    margin-left: -1.125rem; }
    @media print, screen and (min-width: 64em) {
      .leaders-trust .main-title:before {
        border-width: 2.25rem;
        margin-left: -2.25rem; } }
  .leaders-trust .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    padding: 1.25rem 0 0.625rem 0; }
    @media print, screen and (min-width: 64em) {
      .leaders-trust .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.leaders-trust .logos-quotes {
  background: #298dff;
  padding-top: 1.875rem; }
  @media print, screen and (min-width: 40em) {
    .leaders-trust .logos-quotes {
      padding-top: 3.75rem; } }
  .leaders-trust .logos-quotes .logos {
    margin-bottom: 0;
    padding-bottom: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .leaders-trust .logos-quotes .logos {
        margin-bottom: 1.875rem;
        padding-bottom: 0; } }
  .leaders-trust .logos-quotes .quotes .quote-border {
    border-top: 0.125rem solid #0d7eff; }
  .leaders-trust .logos-quotes .quotes .quote {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem; }
    @media print, screen and (min-width: 40em) {
      .leaders-trust .logos-quotes .quotes .quote {
        padding-top: 1.5625rem;
        padding-bottom: 3.4375rem; } }
    .leaders-trust .logos-quotes .quotes .quote .quote-author {
      margin-bottom: 1.25rem; }
      @media print, screen and (min-width: 40em) {
        .leaders-trust .logos-quotes .quotes .quote .quote-author {
          margin-bottom: 0; } }
      .leaders-trust .logos-quotes .quotes .quote .quote-author h5 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        line-height: 1.4375rem;
        color: #fff;
        margin: 0; }
      .leaders-trust .logos-quotes .quotes .quote .quote-author span {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        line-height: 1.4375rem;
        color: #fff;
        font-style: italic; }
    .leaders-trust .logos-quotes .quotes .quote .clear {
      clear: both; }
    .leaders-trust .logos-quotes .quotes .quote .quote-text {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      color: #fff;
      position: relative; }
      @media print, screen and (min-width: 40em) {
        .leaders-trust .logos-quotes .quotes .quote .quote-text {
          margin-top: 0;
          font-size: 1.5rem;
          line-height: 1.875rem; } }
      .leaders-trust .logos-quotes .quotes .quote .quote-text:before {
        content: '“ ';
        position: absolute;
        left: -0.875rem; }
      .leaders-trust .logos-quotes .quotes .quote .quote-text:after {
        content: '”'; }

.customized-solutions .main-title {
  background: #ebebeb;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .customized-solutions .main-title {
      min-height: 5.625rem; } }
  .customized-solutions .main-title:after, .customized-solutions .main-title:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .customized-solutions .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 64em) {
      .customized-solutions .main-title:after {
        border-width: 1.875rem;
        margin-left: -1.875rem; } }
  .customized-solutions .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 1.125rem;
    margin-left: -1.125rem; }
    @media print, screen and (min-width: 64em) {
      .customized-solutions .main-title:before {
        border-width: 2.25rem;
        margin-left: -2.25rem; } }
  .customized-solutions .main-title h5 {
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    padding: 1.25rem 0 1.25rem 0; }
    @media print, screen and (min-width: 64em) {
      .customized-solutions .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.customized-solutions .features {
  background: #f5f5f5;
  padding-bottom: 3.75rem;
  padding-top: 1.5625rem;
  text-align: center; }
  @media print, screen and (min-width: 64em) {
    .customized-solutions .features {
      padding-top: 3.75rem; } }
  .customized-solutions .features > .row {
    padding-left: 0;
    padding-right: 0; }
    .customized-solutions .features > .row .row {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; }
  .customized-solutions .features h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 2.8125rem;
    margin-bottom: 1.25rem;
    margin-top: 1.5625rem; }
    @media print, screen and (min-width: 64em) {
      .customized-solutions .features h3 {
        line-height: 3.125rem;
        font-size: 2.8125rem; } }
  .customized-solutions .features .feature {
    background: #fff;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-bottom: 1.875rem;
    padding: 1.5625rem 1.5625rem;
    text-align: left;
    display: table;
    width: 100%; }
    @media print, screen and (min-width: 40em) {
      .customized-solutions .features .feature {
        display: block;
        text-align: center;
        padding: 0 0 1.875rem 0; } }
    .customized-solutions .features .feature .feature-icon {
      padding: 0 0 0 0;
      display: table-cell;
      vertical-align: middle;
      width: 3.875rem; }
      @media print, screen and (min-width: 40em) {
        .customized-solutions .features .feature .feature-icon {
          padding: 1.875rem 0 0.9375rem 0;
          display: block;
          width: 100%; } }
      .customized-solutions .features .feature .feature-icon img {
        height: 2.75rem; }
        @media print, screen and (min-width: 40em) {
          .customized-solutions .features .feature .feature-icon img {
            height: 3.375rem; } }
    .customized-solutions .features .feature .feature-title {
      height: auto;
      display: table-cell;
      text-align: left;
      vertical-align: middle;
      margin-bottom: 0;
      padding-left: 0.625rem; }
      @media print, screen and (min-width: 40em) {
        .customized-solutions .features .feature .feature-title {
          width: 100%;
          height: 3.4375rem;
          text-align: center;
          display: table;
          margin-bottom: 0.9375rem;
          padding-left: 0; } }
      .customized-solutions .features .feature .feature-title p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 700;
        line-height: 1.4375rem;
        display: inline-block;
        vertical-align: middle;
        text-align: left;
        margin-bottom: 0; }
        @media print, screen and (min-width: 40em) {
          .customized-solutions .features .feature .feature-title p {
            display: table-cell;
            vertical-align: middle;
            text-align: center; } }
        .customized-solutions .features .feature .feature-title p a {
          color: #696969;
          text-decoration: underline; }
          @media print, screen and (min-width: 40em) {
            .customized-solutions .features .feature .feature-title p a {
              text-decoration: none; } }
          .customized-solutions .features .feature .feature-title p a:hover {
            color: #999; }
    .customized-solutions .features .feature .feature-cta {
      padding: 0;
      display: table-cell;
      text-align: right;
      vertical-align: middle; }
      @media print, screen and (min-width: 40em) {
        .customized-solutions .features .feature .feature-cta {
          float: none;
          display: block;
          padding: 0.625rem 1.25rem 0 1.25rem; } }
      .customized-solutions .features .feature .feature-cta a {
        height: 3.125rem;
        line-height: 3.125rem;
        border-radius: 3.125rem;
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        text-align: center;
        font-weight: 600;
        display: inline-block;
        width: 6.5rem; }
        @media print, screen and (min-width: 40em) {
          .customized-solutions .features .feature .feature-cta a {
            display: block;
            width: 100%; } }
  .customized-solutions .features .cta-button {
    width: 100%;
    height: 3.125rem;
    line-height: 3.125rem;
    color: #fff;
    border-radius: 3.125rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
    @media print, screen and (min-width: 40em) {
      .customized-solutions .features .cta-button {
        width: 24.25rem; } }
  .customized-solutions .features .cta-text {
    line-height: 2.8125rem;
    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
    @media print, screen and (min-width: 40em) {
      .customized-solutions .features .cta-text {
        line-height: 3.75rem;
        font-size: 3.125rem; } }

.featured-widgets {
  background: #fff;
  padding-top: 0;
  padding-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .featured-widgets {
      padding-top: 3.75rem;
      padding-bottom: 1.875rem; } }
  @media print, screen and (min-width: 64em) {
    .featured-widgets {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  @media screen and (max-width: 39.9375em) {
    .featured-widgets .row {
      padding: 0;
      margin: 0; } }
  @media screen and (max-width: 39.9375em) {
    .featured-widgets .row .large-4 {
      padding: 0;
      margin: 0; } }
  .featured-widgets .widget {
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-bottom: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .featured-widgets .widget {
        margin-bottom: 0; } }
    .featured-widgets .widget .widget-image {
      height: auto;
      overflow: hidden;
      float: none;
      width: auto;
      margin-right: 0; }
      @media print, screen and (min-width: 40em) {
        .featured-widgets .widget .widget-image {
          height: 15.3125rem;
          width: 25rem;
          float: left;
          margin-right: 1.25rem; } }
      @media print, screen and (min-width: 64em) {
        .featured-widgets .widget .widget-image {
          height: 12.5rem;
          float: none;
          width: auto;
          margin-right: 0; } }
      .featured-widgets .widget .widget-image img {
        min-height: 100%;
        width: 100%; }
        @media print, screen and (min-width: 40em) {
          .featured-widgets .widget .widget-image img {
            height: 15.3125rem;
            width: 100%; } }
        @media print, screen and (min-width: 64em) {
          .featured-widgets .widget .widget-image img {
            height: 100%;
            max-width: 100%; } }
    .featured-widgets .widget .widget-contents .widget-details {
      padding: 1.875rem 1.875rem 1.25rem 1.875rem;
      height: auto; }
      @media print, screen and (min-width: 40em) {
        .featured-widgets .widget .widget-contents .widget-details {
          height: 12.1875rem;
          padding: 1.875rem 0.625rem; } }
      @media print, screen and (min-width: 64em) {
        .featured-widgets .widget .widget-contents .widget-details {
          height: 12.1875rem;
          padding: 1.875rem; } }
      .featured-widgets .widget .widget-contents .widget-details h5 {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.25rem;
        color: #fff;
        margin: 0 0 0.3125rem 0; }
      .featured-widgets .widget .widget-contents .widget-details h2 {
        font-size: 1.5rem;
        font-weight: 700;
        color: #fff;
        line-height: 1.625rem; }
      .featured-widgets .widget .widget-contents .widget-details span {
        font-size: 1rem;
        font-weight: 600;
        font-style: italic;
        color: #fff;
        line-height: 1.2rem; }
    .featured-widgets .widget .widget-contents .widget-footer {
      height: 4.375rem;
      line-height: 4.375rem;
      text-align: right;
      font-size: 1.1875rem;
      font-weight: 700;
      margin-right: 1.875rem;
      margin-left: 1.875rem;
      border-top: 0.125rem solid rgba(0, 0, 0, 0.1); }
      @media print, screen and (min-width: 40em) {
        .featured-widgets .widget .widget-contents .widget-footer {
          height: 3.125rem;
          line-height: 2.5rem;
          margin-left: 26.25rem; } }
      @media print, screen and (min-width: 64em) {
        .featured-widgets .widget .widget-contents .widget-footer {
          height: 4.375rem;
          line-height: 4.375rem;
          margin-left: 1.875rem; } }
      .featured-widgets .widget .widget-contents .widget-footer a {
        color: #fff; }
        .featured-widgets .widget .widget-contents .widget-footer a img {
          width: 1.6875rem;
          height: 1.1625rem;
          margin-left: 0.375rem; }

.bg-green {
  background: #6a8e2c; }
  .bg-green:hover {
    background: #1f6e08; }

.bg-orange {
  background: #c86b1f; }
  .bg-orange:hover {
    background: #d14704; }

.bg-navy {
  background: #547b96; }
  .bg-navy:hover {
    background: #1c64a1; }

.bg-blue {
  background: #1b4886; }
  .bg-blue:hover {
    background: #0a3a74; }

.bg-gray {
  background: #696969; }
  .bg-gray:hover {
    background: #444; }

.text-green {
  color: #6a8e2c; }

.text-orange {
  color: #c86b1f; }

.text-navy {
  color: #547b96; }

.text-blue {
  color: #1b4886; }

.text-gray {
  color: #696969; }

body {
  overflow-x: hidden; }
  body .subscription-form .gform_confirmation_wrapper .gform_confirmation_message {
    border: none;
    color: #fff;
    text-align: left;
    padding: 0.625rem 0;
    margin-top: 0.625rem;
    font-weight: 700; }
  body .subscription-form .gform_wrapper ul.gform_fields li.gfield.gfield_error + li.gfield.gfield_error {
    margin-top: 0; }
  body .subscription-form .gform_wrapper {
    margin: 0; }
    body .subscription-form .gform_wrapper .validation_error {
      border: none;
      color: #FF3422;
      text-align: left;
      padding: 0;
      margin: 0 0 0.5rem 0; }
    body .subscription-form .gform_wrapper form {
      position: relative;
      z-index: 99; }
      body .subscription-form .gform_wrapper form label:empty {
        display: none; }
      body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield {
        margin: 0;
        padding: 0; }
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox {
          margin: 0; }
          body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox {
            margin: 0 0 0.625rem;
            width: 100%; }
            body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox:after {
              display: block;
              content: '';
              clear: both; }
            body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li {
              display: block;
              margin: 0 0.9375rem 0 0;
              float: left;
              /* checkbox aspect */
              /* checked mark aspect */
              /* checked mark aspect changes */
              /* disabled checkbox */
              /* accessibility */ }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li label {
                font-family: 'Source Sans Pro', sans-serif;
                font-size: 1rem;
                font-weight: 300;
                color: #fff;
                max-width: 100%;
                width: 100%; }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:not(:checked),
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:checked {
                position: absolute;
                left: -9999px; }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:not(:checked) + label,
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:checked + label {
                position: relative;
                padding-left: 1.5625rem;
                cursor: pointer; }
                @media print, screen and (min-width: 40em) {
                  body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:not(:checked) + label,
                  body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:checked + label {
                    padding-left: 2.1875rem; } }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:not(:checked) + label:before,
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:checked + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.125rem;
                width: 1.25rem;
                height: 1.25rem;
                border: 1px solid #fff;
                background: #3c3c3c; }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:not(:checked) + label:after,
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:checked + label:after {
                content: '×';
                position: absolute;
                top: -0.8125rem;
                left: 3px;
                font-size: 1.75rem;
                color: #fff;
                line-height: 1.75;
                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                -ms-transition: all .2s;
                transition: all .2s; }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                -ms-transform: scale(0);
                transform: scale(0); }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1); }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:disabled:not(:checked) + label:before,
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:disabled:checked + label:before {
                box-shadow: none;
                border-color: #bbb;
                background-color: #ddd; }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:disabled:checked + label:after {
                color: #999; }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:disabled + label {
                color: #aaa; }
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:checked:focus + label:before,
              body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:not(:checked):focus + label:before {
                border: 1px solid #fff; }
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_text {
          margin: 0; }
          body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_text input {
            background: none;
            height: 2.5rem;
            border-color: #fff;
            box-shadow: none;
            color: #fff;
            margin: 0; }
            body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_text input:focus {
              box-shadow: none; }
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_email {
          margin: 0; }
          body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_email input {
            background: none;
            height: 2.5rem;
            border-color: #fff;
            box-shadow: none;
            color: #fff;
            margin: 0; }
            body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_email input:focus {
              box-shadow: none; }
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .gfield_required {
          display: none; }
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield .gfield_label {
          display: none; }
      body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield_error {
        background: none;
        border: none;
        margin-bottom: 0 !important;
        margin-top: 0; }
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield_error .ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
          border-color: red; }
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield_error .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:not(:checked) + label:before,
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield_error .ginput_container_checkbox .gfield_checkbox li [type="checkbox"]:checked + label:before {
          border: 1px solid red; }
        body .subscription-form .gform_wrapper form .gform_body .gform_fields .gfield_error .validation_message {
          display: none; }
      body .subscription-form .gform_wrapper form .gform_footer {
        position: relative;
        margin: 0;
        padding: 0;
        height: 0; }
        body .subscription-form .gform_wrapper form .gform_footer input.button {
          position: absolute;
          padding: 0;
          margin: 0;
          right: 0;
          bottom: 0;
          width: 6.5625rem;
          height: 2.5rem;
          display: inline;
          background: #fff;
          text-align: center;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-weight: 600;
          color: #3c3c3c; }
  body .movr-subscription-form {
    position: relative; }
    body .movr-subscription-form .gform_confirmation_message {
      font-size: 1.1875rem;
      max-width: 62.5rem;
      color: #fff;
      margin: 0 auto;
      line-height: 1.4375rem;
      font-family: 'Source Sans Pro', sans-serif; }
    body .movr-subscription-form .gform_anchor {
      display: block;
      position: absolute;
      top: -5rem; }
    body .movr-subscription-form .gform_wrapper,
    body .movr-subscription-form .gform_wrapper.gform_validation_error {
      margin: 0; }
      body .movr-subscription-form .gform_wrapper form,
      body .movr-subscription-form .gform_wrapper.gform_validation_error form {
        max-width: 62.5rem; }
        body .movr-subscription-form .gform_wrapper form .validation_error,
        body .movr-subscription-form .gform_wrapper.gform_validation_error form .validation_error {
          color: #8E0005;
          border: none;
          font-size: 1.1875rem;
          margin: 0;
          padding-top: 0;
          line-height: 1.4375rem;
          font-family: 'Source Sans Pro', sans-serif; }
        body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields,
        body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields {
          width: 100%; }
          body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield,
          body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error,
          body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield,
          body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error {
            max-width: 100% !important;
            position: relative;
            background: none;
            border: none;
            margin: 0 !important;
            padding: 0;
            width: 100%; }
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield:after,
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error:after,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield:after,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error:after {
              display: block;
              content: '';
              clear: both; }
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield .gfield_label,
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield .ginput_container,
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error .gfield_label,
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error .ginput_container,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield .gfield_label,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield .ginput_container,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error .gfield_label,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error .ginput_container {
              display: block;
              vertical-align: top; }
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield .gfield_label,
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error .gfield_label,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield .gfield_label,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error .gfield_label {
              color: #fff;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 1.125rem;
              font-weight: 700;
              line-height: 1.5rem;
              margin-bottom: 0.9375rem; }
              @media print, screen and (min-width: 64em) {
                body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield .gfield_label,
                body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error .gfield_label,
                body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield .gfield_label,
                body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error .gfield_label {
                  float: left;
                  width: 42%;
                  line-height: 3.125rem;
                  font-size: 1.5rem;
                  margin: 0; } }
              body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield .gfield_label .gfield_required,
              body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error .gfield_label .gfield_required,
              body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield .gfield_label .gfield_required,
              body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error .gfield_label .gfield_required {
                display: none; }
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield div.ginput_container,
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield div.ginput_container,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container {
              padding-right: 7.4375rem;
              width: 100%;
              margin: 0; }
              @media print, screen and (min-width: 64em) {
                body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield div.ginput_container,
                body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container,
                body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield div.ginput_container,
                body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container {
                  padding-right: 11.1875rem;
                  float: right;
                  width: 58%; } }
              body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
              body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
              body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
              body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
                background: #fff;
                line-height: 3rem;
                height: 3.125rem;
                width: 100%;
                border-color: #fff;
                box-shadow: none;
                color: #696969;
                margin: 0;
                font-weight: 600;
                font-size: 1.1875rem;
                padding: 0 2.0625rem;
                width: 100%; }
                body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):focus,
                body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):focus,
                body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):focus,
                body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):focus {
                  box-shadow: none; }
                body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])::placeholder,
                body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])::placeholder,
                body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])::placeholder,
                body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"])::placeholder {
                  /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: #298dff;
                  opacity: 1;
                  /* Firefox */
                  font-weight: 600;
                  font-size: 1.1875rem; }
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error.gfield_error div.ginput_container input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
              border-color: #8E0005; }
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield .validation_message,
            body .movr-subscription-form .gform_wrapper form .gform_body ul.gform_fields li.gfield.gfield_error .validation_message,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield .validation_message,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_body ul.gform_fields li.gfield.gfield_error .validation_message {
              position: absolute;
              top: 3.125rem;
              width: 100%;
              text-align: center;
              top: 3.125rem;
              right: 0;
              left: 0;
              padding-right: 0;
              color: #8E0005;
              font-size: 1.1875rem;
              font-weight: normal;
              display: none;
              line-height: 1.4375rem;
              font-family: 'Source Sans Pro', sans-serif; }
        body .movr-subscription-form .gform_wrapper form .gform_footer,
        body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_footer {
          position: relative;
          margin: 0;
          padding: 0; }
          body .movr-subscription-form .gform_wrapper form .gform_footer input.button,
          body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_footer input.button {
            position: absolute;
            padding: 0;
            right: 0;
            top: -3.125rem;
            width: 7.4375rem;
            height: 3.125rem;
            line-height: 1;
            display: inline;
            background: #1b4886;
            text-align: center;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 1.1875rem;
            font-weight: 600;
            color: #fff;
            margin: 0; }
            @media print, screen and (min-width: 64em) {
              body .movr-subscription-form .gform_wrapper form .gform_footer input.button,
              body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_footer input.button {
                line-height: 3.125rem;
                width: 11.1875rem; } }
            body .movr-subscription-form .gform_wrapper form .gform_footer input.button:hover,
            body .movr-subscription-form .gform_wrapper.gform_validation_error form .gform_footer input.button:hover {
              background: #2a5996; }

.product-title-wrapper {
  padding: 1.875rem 0 0.625rem 0; }
  .product-title-wrapper .product-title h1 {
    line-height: 2.8125rem;
    font-size: 2.5rem;
    font-weight: 400;
    color: #1b4886; }
    @media print, screen and (min-width: 64em) {
      .product-title-wrapper .product-title h1 {
        line-height: 3.75rem;
        font-size: 3.75rem; } }
  .product-title-wrapper .product-title .signup-intro-text {
    line-height: 1.4375rem;
    font-size: 1.1875rem;
    font-weight: 600;
    color: #1b4886;
    margin: 0;
    margin-top: 1.875rem;
    margin-bottom: 3.75rem; }

.products-filters {
  padding: 3.75rem 0;
  background: #f5f5f5; }
  .products-filters .filters-form {
    background: #ebebeb;
    padding: 1.875rem;
    margin-bottom: 1.5625rem; }
    .products-filters .filters-form h3 {
      text-align: center;
      margin-top: 0;
      margin-bottom: 1.875rem;
      line-height: 1.625rem;
      font-size: 1.5rem;
      font-weight: 700;
      color: #696969; }
    .products-filters .filters-form select {
      width: 100%;
      margin-right: 0;
      height: 3.75rem;
      border: none;
      background-image: url("../img/arrow-down-g.svg");
      background-position: right -1.6875rem center;
      background-size: 1.25rem 0.75rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      color: #696969;
      padding-left: 0.9375rem;
      padding-right: 2.9375rem; }
      @media print, screen and (min-width: 64em) {
        .products-filters .filters-form select {
          width: 28.9375rem;
          margin-right: 1.875rem;
          padding-left: 1.875rem; } }
    .products-filters .filters-form button {
      height: 3.75rem;
      background: #696969;
      color: #fff;
      text-align: center;
      width: 100%;
      border: none;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      line-height: 3.75rem;
      font-weight: 700; }
      @media print, screen and (min-width: 64em) {
        .products-filters .filters-form button {
          width: 12.625rem; } }
      .products-filters .filters-form button:hover {
        background: #555;
        cursor: pointer; }
  .products-filters .category-accordion {
    margin-bottom: 1.25rem; }
    .products-filters .category-accordion .count {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      line-height: 1.75rem;
      font-weight: 700;
      color: #696969;
      padding-left: 1.875rem; }
    .products-filters .category-accordion .accordion .accordion-item .accordion-title {
      background: #1b4886;
      border: none;
      border-bottom: 0.125rem solid #f5f5f5;
      padding: 0.9375rem 3.75rem 0.9375rem 1.875rem; }
      @media print, screen and (min-width: 64em) {
        .products-filters .category-accordion .accordion .accordion-item .accordion-title {
          padding: 1.875rem 11.25rem 1.875rem 1.875rem; } }
      .products-filters .category-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"] {
        background: #ebebeb;
        padding-bottom: 0;
        border-bottom: 0.125rem solid #ebebeb; }
        @media print, screen and (min-width: 64em) {
          .products-filters .category-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"] {
            padding-bottom: 1.875rem; } }
        .products-filters .category-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
          background: url("../img/arrow-up-b.svg") no-repeat center center;
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Safari */
          transform: rotate(180deg); }
        .products-filters .category-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"] h3, .products-filters .category-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"] p {
          color: #1b4886; }
      .products-filters .category-accordion .accordion .accordion-item .accordion-title:before {
        background: url("../img/arrow-up-w.svg") no-repeat center center;
        top: 0;
        content: '';
        width: 1.25rem;
        height: auto;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        background-size: 1.25rem 0.75rem;
        -webkit-transition: all 0.2s;
        /* Safari */
        transition: all 0.2s; }
        @media print, screen and (min-width: 40em) {
          .products-filters .category-accordion .accordion .accordion-item .accordion-title:before {
            width: 1.9375rem;
            background-size: 1.9375rem 1.1875rem; } }
      .products-filters .category-accordion .accordion .accordion-item .accordion-title h3 {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.625rem; }
      .products-filters .category-accordion .accordion .accordion-item .accordion-title p {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        margin: 0; }
    .products-filters .category-accordion .accordion .accordion-item .accordion-content {
      background: #ebebeb;
      border: none;
      padding: 0 1.875rem;
      border-bottom: 0.25rem solid #f5f5f5; }
      @media print, screen and (min-width: 64em) {
        .products-filters .category-accordion .accordion .accordion-item .accordion-content {
          padding: 3.75rem 1.875rem 3.75rem 1.875rem; } }
      .products-filters .category-accordion .accordion .accordion-item .accordion-content .prod-desc {
        color: #1b4886;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        margin: 0;
        padding-bottom: 0.9375rem; }
      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container {
        position: relative; }
        .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-2 {
          position: relative; }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-2 table.chart-table tbody tr td:nth-of-type(2) {
            padding-right: 3.75rem !important; }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-2 .shadow-0 {
            display: none; }
            @media print, screen and (min-width: 64em) {
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-2 .shadow-0 {
                background: #fff;
                position: absolute;
                top: -1.875rem;
                bottom: -1.875rem;
                right: 0;
                width: 40%;
                display: block;
                content: '';
                z-index: 1;
                box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); } }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-2 .shadow-1 {
            display: none; }
            @media print, screen and (min-width: 64em) {
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-2 .shadow-1 {
                background: #fff;
                position: absolute;
                top: -1.875rem;
                bottom: -1.875rem;
                right: 42%;
                width: 38%;
                display: block;
                content: '';
                z-index: 1;
                box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); } }
        .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-1 .shadow-0 {
          display: none; }
          @media print, screen and (min-width: 64em) {
            .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-1 .shadow-0 {
              display: block;
              background: #fff;
              position: absolute;
              top: -1.875rem;
              bottom: -1.875rem;
              right: 0;
              width: 80%;
              display: block;
              content: '';
              z-index: 1;
              box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); } }
        .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-3 {
          position: relative; }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-3 .shadow-0 {
            display: none; }
            @media print, screen and (min-width: 64em) {
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-3 .shadow-0 {
                background: #fff;
                position: absolute;
                top: -1.875rem;
                bottom: -1.875rem;
                right: 0;
                width: 25%;
                display: block;
                content: '';
                z-index: 1;
                box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); } }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-3 .shadow-1 {
            display: none; }
            @media print, screen and (min-width: 64em) {
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-3 .shadow-1 {
                background: #fff;
                position: absolute;
                top: -1.875rem;
                bottom: -1.875rem;
                right: 27%;
                width: 25%;
                display: block;
                content: '';
                z-index: 1;
                box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); } }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-3 .shadow-2 {
            display: none; }
            @media print, screen and (min-width: 64em) {
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container.count-3 .shadow-2 {
                background: #fff;
                position: absolute;
                top: -1.875rem;
                bottom: -1.875rem;
                right: 54%;
                width: 25%;
                display: block;
                content: '';
                z-index: 1;
                box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); } }
        .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table {
          position: relative;
          z-index: 2; }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody {
            background: transparent; }
            .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row {
              background: transparent; }
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td {
                vertical-align: top;
                background-color: transparent;
                padding-top: 0.3125rem;
                padding-bottom: 0; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.key {
                  color: #298dff;
                  font-family: 'Source Sans Pro', sans-serif;
                  font-size: 1rem;
                  font-weight: 600;
                  padding-left: 0;
                  width: 20%; }
                  .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.key:before {
                    content: ' ';
                    display: block;
                    width: 100%;
                    border-bottom: 0.125rem solid #dcdcdc;
                    vertical-align: bottom;
                    margin-bottom: 0.3125rem; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.value {
                  color: #696969;
                  font-family: 'Source Sans Pro', sans-serif;
                  font-size: 1rem;
                  font-weight: 400;
                  padding-left: 1.875rem;
                  padding-right: 1.875rem; }
                  .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.value:before {
                    content: ' ';
                    display: block;
                    width: 100%;
                    border-bottom: 0.125rem solid #ebebeb;
                    vertical-align: bottom;
                    margin-bottom: 0.3125rem; }
                  .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.value h3 {
                    text-align: center;
                    font-size: 1.1875rem;
                    font-weight: 700;
                    line-height: 1.4375rem;
                    margin: 0 0 0.625rem 0; }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.value h3 a {
                      color: #298dff; }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.value h3 a:hover {
                        color: #1b4886; }
                  .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.value p {
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 1.1875rem; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.width-2 {
                  width: 40%; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas {
                  padding-top: 0;
                  padding-bottom: 0;
                  padding-right: 1.875rem;
                  padding-left: 1.875rem; }
                  .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas.first-one {
                    padding-left: 1.875rem;
                    padding-right: 1.875rem; }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas.first-one:before {
                      content: ' ';
                      display: block;
                      width: 100%;
                      border-top: 0.125rem solid #ebebeb;
                      vertical-align: bottom;
                      margin-top: 1.5625rem;
                      padding-top: 1.5625rem; }
                  .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas a {
                    display: block;
                    text-align: center;
                    font-family: 'Source Sans Pro', sans-serif;
                    line-height: 1.4375rem;
                    font-size: 1.1875rem;
                    font-weight: 600;
                    margin-bottom: 1.5625rem; }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas a.try-browser {
                      color: #298dff;
                      position: relative; }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas a.try-browser:after {
                        position: absolute;
                        margin-left: 0.4375rem;
                        top: 0.25rem;
                        content: ' ';
                        display: inline-block;
                        width: 1.6875rem;
                        height: 1.1625rem;
                        background: url("../img/arrow-right-blue.svg") no-repeat center center;
                        background-size: 1.6875rem 1.1625rem; }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas a.try-browser:hover {
                        -webkit-filter: brightness(60%);
                        filter: brightness(60%); }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas a.trail-period {
                      border: 0.125rem solid #298dff;
                      border-radius: 3.125rem;
                      height: 3.125rem;
                      line-height: 3.125rem;
                      color: #298dff; }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas a.trail-period:hover {
                        color: #1b4886;
                        border-color: #1b4886; }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas a.buy-now {
                      background: #298dff;
                      border-radius: 3.125rem;
                      height: 3.125rem;
                      line-height: 3.125rem;
                      color: #fff;
                      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row td.product-ctas a.buy-now:hover {
                        background: #1b4886; }
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row:first-child td:before {
                content: '';
                display: none; }
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row:last-child td.key:after {
                content: ' ';
                display: block;
                width: 100%;
                border-bottom: 0.125rem solid #dbdddc;
                vertical-align: bottom;
                margin-top: 0.3125rem; }
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table tbody tr.row:last-child td.value:after {
                content: ' ';
                display: block;
                width: 100%;
                border-bottom: 0.125rem solid #ebebeb;
                vertical-align: bottom;
                margin-top: 0.3125rem; }
        .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile {
          border-bottom: 2px solid #dcdcdc;
          margin-bottom: 0;
          padding-top: 1.25rem; }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile:last-child {
            border-bottom: none; }
          .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody {
            background: transparent; }
            .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr {
              background: transparent; }
              .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td {
                background-color: transparent;
                padding: 0.5rem 0 0.1875rem 0; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.product h3 {
                  font-family: 'Source Sans Pro', sans-serif;
                  line-height: 1.4375rem;
                  font-size: 1.1875rem;
                  font-weight: 700;
                  margin: 0 0 0.9375rem 0;
                  text-align: center; }
                  .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.product h3 a {
                    color: #298dff; }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.product h3 a:hover {
                      color: #1b4886; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.product p {
                  font-family: 'Source Sans Pro', sans-serif;
                  line-height: 1.4375rem;
                  font-size: 1.1875rem;
                  font-weight: 600;
                  color: #696969;
                  margin: 0; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.features h3 {
                  color: #298dff;
                  font-family: 'Source Sans Pro', sans-serif;
                  font-size: 1.1875rem;
                  font-weight: 600;
                  line-height: 1.4375rem;
                  margin: 0; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.features p {
                  font-family: 'Source Sans Pro', sans-serif;
                  line-height: 1.4375rem;
                  font-size: 1.1875rem;
                  font-weight: 400;
                  color: #696969;
                  margin: 0; }
                .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas {
                  padding-top: 0;
                  padding-bottom: 0; }
                  .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas a {
                    display: block;
                    text-align: center;
                    font-family: 'Source Sans Pro', sans-serif;
                    line-height: 1.4375rem;
                    font-size: 1.1875rem;
                    font-weight: 600;
                    margin-bottom: 1.5625rem; }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas a.try-browser {
                      color: #298dff;
                      position: relative;
                      margin-top: 1.875rem; }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas a.try-browser:after {
                        position: absolute;
                        margin-left: 0.4375rem;
                        top: 0.25rem;
                        content: ' ';
                        display: inline-block;
                        width: 1.6875rem;
                        height: 1.1625rem;
                        background: url("../img/arrow-right-blue.svg") no-repeat center center;
                        background-size: 1.6875rem 1.1625rem; }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas a.try-browser:hover {
                        -webkit-filter: brightness(60%);
                        filter: brightness(60%); }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas a.trail-period {
                      border: 0.125rem solid #298dff;
                      border-radius: 3.125rem;
                      height: 3.125rem;
                      line-height: 3.125rem;
                      color: #298dff; }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas a.trail-period:hover {
                        color: #1b4886;
                        border-color: #1b4886; }
                    .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas a.buy-now {
                      background: #298dff;
                      border-radius: 3.125rem;
                      height: 3.125rem;
                      line-height: 3.125rem;
                      color: #fff;
                      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
                      .products-filters .category-accordion .accordion .accordion-item .accordion-content .chart-container table.chart-table-mobile tbody tr td.ctas a.buy-now:hover {
                        background: #1b4886; }
    .products-filters .category-accordion .accordion .accordion-item.is-active .accordion-title {
      background: #ebebeb;
      padding-bottom: 0;
      border-bottom: 0.125rem solid #ebebeb; }
      @media print, screen and (min-width: 64em) {
        .products-filters .category-accordion .accordion .accordion-item.is-active .accordion-title {
          padding-bottom: 1.875rem; } }
      .products-filters .category-accordion .accordion .accordion-item.is-active .accordion-title:before {
        background: url("../img/arrow-up-b.svg") no-repeat center center;
        -ms-transform: rotate(180deg);
        /* IE 9 */
        -webkit-transform: rotate(180deg);
        /* Safari */
        transform: rotate(180deg); }
      .products-filters .category-accordion .accordion .accordion-item.is-active .accordion-title h3, .products-filters .category-accordion .accordion .accordion-item.is-active .accordion-title p {
        color: #1b4886; }

.product-intro {
  padding-bottom: 3.125rem;
  position: relative; }
  .product-intro .product-details {
    margin-bottom: 1.875rem;
    font-family: 'Source Sans Pro', sans-serif;
    line-height: 1.4375rem;
    font-size: 1.1875rem; }
    .product-intro .product-details p {
      line-height: 1.4375rem;
      font-weight: 400;
      color: #696969; }
      .product-intro .product-details p a {
        color: #696969;
        font-weight: 400;
        text-decoration: underline; }
        .product-intro .product-details p a:hover {
          opacity: 0.7; }
      .product-intro .product-details p:first-child {
        color: #1b4886;
        font-weight: 600;
        margin-bottom: 0.625rem; }
        .product-intro .product-details p:first-child a {
          color: #1b4886;
          font-weight: 600;
          text-decoration: underline; }
          .product-intro .product-details p:first-child a:hover {
            opacity: 0.7; }
    .product-intro .product-details ul li {
      line-height: 1.4375rem;
      font-size: 1.1875rem;
      color: #696969; }
  .product-intro .product-ctas {
    width: auto; }
    @media print, screen and (min-width: 64em) {
      .product-intro .product-ctas {
        width: 30.9375rem; } }
    .product-intro .product-ctas a {
      display: block;
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      line-height: 1.4375rem;
      font-size: 1.1875rem;
      font-weight: 600;
      margin-bottom: 1.25rem;
      width: 100%; }
      .product-intro .product-ctas a.try-browser {
        color: #298dff;
        position: relative; }
        .product-intro .product-ctas a.try-browser:after {
          position: absolute;
          margin-left: 0.4375rem;
          top: 0.25rem;
          content: ' ';
          display: inline-block;
          width: 1.6875rem;
          height: 1.1625rem;
          background: url("../img/arrow-right-blue.svg") no-repeat center center;
          background-size: 1.6875rem 1.1625rem; }
        .product-intro .product-ctas a.try-browser:hover {
          -webkit-filter: brightness(60%);
          filter: brightness(60%); }
      .product-intro .product-ctas a.trail-period {
        border: 0.125rem solid #298dff;
        border-radius: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem;
        color: #298dff; }
        .product-intro .product-ctas a.trail-period:hover {
          color: #1b4886;
          border-color: #1b4886; }
      .product-intro .product-ctas a.buy-now {
        background: #298dff;
        border-radius: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem;
        color: #fff;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
        .product-intro .product-ctas a.buy-now:hover {
          background: #1b4886; }

.recommended-pricing .main-title {
  background: #0d7eff;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 40em) {
    .recommended-pricing .main-title {
      min-height: 5.625rem; } }
  .recommended-pricing .main-title:after, .recommended-pricing .main-title:before {
    display: none; }
    @media print, screen and (min-width: 40em) {
      .recommended-pricing .main-title:after, .recommended-pricing .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .recommended-pricing .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .recommended-pricing .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .recommended-pricing .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .recommended-pricing .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.recommended-pricing .plans {
  background: #298dff;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }
  .recommended-pricing .plans .plan {
    background: #fff;
    padding: 1.875rem;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
    .recommended-pricing .plans .plan h4 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.4375rem;
      margin: 0 0 0.625rem 0; }
    .recommended-pricing .plans .plan p {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      min-height: 5rem; }
      .recommended-pricing .plans .plan p:empty {
        display: none; }
      .recommended-pricing .plans .plan p > a {
        display: inline;
        margin: 0;
        background: none;
        line-height: 1.4375rem;
        color: #298dff;
        box-shadow: none; }
    .recommended-pricing .plans .plan .demo,
    .recommended-pricing .plans .plan .buy-now {
      display: block;
      margin-top: 0.9375rem;
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      background: #298dff;
      border-radius: 3.125rem;
      height: 3.125rem;
      line-height: 3.125rem;
      color: #fff;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
      .recommended-pricing .plans .plan .demo:hover,
      .recommended-pricing .plans .plan .buy-now:hover {
        background: #1b4886; }

.recommended-pricing .plans-mobile {
  background: #0d7eff;
  padding-top: 0;
  padding-bottom: 3.75rem; }
  .recommended-pricing .plans-mobile .accordion .accordion-item {
    border-bottom: 0.125rem solid #0d7eff; }
    .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-title {
      background: #298dff;
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      border: none;
      height: 4.875rem;
      line-height: 4.875rem;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.875rem; }
      .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"] {
        background: #298dff;
        color: #fff; }
        .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Safari */
          transform: rotate(180deg); }
      .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-title:before {
        background: url("../img/arrow-up-w.svg") no-repeat center center;
        top: 0;
        content: '';
        width: 1.25rem;
        height: auto;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        right: 1.25rem;
        background-size: 1.25rem 0.75rem;
        -webkit-transition: all 0.2s;
        /* Safari */
        transition: all 0.2s; }
        @media print, screen and (min-width: 64em) {
          .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-title:before {
            width: 1.9375rem;
            background-size: 1.9375rem 1.1875rem; } }
    .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-content {
      background: #298dff;
      border: none;
      padding: 1.875rem;
      padding-top: 0; }
      .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-content p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        color: #fff; }
        .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-content p:empty {
          display: none; }
      .recommended-pricing .plans-mobile .accordion .accordion-item .accordion-content .white-cta {
        display: block;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        margin-bottom: 1.25rem;
        background: #fff;
        border-radius: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem;
        color: #298dff;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }

.product-details .main-title {
  background: #f5f5f5;
  min-height: auto;
  text-align: center; }
  @media print, screen and (min-width: 64em) {
    .product-details .main-title {
      background: #ebebeb;
      min-height: 5.625rem;
      position: relative; } }
  .product-details .main-title:after, .product-details .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .product-details .main-title:after, .product-details .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .product-details .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .product-details .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .product-details .main-title h5 {
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .product-details .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.product-details .chart-accordion {
  background: #f5f5f5;
  padding-top: 0;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 64em) {
    .product-details .chart-accordion {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .product-details .chart-accordion .chart-container {
    position: relative;
    margin-top: 1.875rem; }
    .product-details .chart-accordion .chart-container:before {
      background: #fff;
      position: absolute;
      top: -1.875rem;
      bottom: -1.875rem;
      right: 0;
      width: 65%;
      display: block;
      content: '';
      z-index: 1;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
    .product-details .chart-accordion .chart-container table.chart-table {
      position: relative;
      z-index: 2; }
      .product-details .chart-accordion .chart-container table.chart-table tbody {
        background: transparent; }
        .product-details .chart-accordion .chart-container table.chart-table tbody tr {
          background: transparent; }
          .product-details .chart-accordion .chart-container table.chart-table tbody tr td {
            vertical-align: top;
            background-color: transparent;
            padding-top: 0.3125rem;
            padding-bottom: 0; }
            .product-details .chart-accordion .chart-container table.chart-table tbody tr td.key {
              color: #298dff;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 1rem;
              font-weight: 600;
              line-height: 1.1875rem;
              padding-left: 0;
              padding-right: 0; }
              .product-details .chart-accordion .chart-container table.chart-table tbody tr td.key:before {
                content: ' ';
                display: block;
                width: 100%;
                border-bottom: 0.125rem solid #ebebeb;
                vertical-align: bottom;
                margin-bottom: 0.3125rem; }
            .product-details .chart-accordion .chart-container table.chart-table tbody tr td.value {
              padding-left: 2.5rem;
              padding-right: 1.875rem;
              color: #696969;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.1875rem; }
              .product-details .chart-accordion .chart-container table.chart-table tbody tr td.value:before {
                content: ' ';
                display: block;
                width: 100%;
                border-bottom: 0.125rem solid #ebebeb;
                vertical-align: bottom;
                margin-bottom: 0.3125rem; }
  .product-details .chart-accordion .promotion-question {
    width: 65%;
    text-align: left;
    float: right;
    clear: both;
    margin-top: 2.5rem;
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 600;
    line-height: 1.4375rem; }
    .product-details .chart-accordion .promotion-question a {
      color: #696969;
      text-decoration: underline; }
      .product-details .chart-accordion .promotion-question a:hover {
        opacity: 0.7; }
  .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-title {
    background: #1b4886;
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    border: none;
    border-bottom: 0.125rem solid #fff;
    height: auto;
    line-height: 1.8125rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-left: 1.875rem;
    padding-right: 2.5rem;
    position: relative; }
    @media print, screen and (min-width: 64em) {
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-title {
        height: 3.75rem;
        line-height: 3.75rem;
        padding-top: 0;
        padding-bottom: 0; } }
    .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"] {
      background: #ebebeb;
      color: #1b4886;
      border-bottom: 0.125rem solid #ebebeb; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
        background: url("../img/arrow-up-b.svg") no-repeat center center;
        -ms-transform: rotate(180deg);
        /* IE 9 */
        -webkit-transform: rotate(180deg);
        /* Safari */
        transform: rotate(180deg); }
    .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-title:before {
      background: url("../img/arrow-up-w.svg") no-repeat center center;
      height: auto;
      width: 1.25rem;
      background-size: 1.25rem 0.75rem;
      position: absolute;
      -webkit-transition: all 0.2s;
      /* Safari */
      transition: all 0.2s;
      margin-top: 0;
      top: 0;
      bottom: 0;
      content: ''; }
      @media print, screen and (min-width: 64em) {
        .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-title:before {
          height: 3.75rem;
          width: 1.9375rem;
          background-size: 1.9375rem 1.1875rem; } }
  .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content {
    background: #ebebeb;
    border: none;
    padding: 1.875rem;
    padding-top: 0; }
    .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-wysiwyg {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      color: #696969; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-wysiwyg h4 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 700;
        line-height: 1.4375rem;
        margin: 0;
        color: #696969; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-wysiwyg p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        color: #696969; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-wysiwyg a:not([class="buy-now"]) {
        color: #696969;
        text-decoration: underline;
        font-weight: 600; }
    .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-buttons-set .dbutton-block {
      margin-bottom: 0.625rem; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-buttons-set .dbutton-block h3 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 700;
        line-height: 1.4375rem;
        color: #696969; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-buttons-set .dbutton-block p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        color: #696969; }
        .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-buttons-set .dbutton-block p a {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.1875rem;
          line-height: 1.4375rem;
          font-weight: 600;
          color: #696969; }
          .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-buttons-set .dbutton-block p a img {
            width: 1.6875rem;
            height: 1.1625rem;
            margin-left: 0.375rem; }
          .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-buttons-set .dbutton-block p a:hover {
            opacity: 0.7; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-buttons-set .dbutton-block .cta {
        display: block;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        margin-bottom: 1.25rem;
        background: #298dff;
        border-radius: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem;
        color: #fff;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
        .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-buttons-set .dbutton-block .cta:hover {
          background: #1b4886; }
    .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison p {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem; }
    .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table {
      border-collapse: collapse;
      border: none; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table thead {
        background: transparent;
        border: none; }
        .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table thead tr th {
          color: #1b4886;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.75rem;
          border-bottom: 0.125rem solid #e0e0e0; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody {
        background: transparent;
        border: none; }
        .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr {
          background: transparent; }
          .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr td {
            vertical-align: middle;
            background-color: transparent;
            padding-top: 0.3125rem;
            padding-bottom: 0.3125rem;
            line-height: 1.2;
            border-bottom: 0.125rem solid #e0e0e0; }
            .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr td.partner-feature {
              color: #1b4886;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.1875rem; }
            .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr td.partner-check {
              text-align: center; }
              .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr td.partner-check .checked-feature {
                width: 0.7625rem;
                height: 0.80625rem;
                display: inline-block;
                background: url("../img/check-db.svg") no-repeat center center; }
              .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr td.partner-check:before {
                content: ' ';
                border-top: 0.125rem solid #ebebeb;
                display: block;
                position: relative;
                top: -0.625rem; }
            .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr td.partner-type-cta a {
              display: block;
              text-align: center;
              font-family: 'Source Sans Pro', sans-serif;
              line-height: 3.125rem;
              font-size: 1.1875rem;
              font-weight: 600;
              margin-top: 1.5625rem;
              color: #fff;
              height: 3.125rem;
              border-radius: 3.125rem; }
            .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr td.partner-type-cta:before {
              content: ' ';
              border-top: 0.125rem solid #ebebeb;
              display: block;
              position: relative;
              top: -0.625rem; }
            .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content .part-comparison table tbody tr td.partner-type-cta-empty:before {
              content: ' ';
              border-top: 0.125rem solid #dbdddc;
              display: block;
              position: relative;
              top: -0.625rem;
              right: 0.625rem; }
    .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content table.chart-table {
      position: relative;
      z-index: 2; }
      .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content table.chart-table tbody {
        background: transparent; }
        .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content table.chart-table tbody tr {
          background: transparent; }
          .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content table.chart-table tbody tr td {
            vertical-align: top;
            background-color: transparent;
            padding-top: 0.3125rem;
            padding-bottom: 0.3125rem; }
            .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content table.chart-table tbody tr td.key {
              color: #298dff;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 1.1875rem;
              font-weight: 600;
              line-height: 1.4375rem;
              padding-left: 0; }
            .product-details .chart-accordion .product-accordion .accordion .accordion-item .accordion-content table.chart-table tbody tr td.value {
              padding-left: 0;
              color: #696969;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 1.1875rem;
              font-weight: 400;
              line-height: 1.4375rem; }

.product-details .buy-now {
  display: block;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1875rem;
  font-weight: 600;
  background: #696969;
  border-radius: 3.125rem;
  height: 3.125rem;
  line-height: 3.125rem;
  color: #fff;
  box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative; }
  .product-details .buy-now:after {
    background: url("../img/download-w.svg") no-repeat center center;
    width: 0.75625rem;
    height: 1.0125rem;
    content: '';
    display: inline-block;
    margin-left: 0.3125rem;
    margin-bottom: -0.1875rem; }
  .product-details .buy-now:hover {
    background: #444;
    color: #eee; }
  .product-details .buy-now:focus {
    color: #eee; }

.product-resources .main-title {
  background: #ebebeb;
  min-height: auto;
  text-align: center; }
  @media print, screen and (min-width: 40em) {
    .product-resources .main-title {
      min-height: 5.625rem;
      position: relative; } }
  .product-resources .main-title:after, .product-resources .main-title:before {
    display: none; }
    @media print, screen and (min-width: 40em) {
      .product-resources .main-title:after, .product-resources .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .product-resources .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .product-resources .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .product-resources .main-title h5 {
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .product-resources .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.product-resources .resources {
  background: #ebebeb;
  padding-top: 3.125rem;
  padding-bottom: 3.75rem; }
  @media print, screen and (min-width: 64em) {
    .product-resources .resources {
      background: #f5f5f5; } }
  .product-resources .resources .columns {
    border-top: 0.125rem solid #ebebeb;
    border-left: none; }
    .product-resources .resources .columns:first-child {
      border-left: none;
      border-top: none; }
    @media print, screen and (min-width: 40em) {
      .product-resources .resources .columns {
        border-left: 0.125rem solid #ebebeb;
        border-top: none; } }
  .product-resources .resources .resource {
    padding: 0 1.875rem; }
    .product-resources .resources .resource .resource-icon {
      text-align: center;
      margin-bottom: 1.5625rem; }
      .product-resources .resources .resource .resource-icon img {
        height: 5.5625rem; }
        @media screen and (max-width: 39.9375em) {
          .product-resources .resources .resource .resource-icon img {
            display: none; } }
    .product-resources .resources .resource .resource-details {
      min-height: 10.9375rem; }
      .product-resources .resources .resource .resource-details h3 {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.625rem;
        color: #1b4886; }
      .product-resources .resources .resource .resource-details p {
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        color: #1b4886; }
    .product-resources .resources .resource .resource-cta {
      text-align: right; }
      .product-resources .resources .resource .resource-cta a {
        font-size: 1.1875rem;
        font-weight: 700;
        line-height: 1.4375rem;
        color: #1b4886; }
        .product-resources .resources .resource .resource-cta a img {
          width: 1.6875rem;
          height: 1.1625rem;
          margin-left: 0.375rem; }
    .product-resources .resources .resource:hover {
      opacity: 0.8;
      cursor: pointer; }

.product-resources .resources-mobile {
  background: #ebebeb;
  padding-top: 0;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 40em) {
    .product-resources .resources-mobile {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }
  .product-resources .resources-mobile .accordion .accordion-item {
    border-bottom: 0.125rem solid #ebebeb; }
    .product-resources .resources-mobile .accordion .accordion-item .accordion-title {
      background: #f5f5f5;
      color: #1b4886;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      border: none;
      height: 4.875rem;
      line-height: 4.875rem;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.875rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative; }
      .product-resources .resources-mobile .accordion .accordion-item .accordion-title:before {
        content: ''; }
      .product-resources .resources-mobile .accordion .accordion-item .accordion-title.full:before {
        background: url("../img/arrow-up-b.svg") no-repeat center center;
        top: 0;
        content: '';
        width: 1.25rem;
        height: auto;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        background-size: 1.25rem 0.75rem;
        -webkit-transition: all 0.2s;
        /* Safari */
        transition: all 0.2s; }
        @media print, screen and (min-width: 40em) {
          .product-resources .resources-mobile .accordion .accordion-item .accordion-title.full:before {
            width: 1.9375rem;
            background-size: 1.9375rem 1.1875rem; } }
      .product-resources .resources-mobile .accordion .accordion-item .accordion-title.full[aria-expanded="true"]:before {
        -ms-transform: rotate(180deg);
        /* IE 9 */
        -webkit-transform: rotate(180deg);
        /* Safari */
        transform: rotate(180deg); }
    .product-resources .resources-mobile .accordion .accordion-item .accordion-content {
      background: #f5f5f5;
      border: none;
      padding: 1.875rem;
      padding-top: 0; }
      .product-resources .resources-mobile .accordion .accordion-item .accordion-content h4 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 700;
        line-height: 1.4375rem;
        margin: 0;
        color: #696969; }
      .product-resources .resources-mobile .accordion .accordion-item .accordion-content p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        color: #696969; }
      .product-resources .resources-mobile .accordion .accordion-item .accordion-content .resource-cta {
        text-align: right; }
        .product-resources .resources-mobile .accordion .accordion-item .accordion-content .resource-cta a {
          font-size: 1.1875rem;
          font-weight: 700;
          line-height: 1.4375rem;
          color: #1b4886; }
          .product-resources .resources-mobile .accordion .accordion-item .accordion-content .resource-cta a img {
            width: 1.6875rem;
            height: 1.1625rem;
            margin-left: 0.375rem; }
          .product-resources .resources-mobile .accordion .accordion-item .accordion-content .resource-cta a:hover {
            opacity: 0.7; }

.ready-to-go {
  padding: 4.375rem 0 0 0; }
  .ready-to-go .cta-buttons {
    margin-bottom: 3.125rem;
    text-align: center; }
    .ready-to-go .cta-buttons .clear {
      clear: both; }
    .ready-to-go .cta-buttons .ready-text {
      font-size: 2.8125rem;
      line-height: 3.125rem;
      font-weight: 300;
      color: #298dff;
      float: none; }
      @media print, screen and (min-width: 64em) {
        .ready-to-go .cta-buttons .ready-text {
          float: left;
          font-size: 3.75rem;
          line-height: 3.75rem; } }
    .ready-to-go .cta-buttons .buy-now {
      display: block;
      text-align: center;
      font-size: 1.1875rem;
      font-weight: 600;
      margin-top: 1.25rem;
      margin-left: 0;
      border-radius: 3.125rem;
      height: 3.125rem;
      line-height: 3.125rem;
      background: #298dff;
      color: #fff;
      width: 100%;
      float: none; }
      .ready-to-go .cta-buttons .buy-now:hover {
        background: #1b4886; }
      @media print, screen and (min-width: 64em) {
        .ready-to-go .cta-buttons .buy-now {
          float: left;
          width: 20.125rem;
          display: inline-block;
          margin-left: 1.875rem;
          margin-top: 0.3125rem; } }
    .ready-to-go .cta-buttons .trail-period {
      display: block;
      text-align: center;
      font-size: 1.1875rem;
      font-weight: 600;
      margin-left: 0;
      margin-top: 1.25rem;
      border: 0.125rem solid #298dff;
      border-radius: 3.125rem;
      height: 3.125rem;
      line-height: 3.125rem;
      color: #298dff;
      width: 100%;
      float: none; }
      @media print, screen and (min-width: 64em) {
        .ready-to-go .cta-buttons .trail-period {
          display: inline-block;
          float: left;
          width: 20.5625rem;
          margin-left: 1.875rem;
          margin-top: 0.3125rem; } }
      .ready-to-go .cta-buttons .trail-period:hover {
        color: #1b4886;
        border-color: #1b4886; }

.related-widgets {
  padding: 1.25rem 0 1.5625rem 0; }
  .related-widgets .widget {
    padding: 1.5625rem 1.875rem 0 1.875rem;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
    .related-widgets .widget .widget-inner .widget-type {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.25rem;
      color: #fff;
      margin-bottom: 0.625rem; }
    .related-widgets .widget .widget-inner .widget-title {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      color: #fff;
      margin-bottom: 0.625rem; }
      .related-widgets .widget .widget-inner .widget-title.testimonial {
        position: relative; }
        .related-widgets .widget .widget-inner .widget-title.testimonial:before {
          content: '“';
          position: absolute;
          left: -0.9375rem; }
        .related-widgets .widget .widget-inner .widget-title.testimonial:after {
          content: '”'; }
    .related-widgets .widget .widget-inner .widget-image {
      background: #fff;
      margin: 1.5625rem 0; }
      .related-widgets .widget .widget-inner .widget-image img {
        padding: 1.25rem; }
    .related-widgets .widget .widget-inner .widget-excerpt {
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      color: #fff; }
    .related-widgets .widget .widget-inner .widget-report-date {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2rem;
      color: #fff; }
    .related-widgets .widget .widget-inner .widget-author {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2rem;
      color: #fff; }
    .related-widgets .widget .widget-inner .widget-position {
      font-size: 1rem;
      font-weight: 600;
      font-style: italic;
      line-height: 1.2rem;
      color: #fff; }
    .related-widgets .widget .widget-footer {
      height: 4.2875rem;
      line-height: 4.2875rem;
      text-align: right;
      font-size: 1.1875rem;
      font-weight: 700;
      border-top: 0.125rem solid rgba(0, 0, 0, 0.1); }
      .related-widgets .widget .widget-footer:empty {
        border-top: none; }
      .related-widgets .widget .widget-footer a {
        color: #fff; }
        .related-widgets .widget .widget-footer a img {
          width: 1.6875rem;
          height: 1.1625rem;
          margin-left: 0.375rem;
          display: inline; }
  .related-widgets .no-hover:hover {
    background: #696969; }
  .related-widgets .see-more {
    text-align: right;
    margin-top: 0.625rem; }
    .related-widgets .see-more a {
      line-height: 1.4375rem;
      font-size: 1rem;
      font-weight: 700;
      color: #696969; }
      .related-widgets .see-more a img {
        width: 0.61875rem;
        height: 0.91875rem;
        margin-left: 0.3125rem; }
      .related-widgets .see-more a:hover {
        opacity: 0.7; }

.related-widgets-mobile .accordion .accordion-item .accordion-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  color: #fff;
  height: 4.875rem;
  line-height: 4.875rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.875rem;
  position: relative; }
  .related-widgets-mobile .accordion .accordion-item .accordion-title:hover, .related-widgets-mobile .accordion .accordion-item .accordion-title:focus {
    background: none; }
  .related-widgets-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"] {
    background: none;
    border: none; }
    .related-widgets-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"]:hover, .related-widgets-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"]:focus {
      background: none;
      border: none; }
    .related-widgets-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
      background: url("../img/arrow-up-w.svg") no-repeat center center;
      -ms-transform: rotate(180deg);
      /* IE 9 */
      -webkit-transform: rotate(180deg);
      /* Safari */
      transform: rotate(180deg); }
  .related-widgets-mobile .accordion .accordion-item .accordion-title:before {
    background: url("../img/arrow-up-w.svg") no-repeat center center;
    top: 0;
    content: '';
    width: 1.25rem;
    height: auto;
    margin-top: 0;
    position: absolute;
    bottom: 0;
    background-size: 1.25rem 0.75rem;
    -webkit-transition: all 0.2s;
    /* Safari */
    transition: all 0.2s; }
    @media print, screen and (min-width: 40em) {
      .related-widgets-mobile .accordion .accordion-item .accordion-title:before {
        width: 1.9375rem;
        background-size: 1.9375rem 1.1875rem; } }

.related-widgets-mobile .accordion .accordion-item .accordion-content {
  background: none;
  padding: 0 1.875rem 1.875rem 1.875rem;
  border: none;
  margin: 0; }
  .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner {
    margin-bottom: 1.25rem; }
    .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-title {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      color: #fff;
      margin-bottom: 0.625rem; }
      .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-title.testimonial {
        position: relative; }
        .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-title.testimonial:before {
          content: '“';
          position: absolute;
          left: -0.9375rem; }
        .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-title.testimonial:after {
          content: '”'; }
    .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-image {
      background: #fff;
      margin: 1.5625rem 0; }
      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
        .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-image {
          width: 33%; } }
    .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-excerpt {
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      color: #fff; }
    .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-report-date {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2rem;
      color: #fff; }
    .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-author {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2rem;
      color: #fff; }
    .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-inner .widget-position {
      font-size: 1rem;
      font-weight: 600;
      font-style: italic;
      line-height: 1.2rem;
      color: #fff; }
  .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-footer {
    padding-top: 1.25rem;
    text-align: right;
    font-size: 1.1875rem;
    font-weight: 700;
    border-top: 0.125rem solid rgba(0, 0, 0, 0.1); }
    .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-footer:empty {
      border-top: none; }
    .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-footer a {
      color: #fff; }
      .related-widgets-mobile .accordion .accordion-item .accordion-content .widget-footer a img {
        width: 1.6875rem;
        height: 1.1625rem;
        margin-left: 0.375rem; }

.about-us {
  padding: 1.875rem 0 0 0;
  background: #fff; }
  .about-us .page-intro {
    padding-bottom: 3.125rem; }
    .about-us .page-intro h1 {
      margin-bottom: 1.25rem;
      line-height: 2.8125rem;
      font-size: 2.5rem;
      font-weight: 400;
      color: #1b4886; }
      @media print, screen and (min-width: 40em) {
        .about-us .page-intro h1 {
          line-height: 3.75rem;
          font-size: 3.75rem; } }
    .about-us .page-intro p {
      line-height: 1.4375rem;
      font-size: 1.1875rem;
      font-weight: 400;
      color: #696969;
      margin-bottom: 0.625rem; }
      .about-us .page-intro p strong {
        font-weight: 600; }
      .about-us .page-intro p:empty {
        display: none; }
    .about-us .page-intro .intro-cta a {
      display: block;
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      margin-top: 1.25rem;
      border-radius: 3.125rem;
      height: 3.125rem;
      line-height: 3.125rem;
      color: #fff;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
  .about-us .team {
    background: #f5f5f5; }
    .about-us .team .member {
      padding-top: 3.75rem;
      padding-bottom: 2.8125rem;
      border-top: 0.125rem solid #ebebeb;
      text-align: center; }
      @media print, screen and (min-width: 40em) {
        .about-us .team .member {
          text-align: left; } }
      .about-us .team .member:first-child {
        border-top: none; }
      .about-us .team .member .member-picture {
        margin-bottom: 1.25rem; }
        .about-us .team .member .member-picture img {
          width: 100%;
          height: auto;
          background: #fff;
          border: 0.0625rem solid #ebebeb;
          padding: 0.1875rem; }
          @media print, screen and (min-width: 40em) {
            .about-us .team .member .member-picture img {
              border-radius: 0;
              border: none;
              padding: 0; } }
      .about-us .team .member .member-name {
        color: #298dff;
        line-height: 1.75rem;
        font-size: 1.5rem;
        font-weight: 700; }
      .about-us .team .member .member-position {
        color: #696969;
        line-height: 1.75rem;
        font-size: 1.5rem;
        font-weight: 400;
        margin-bottom: 1.25rem; }
      .about-us .team .member .member-bio {
        color: #696969;
        font-size: 1.1875rem;
        font-weight: 400;
        text-align: left; }
        .about-us .team .member .member-bio p {
          line-height: 1.4375rem;
          margin-bottom: 0.625rem; }
      .about-us .team .member .member-bio-right {
        color: #696969;
        margin-top: 0;
        font-size: 1.1875rem;
        font-weight: 400;
        text-align: left; }
        @media print, screen and (min-width: 64em) {
          .about-us .team .member .member-bio-right {
            margin-top: 4.75rem; } }
        .about-us .team .member .member-bio-right p {
          line-height: 1.4375rem;
          margin-bottom: 0.625rem; }
  .about-us .customers {
    background: #f5f5f5;
    padding: 3.75rem 0 1.875rem 0; }
    .about-us .customers .customer {
      margin-bottom: 1.875rem;
      background: #ffffff;
      height: 7.3125rem;
      text-align: center; }
      .about-us .customers .customer img {
        max-height: 100%; }

.the-page {
  padding: 1.875rem 0 0 0; }
  .the-page .page-intro {
    padding-bottom: 3.125rem; }
    .the-page .page-intro h1 {
      margin-bottom: 1.875rem;
      line-height: 2.8125rem;
      font-size: 2.5rem;
      font-weight: 400;
      color: #1b4886; }
      @media print, screen and (min-width: 64em) {
        .the-page .page-intro h1 {
          line-height: 3.75rem;
          font-size: 3.75rem; } }
    .the-page .page-intro p {
      line-height: 1.4375rem;
      font-size: 1.1875rem;
      font-weight: 600;
      color: #1b4886; }
      .the-page .page-intro p strong {
        font-weight: 600; }
      .the-page .page-intro p:empty {
        display: none; }

.bundles-box .main-title {
  background: #0d7eff;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 40em) {
    .bundles-box .main-title {
      min-height: 5.625rem; } }
  .bundles-box .main-title:after, .bundles-box .main-title:before {
    display: none; }
    @media print, screen and (min-width: 40em) {
      .bundles-box .main-title:after, .bundles-box .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .bundles-box .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 40em) {
      .bundles-box .main-title:after {
        border-width: 1.875rem;
        margin-left: -1.875rem; } }
  .bundles-box .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 1.125rem;
    margin-left: -1.125rem; }
    @media print, screen and (min-width: 40em) {
      .bundles-box .main-title:before {
        border-width: 2.25rem;
        margin-left: -2.25rem; } }
  .bundles-box .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    padding: 1.25rem 0 0.625rem 0; }
    @media print, screen and (min-width: 40em) {
      .bundles-box .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.bundles-box .bundles {
  background: #298dff;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 40em) {
    .bundles-box .bundles {
      padding-top: 3.75rem; } }
  .bundles-box .bundles .bundle {
    background: #fff;
    padding: 2.5rem 1.875rem 1.875rem 1.875rem;
    text-align: center;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-bottom: 1.875rem; }
    .bundles-box .bundles .bundle .bundle-icon {
      margin-bottom: 2.5rem; }
      .bundles-box .bundles .bundle .bundle-icon img {
        height: 4.6875rem; }
    .bundles-box .bundles .bundle h4 {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.4375rem;
      margin-bottom: 1.25rem;
      text-align: left; }
    .bundles-box .bundles .bundle p {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      min-height: 12.5rem;
      text-align: left; }
      .bundles-box .bundles .bundle p:empty {
        display: none; }
    .bundles-box .bundles .bundle .demo {
      display: block;
      margin-top: 1.5625rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      background: #c86b1f;
      border-radius: 3.125rem;
      height: 3.125rem;
      line-height: 3.125rem;
      color: #fff;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
      .bundles-box .bundles .bundle .demo:hover {
        background: #c85608; }
    .bundles-box .bundles .bundle .learn-more {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      line-height: 1.4375rem;
      font-weight: 600;
      color: #696969; }
      .bundles-box .bundles .bundle .learn-more img {
        width: 1.6875rem;
        height: 1.1625rem;
        margin-left: 0.375rem; }
      .bundles-box .bundles .bundle .learn-more:hover {
        opacity: 0.7; }

.bundles-box .bundles-mobile {
  background: #0d7eff;
  padding-top: 0;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 40em) {
    .bundles-box .bundles-mobile {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }
  .bundles-box .bundles-mobile .accordion .accordion-item {
    border-bottom: 0.125rem solid #0d7eff; }
    .bundles-box .bundles-mobile .accordion .accordion-item .accordion-title {
      background: #298dff;
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      border: none;
      height: 4.875rem;
      line-height: 4.875rem;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.875rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .bundles-box .bundles-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"] {
        background: #298dff;
        color: #fff;
        border-bottom: 0.125rem solid #298dff; }
        .bundles-box .bundles-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Safari */
          transform: rotate(180deg); }
      .bundles-box .bundles-mobile .accordion .accordion-item .accordion-title:before {
        background: url("../img/arrow-up-w.svg") no-repeat center center;
        top: 0;
        content: '';
        width: 1.25rem;
        height: auto;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        background-size: 1.25rem 0.75rem;
        -webkit-transition: all 0.2s;
        /* Safari */
        transition: all 0.2s; }
        @media print, screen and (min-width: 64em) {
          .bundles-box .bundles-mobile .accordion .accordion-item .accordion-title:before {
            width: 1.9375rem;
            background-size: 1.9375rem 1.1875rem; } }
    .bundles-box .bundles-mobile .accordion .accordion-item .accordion-content {
      background: #298dff;
      border: none;
      padding: 1.875rem;
      padding-top: 0;
      text-align: center; }
      .bundles-box .bundles-mobile .accordion .accordion-item .accordion-content p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        color: #fff;
        margin-bottom: 1.25rem;
        text-align: left; }
        .bundles-box .bundles-mobile .accordion .accordion-item .accordion-content p:empty {
          display: none; }
      .bundles-box .bundles-mobile .accordion .accordion-item .accordion-content .white-cta {
        display: block;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        margin-bottom: 1.25rem;
        background: #fff;
        border-radius: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem;
        margin-top: 1.25rem;
        color: #298dff;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
      .bundles-box .bundles-mobile .accordion .accordion-item .accordion-content .learn-more {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        line-height: 1.4375rem;
        font-weight: 600;
        color: #fff; }
        .bundles-box .bundles-mobile .accordion .accordion-item .accordion-content .learn-more img {
          width: 1.6875rem;
          height: 1.1625rem;
          margin-left: 0.375rem; }

.cta-box {
  padding: 5rem 0; }
  .cta-box .cta-title {
    font-weight: 300;
    color: #6a8e2c;
    font-size: 2.8125rem;
    line-height: 3.125rem;
    text-align: center;
    float: none; }
    @media print, screen and (min-width: 64em) {
      .cta-box .cta-title {
        line-height: 3.75rem;
        font-size: 3.75rem;
        text-align: left;
        float: left; } }
  .cta-box .cta-button {
    float: none;
    width: 100%; }
    @media print, screen and (min-width: 64em) {
      .cta-box .cta-button {
        float: right;
        width: 40%; } }
    .cta-box .cta-button a {
      display: block;
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      background: #6a8e2c;
      border-radius: 3.125rem;
      height: 3.125rem;
      line-height: 3.125rem;
      margin-top: 2.5rem;
      color: #fff;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
      .cta-box .cta-button a:hover {
        background: #4c8e2c; }

.faqs {
  background: #f5f5f5;
  padding: 3.75rem 0; }
  .faqs .faq-section h3.count {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    color: #696969; }
  .faqs .faq-section .section-title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    color: #696969;
    margin-bottom: 0.9375rem; }
  .faqs .faq-section .the-faq-accordion {
    margin-bottom: 1.25rem; }
    .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-title {
      background: #1b4886;
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      border: none;
      border-bottom: 0.125rem solid #fff;
      line-height: 1.625rem;
      padding: 0.9375rem 3.125rem 0.9375rem 1.875rem;
      position: relative; }
      .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"] {
        background: #ebebeb;
        color: #1b4886;
        border-bottom: 0.125rem solid #ebebeb; }
        .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
          background: url("../img/arrow-up-b.svg") no-repeat center center;
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Safari */
          transform: rotate(180deg); }
      .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-title:before {
        background: url("../img/arrow-up-w.svg") no-repeat center center;
        top: 0;
        content: '';
        width: 1.25rem;
        height: auto;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        background-size: 1.25rem 0.75rem;
        -webkit-transition: all 0.2s;
        /* Safari */
        transition: all 0.2s; }
        @media print, screen and (min-width: 40em) {
          .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-title:before {
            width: 1.9375rem;
            background-size: 1.9375rem 1.1875rem; } }
    .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content {
      background: #ebebeb;
      border: none;
      padding: 1.875rem;
      padding-top: 0;
      color: #696969;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem; }
      .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content h4 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 700;
        line-height: 1.4375rem;
        margin: 0;
        color: #696969; }
      .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        color: #696969; }
      .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content table.chart-table {
        position: relative;
        z-index: 2; }
        .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content table.chart-table tbody {
          background: transparent; }
          .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content table.chart-table tbody tr {
            background: transparent; }
            .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content table.chart-table tbody tr td {
              vertical-align: top;
              background-color: transparent;
              padding-top: 0.3125rem;
              padding-bottom: 0.3125rem; }
              .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content table.chart-table tbody tr td.key {
                color: #298dff;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.1875rem;
                padding-left: 0; }
              .faqs .faq-section .the-faq-accordion .accordion .accordion-item .accordion-content table.chart-table tbody tr td.value {
                padding-left: 0;
                color: #696969;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.1875rem; }
  .faqs .search-box {
    background: #ebebeb;
    padding: 1.875rem;
    margin-bottom: 1.875rem; }
    .faqs .search-box h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      margin: 0 0 1.25rem 0;
      padding: 0; }
    .faqs .search-box form {
      position: relative;
      margin-bottom: 0; }
      .faqs .search-box form input {
        height: 3.125rem;
        line-height: 3.125rem;
        background: #fff;
        border: none;
        box-shadow: none;
        margin-bottom: 0; }
      .faqs .search-box form button {
        position: absolute;
        right: 1.25rem;
        top: 0.9375rem;
        margin-bottom: 0; }
        .faqs .search-box form button img {
          width: 1.175rem;
          height: 1.16875rem; }
      .faqs .search-box form:hover button {
        opacity: 0.7; }
  .faqs .filters-box {
    background: #ebebeb;
    padding: 1.875rem 1.875rem 0.9375rem 1.875rem;
    margin-bottom: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .faqs .filters-box {
        margin-bottom: 0; } }
    .faqs .filters-box h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      margin: 0 0 0 0;
      padding: 0 0 1.25rem 0;
      border-bottom: 0.125rem solid #dcdcdc; }
    .faqs .filters-box .filters-box-content .filters-accordion {
      list-style: none;
      margin: 0;
      padding: 0; }
      .faqs .filters-box .filters-box-content .filters-accordion .accordion-item {
        border-top: 0.125rem solid #dcdcdc;
        padding: 0.9375rem 0 0.9375rem 0; }
        .faqs .filters-box .filters-box-content .filters-accordion .accordion-item:first-child {
          border-top: none; }
        .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-title {
          background: none;
          color: #298dff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.1875rem;
          font-weight: 700;
          border: none;
          text-transform: uppercase;
          line-height: 1.4375rem;
          padding: 0;
          position: relative; }
          .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-title[aria-expanded="true"] {
            color: #298dff; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
              background: url("../img/arrow-up-sb.svg") no-repeat center center;
              -ms-transform: rotate(180deg);
              /* IE 9 */
              -webkit-transform: rotate(180deg);
              /* Safari */
              transform: rotate(180deg); }
          .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-title:before {
            background: url("../img/arrow-up-sb.svg") no-repeat center center;
            top: 0;
            content: '';
            width: 1.25rem;
            height: auto;
            margin-top: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            background-size: 1.25rem 0.75rem;
            -webkit-transition: all 0.2s;
            /* Safari */
            transition: all 0.2s; }
        .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content {
          background: none;
          border: none;
          padding: 0.9375rem 0 0 0; }
          .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item {
            padding: 0;
            margin-bottom: 0.125rem;
            /* checkbox aspect */
            /* checked mark aspect */
            /* checked mark aspect changes */
            /* disabled checkbox */
            /* accessibility */ }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item label {
              color: #696969;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 1.1875rem;
              font-weight: 400;
              line-height: 1.4375rem; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked),
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked {
              position: absolute;
              left: -624.9375rem; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked) + label,
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked + label {
              position: relative;
              padding-left: 1.5625rem;
              cursor: pointer;
              margin-left: 0; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked) + label:before,
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked + label:before {
              content: '';
              position: absolute;
              left: 0;
              top: 2px;
              width: 1.0625rem;
              height: 1.0625rem;
              border: 0.125rem solid #696969;
              background: none; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked) + label:after,
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked + label:after {
              content: '×';
              position: absolute;
              top: -0.6875rem;
              left: 0.15625rem;
              font-size: 1.5rem;
              color: #696969;
              line-height: 1.75;
              -webkit-transition: all .2s;
              -moz-transition: all .2s;
              -ms-transition: all .2s;
              transition: all .2s; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked) + label:after {
              opacity: 0;
              -webkit-transform: scale(0);
              -moz-transform: scale(0);
              -ms-transform: scale(0);
              transform: scale(0); }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked + label:after {
              opacity: 1;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              -ms-transform: scale(1);
              transform: scale(1); }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:disabled:not(:checked) + label:before,
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:disabled:checked + label:before {
              box-shadow: none;
              border-color: #bbb;
              background-color: #ddd; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:disabled:checked + label:after {
              color: #999; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:disabled + label {
              color: #aaa; }
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked:focus + label:before,
            .faqs .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked):focus + label:before {
              border: 1px solid #fff; }

.partner-program {
  padding: 1.875rem 0 1.875rem 0;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .partner-program {
      padding: 1.875rem 0 3.75rem 0; } }
  .partner-program .page-title h1 {
    margin-bottom: 1rem;
    line-height: 2.8125rem;
    font-size: 2.5rem;
    font-weight: 400;
    color: #1b4886; }
    @media print, screen and (min-width: 40em) {
      .partner-program .page-title h1 {
        line-height: 3.75rem;
        font-size: 3.75rem; } }
  .partner-program .page-intro {
    margin-bottom: 1.25rem; }
    .partner-program .page-intro p {
      line-height: 1.4375rem;
      font-size: 1.1875rem;
      font-weight: 400;
      color: #696969;
      margin-bottom: 1.875rem; }
      .partner-program .page-intro p strong {
        font-weight: 600; }
      .partner-program .page-intro p:empty {
        display: none; }
  .partner-program .cta-button .become-partner {
    display: block;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 600;
    background: #1b4886;
    border-radius: 3.125rem;
    height: 3.125rem;
    line-height: 3.125rem;
    color: #fff;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
    .partner-program .cta-button .become-partner:hover {
      background: #1b55b0; }

.partner-benefits .main-title {
  background: #298dff;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .partner-benefits .main-title {
      background: #0d7eff;
      min-height: 5.625rem; } }
  .partner-benefits .main-title:after, .partner-benefits .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .partner-benefits .main-title:after, .partner-benefits .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .partner-benefits .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 64em) {
      .partner-benefits .main-title:after {
        border-width: 1.875rem;
        margin-left: -1.875rem; } }
  .partner-benefits .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 1.125rem;
    margin-left: -1.125rem; }
    @media print, screen and (min-width: 64em) {
      .partner-benefits .main-title:before {
        border-width: 2.25rem;
        margin-left: -2.25rem; } }
  .partner-benefits .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .partner-benefits .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.partner-benefits .partner-content {
  background: #298dff;
  padding-top: 0;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 40em) {
    .partner-benefits .partner-content {
      padding-top: 2.8125rem;
      padding-bottom: 3.75rem; } }
  .partner-benefits .partner-content .intro-text {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.5rem;
    margin-top: 0;
    margin-bottom: 1.875rem;
    text-align: left; }
    @media print, screen and (min-width: 64em) {
      .partner-benefits .partner-content .intro-text {
        font-size: 2.8125rem;
        line-height: 3.125rem;
        margin-bottom: 0; } }
  .partner-benefits .partner-content .benefit-list {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 400;
    line-height: 1.4375rem; }
    .partner-benefits .partner-content .benefit-list p {
      margin-bottom: 0.3125rem; }
    .partner-benefits .partner-content .benefit-list ul {
      margin-top: 0;
      margin-left: 1.0625rem; }
      .partner-benefits .partner-content .benefit-list ul li {
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        margin-bottom: 0.625rem; }

.partners-types .main-title {
  background: #f5f5f5;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .partners-types .main-title {
      background: #ebebeb;
      min-height: 5.625rem; } }
  .partners-types .main-title:after, .partners-types .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .partners-types .main-title:after, .partners-types .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .partners-types .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .partners-types .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .partners-types .main-title h5 {
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.875rem;
    padding: 2.1875rem 0 0 0; }
    @media print, screen and (min-width: 64em) {
      .partners-types .main-title h5 {
        font-size: 1.5rem;
        line-height: 1.875rem; } }
  .partners-types .main-title p {
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 400;
    line-height: 1.4375rem; }

.partners-types .partner-type-content {
  background: #f5f5f5;
  padding-top: 0;
  padding-bottom: 0; }
  @media print, screen and (min-width: 64em) {
    .partners-types .partner-type-content {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .partners-types .partner-type-content .comparison-table-container {
    position: relative;
    margin-top: 1.875rem; }
    .partners-types .partner-type-content .comparison-table-container:before {
      display: none; }
      @media print, screen and (min-width: 64em) {
        .partners-types .partner-type-content .comparison-table-container:before {
          background: #fff;
          position: absolute;
          top: -1.875rem;
          bottom: -1.875rem;
          right: 0;
          width: 33%;
          display: block;
          content: '';
          z-index: 2;
          box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); } }
    .partners-types .partner-type-content .comparison-table-container:after {
      display: none; }
      @media print, screen and (min-width: 64em) {
        .partners-types .partner-type-content .comparison-table-container:after {
          background: #fff;
          position: absolute;
          top: -1.875rem;
          bottom: -1.875rem;
          right: calc(33% + 30px);
          width: 33%;
          display: block;
          content: '';
          z-index: 2;
          box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); } }
  .partners-types .partner-type-content .comparison-table-container-mobile {
    padding: 1.875rem 1.875rem 0.625rem 1.875rem;
    background: #fff;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-bottom: 1.875rem; }
  .partners-types .partner-type-content table.comparison-table {
    position: relative;
    z-index: 3; }
    .partners-types .partner-type-content table.comparison-table tbody {
      background: transparent; }
      .partners-types .partner-type-content table.comparison-table tbody tr {
        background: transparent; }
        .partners-types .partner-type-content table.comparison-table tbody tr td {
          vertical-align: middle;
          background-color: transparent;
          padding-top: 0.1875rem;
          padding-bottom: 0.1875rem;
          line-height: 1.2; }
          .partners-types .partner-type-content table.comparison-table tbody tr td:nth-child(2) {
            position: relative;
            right: 1.5625rem;
            padding-left: 1.875rem;
            padding-right: 1.875rem; }
          .partners-types .partner-type-content table.comparison-table tbody tr td:nth-child(3) {
            position: relative;
            right: 0;
            padding-left: 1.875rem;
            padding-right: 1.875rem; }
          .partners-types .partner-type-content table.comparison-table tbody tr td.partner-type-name h4 {
            color: #298dff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 1.1875rem;
            font-weight: 700;
            line-height: 1.4375rem;
            text-align: center; }
          .partners-types .partner-type-content table.comparison-table tbody tr td.partner-type-name p {
            color: #696969;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.1875rem; }
          .partners-types .partner-type-content table.comparison-table tbody tr td.partner-feature {
            color: #298dff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.1875rem; }
            .partners-types .partner-type-content table.comparison-table tbody tr td.partner-feature:before {
              content: ' ';
              border-top: 0.125rem solid #dbdddc;
              display: block;
              position: relative;
              top: -0.625rem;
              right: 0.625rem; }
          .partners-types .partner-type-content table.comparison-table tbody tr td.partner-check {
            text-align: center; }
            .partners-types .partner-type-content table.comparison-table tbody tr td.partner-check .checked-feature {
              width: 0.7625rem;
              height: 0.80625rem;
              display: inline-block;
              background: url("../img/check-b.svg") no-repeat center center; }
            .partners-types .partner-type-content table.comparison-table tbody tr td.partner-check:before {
              content: ' ';
              border-top: 0.125rem solid #ebebeb;
              display: block;
              position: relative;
              top: -0.625rem; }
          .partners-types .partner-type-content table.comparison-table tbody tr td.partner-type-cta a {
            display: block;
            text-align: center;
            font-family: 'Source Sans Pro', sans-serif;
            line-height: 3.125rem;
            font-size: 1.1875rem;
            font-weight: 600;
            margin-top: 1.5625rem;
            color: #fff;
            height: 3.125rem;
            border-radius: 3.125rem; }
          .partners-types .partner-type-content table.comparison-table tbody tr td.partner-type-cta:before {
            content: ' ';
            border-top: 0.125rem solid #ebebeb;
            display: block;
            position: relative;
            top: -0.625rem; }
          .partners-types .partner-type-content table.comparison-table tbody tr td.partner-type-cta-empty:before {
            content: ' ';
            border-top: 0.125rem solid #dbdddc;
            display: block;
            position: relative;
            top: -0.625rem;
            right: 0.625rem; }
  .partners-types .partner-type-content table.comparison-table-mobile tbody {
    background: transparent; }
    .partners-types .partner-type-content table.comparison-table-mobile tbody tr {
      background: transparent; }
      .partners-types .partner-type-content table.comparison-table-mobile tbody tr td {
        background-color: transparent;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        line-height: 1.5; }
        .partners-types .partner-type-content table.comparison-table-mobile tbody tr td.partner-type-name h4 {
          color: #298dff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.1875rem;
          font-weight: 700;
          line-height: 1.4375rem;
          text-align: center; }
        .partners-types .partner-type-content table.comparison-table-mobile tbody tr td.partner-type-name p {
          color: #696969;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.1875rem; }
        .partners-types .partner-type-content table.comparison-table-mobile tbody tr td.partner-feature {
          color: #298dff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.1875rem;
          border-top: 0.125rem solid #ebebeb; }
        .partners-types .partner-type-content table.comparison-table-mobile tbody tr td.partner-check {
          text-align: left; }
          .partners-types .partner-type-content table.comparison-table-mobile tbody tr td.partner-check .checked-feature {
            width: 0.7625rem;
            height: 0.80625rem;
            display: inline-block;
            background: url("../img/check-b.svg") no-repeat center center; }
        .partners-types .partner-type-content table.comparison-table-mobile tbody tr td.partner-type-cta {
          border-top: 0.125rem solid #ebebeb;
          padding-top: 1.875rem; }
          .partners-types .partner-type-content table.comparison-table-mobile tbody tr td.partner-type-cta a {
            display: block;
            text-align: center;
            font-family: 'Source Sans Pro', sans-serif;
            line-height: 3.125rem;
            font-size: 1.1875rem;
            font-weight: 600;
            color: #fff;
            height: 3.125rem;
            border-radius: 3.125rem; }

.support-channels .main-title {
  background: #298dff;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .support-channels .main-title {
      min-height: 5.625rem;
      background: #0d7eff; } }
  .support-channels .main-title:after, .support-channels .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .support-channels .main-title:after, .support-channels .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .support-channels .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 0.9375rem;
    margin-left: -0.9375rem; }
    @media print, screen and (min-width: 64em) {
      .support-channels .main-title:after {
        border-width: 1.875rem;
        margin-left: -1.875rem; } }
  .support-channels .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 1.125rem;
    margin-left: -1.125rem; }
    @media print, screen and (min-width: 64em) {
      .support-channels .main-title:before {
        border-width: 2.25rem;
        margin-left: -2.25rem; } }
  .support-channels .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .support-channels .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.support-channels .channels {
  background: #298dff;
  padding-top: 0;
  padding-bottom: 0; }
  @media print, screen and (min-width: 64em) {
    .support-channels .channels {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .support-channels .channels .channel {
    background: #fff;
    padding: 2.5rem 1.875rem 0 1.875rem;
    text-align: center;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-bottom: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .support-channels .channels .channel {
        margin-bottom: 0; } }
    .support-channels .channels .channel .channel-icon {
      margin-bottom: 1.5625rem; }
      .support-channels .channels .channel .channel-icon img {
        height: 5.5625rem; }
    .support-channels .channels .channel .channel-details h3 {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.4375rem;
      margin-bottom: 0.625rem;
      text-align: left; }
    .support-channels .channels .channel .channel-details p {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      text-align: left;
      min-height: 4.9375rem; }
      .support-channels .channels .channel .channel-details p:empty {
        display: none; }
    .support-channels .channels .channel .channel-cta {
      height: 4.5625rem;
      line-height: 4.5625rem;
      text-align: right;
      border-top: 0.125rem solid #ebebeb; }
      .support-channels .channels .channel .channel-cta a {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        line-height: 1.5625rem;
        font-weight: 700;
        color: #696969; }
        .support-channels .channels .channel .channel-cta a img {
          width: 1.6875rem;
          height: 1.1625rem;
          margin-left: 0.375rem; }
        .support-channels .channels .channel .channel-cta a:hover {
          opacity: 0.7; }

.blog-page {
  padding: 3.75rem 0;
  background: #f5f5f5; }
  .blog-page .recent-posts .count {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 700;
    color: #696969; }
  .blog-page .recent-posts h3.recent-posts-title {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    color: #696969;
    margin-bottom: -0.75rem; }
  .blog-page .recent-posts .post-item {
    padding: 1.75rem 0;
    border-bottom: 0.125rem solid #dcdcdc; }
    .blog-page .recent-posts .post-item .row {
      padding-left: 0;
      padding-right: 0; }
      @media print, screen and (min-width: 64em) {
        .blog-page .recent-posts .post-item .row {
          padding-left: 0;
          padding-right: 0; } }
    .blog-page .recent-posts .post-item .post-thumbnail {
      margin-bottom: 1.25rem; }
      @media print, screen and (min-width: 64em) {
        .blog-page .recent-posts .post-item .post-thumbnail {
          margin-bottom: 0; } }
      .blog-page .recent-posts .post-item .post-thumbnail img {
        height: auto;
        min-width: 100%; }
        @media print, screen and (min-width: 64em) {
          .blog-page .recent-posts .post-item .post-thumbnail img {
            height: 10rem;
            min-width: 100%; } }
        .blog-page .recent-posts .post-item .post-thumbnail img:hover {
          opacity: 0.8; }
    .blog-page .recent-posts .post-item .post-details h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      margin-bottom: 0; }
      .blog-page .recent-posts .post-item .post-details h3 a {
        color: #1b4886; }
        .blog-page .recent-posts .post-item .post-details h3 a:hover {
          color: #2D5F86; }
    .blog-page .recent-posts .post-item .post-details h5 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.1875rem;
      font-style: italic;
      color: #696969; }
    .blog-page .recent-posts .post-item .post-details p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      color: #696969;
      margin-bottom: 0.625rem; }
    .blog-page .recent-posts .post-item .post-details .read-more {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      line-height: 1.4375rem;
      font-weight: 700;
      color: #696969; }
      .blog-page .recent-posts .post-item .post-details .read-more img {
        width: 1.6875rem;
        height: 1.1625rem;
        margin-left: 0.375rem; }
      .blog-page .recent-posts .post-item .post-details .read-more:hover {
        opacity: 0.7; }
    .blog-page .recent-posts .post-item .row .medium-4 {
      padding-left: 1.25rem;
      padding-right: 1.25rem; }
      @media print, screen and (min-width: 64em) {
        .blog-page .recent-posts .post-item .row .medium-4 {
          padding-left: 0.3125rem; } }
    .blog-page .recent-posts .post-item .row .medium-8 {
      padding-left: 1.25rem;
      padding-right: 1.25rem; }
      @media print, screen and (min-width: 64em) {
        .blog-page .recent-posts .post-item .row .medium-8 {
          padding-right: 0; } }
  .blog-page .recent-posts .pagination .page-numbers {
    display: none; }
  .blog-page .recent-posts .pagination .next {
    display: block;
    text-align: center;
    color: #1b4886;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem; }
    .blog-page .recent-posts .pagination .next:hover {
      background: none;
      opacity: 0.7; }
    .blog-page .recent-posts .pagination .next:after {
      content: url("../img/arrow-up-b.svg");
      width: 1.3125rem;
      height: 0.8125rem;
      display: inline-block; }
  .blog-page .recent-posts .accordion .accordion-item .accordion-title {
    background: #1b4886;
    border: none;
    border-bottom: 0.125rem solid #f5f5f5;
    padding: 0.9375rem 3.125rem 0.9375rem 1.875rem; }
    .blog-page .recent-posts .accordion .accordion-item .accordion-title[aria-expanded="true"] {
      background: #ebebeb;
      border-bottom: 0.125rem solid #ebebeb; }
      .blog-page .recent-posts .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
        content: url("../img/arrow-up-b.svg");
        -ms-transform: rotate(180deg);
        /* IE 9 */
        -webkit-transform: rotate(180deg);
        /* Safari */
        transform: rotate(180deg); }
      .blog-page .recent-posts .accordion .accordion-item .accordion-title[aria-expanded="true"] h3, .blog-page .recent-posts .accordion .accordion-item .accordion-title[aria-expanded="true"] p {
        color: #1b4886; }
    .blog-page .recent-posts .accordion .accordion-item .accordion-title:before {
      content: url("../img/arrow-up-w.svg");
      width: 1.25rem;
      height: 0.75rem;
      top: 45%;
      right: 1.25rem;
      -webkit-transition: all 0.2s;
      /* Safari */
      transition: all 0.2s; }
      @media print, screen and (min-width: 64em) {
        .blog-page .recent-posts .accordion .accordion-item .accordion-title:before {
          width: 1.9375rem;
          height: 1.1875rem; } }
    .blog-page .recent-posts .accordion .accordion-item .accordion-title h3 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem; }
    .blog-page .recent-posts .accordion .accordion-item .accordion-title p {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      margin: 0; }
  .blog-page .recent-posts .accordion .accordion-item .accordion-content {
    background: #ebebeb;
    border: none;
    padding: 0 1.875rem 1.25rem 1.875rem; }
    .blog-page .recent-posts .accordion .accordion-item .accordion-content .features .feature {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      color: #696969;
      margin-bottom: 0.3125rem; }
      .blog-page .recent-posts .accordion .accordion-item .accordion-content .features .feature strong {
        font-weight: 700; }
      .blog-page .recent-posts .accordion .accordion-item .accordion-content .features .feature a {
        color: #696969;
        text-decoration: underline; }
      .blog-page .recent-posts .accordion .accordion-item .accordion-content .features .feature .star {
        width: 0.875rem;
        height: 0.84375rem;
        display: inline-block;
        background: url("../img/star.svg") no-repeat center center;
        margin-right: 0;
        padding-right: 0; }
    .blog-page .recent-posts .accordion .accordion-item .accordion-content .accordion-cta-box {
      margin-top: 1.875rem;
      padding-bottom: 1.875rem; }
      .blog-page .recent-posts .accordion .accordion-item .accordion-content .accordion-cta-box .intro {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 3.125rem;
        color: #6a8e2c;
        float: none;
        text-align: center; }
        @media print, screen and (min-width: 64em) {
          .blog-page .recent-posts .accordion .accordion-item .accordion-content .accordion-cta-box .intro {
            float: left;
            text-align: left; } }
      .blog-page .recent-posts .accordion .accordion-item .accordion-content .accordion-cta-box .cta {
        float: none; }
        @media print, screen and (min-width: 64em) {
          .blog-page .recent-posts .accordion .accordion-item .accordion-content .accordion-cta-box .cta {
            float: right; } }
        .blog-page .recent-posts .accordion .accordion-item .accordion-content .accordion-cta-box .cta a {
          width: 100%;
          height: 3.125rem;
          display: block;
          text-align: center;
          border-radius: 3.125rem;
          border: none;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.1875rem;
          line-height: 3.125rem;
          font-weight: 600;
          background: #6a8e2c;
          color: #fff;
          box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
          @media print, screen and (min-width: 64em) {
            .blog-page .recent-posts .accordion .accordion-item .accordion-content .accordion-cta-box .cta a {
              width: 23.25rem; } }
          .blog-page .recent-posts .accordion .accordion-item .accordion-content .accordion-cta-box .cta a:hover {
            background: #1f6e08; }
  .blog-page .search-box {
    background: #ebebeb;
    padding: 1.25rem;
    margin-bottom: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .blog-page .search-box {
        padding: 1.875rem; } }
    .blog-page .search-box h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      margin: 0 0 1.25rem 0;
      padding: 0; }
    .blog-page .search-box form {
      position: relative;
      margin-bottom: 0; }
      .blog-page .search-box form input {
        height: 3.125rem;
        line-height: 3.125rem;
        background: #fff;
        border: none;
        box-shadow: none;
        margin-bottom: 0; }
      .blog-page .search-box form button {
        position: absolute;
        right: 0.9375rem;
        top: 0.9375rem;
        margin-bottom: 0; }
        .blog-page .search-box form button img {
          width: 1.175rem;
          height: 1.16875rem; }
      .blog-page .search-box form:hover button {
        opacity: 0.7; }
  .blog-page .filters-box {
    background: #ebebeb;
    padding: 1.25rem 1.25rem 0 1.25rem;
    margin-bottom: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .blog-page .filters-box {
        padding: 1.875rem 1.875rem 0 1.875rem; } }
    .blog-page .filters-box h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      margin: 0 0 0 0;
      padding: 0 0 0.9375rem 0;
      border-bottom: 0.125rem solid #dcdcdc; }
    .blog-page .filters-box .filters-box-content .filters-accordion {
      list-style: none;
      margin: 0;
      padding: 0; }
      .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item {
        padding: 0.9375rem 0 0.9375rem 0;
        border-top: 0.125rem solid #dcdcdc; }
        .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item:first-child {
          border-top: none; }
        .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-title {
          background: none;
          color: #298dff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.1875rem;
          font-weight: 700;
          border: none;
          text-transform: uppercase;
          line-height: 1.4375rem;
          padding: 0;
          position: relative; }
          .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-title[aria-expanded="true"] {
            color: #298dff; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
              background: url("../img/arrow-up-sb.svg") no-repeat center center;
              -ms-transform: rotate(180deg);
              /* IE 9 */
              -webkit-transform: rotate(180deg);
              /* Safari */
              transform: rotate(180deg); }
          .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-title:before {
            background: url("../img/arrow-up-sb.svg") no-repeat center center;
            top: 0;
            content: '';
            width: 1.25rem;
            height: auto;
            margin-top: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            background-size: 1.25rem 0.75rem;
            -webkit-transition: all 0.2s;
            /* Safari */
            transition: all 0.2s; }
        .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content {
          background: none;
          border: none;
          padding: 0.9375rem 0 0 0;
          margin: 0; }
          .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item {
            margin-bottom: 0.125rem;
            /* checkbox aspect */
            /* checked mark aspect */
            /* checked mark aspect changes */
            /* disabled checkbox */
            /* accessibility */ }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item label {
              color: #696969;
              font-family: 'Source Sans Pro', sans-serif;
              font-size: 1.1875rem;
              font-weight: 400;
              line-height: 1.4375rem;
              margin-left: 0; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked),
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked {
              position: absolute;
              left: -624.9375rem; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked) + label,
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked + label {
              position: relative;
              padding-left: 1.5625rem;
              cursor: pointer; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked) + label:before,
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked + label:before {
              content: '';
              position: absolute;
              left: 0;
              top: 2px;
              width: 1.0625rem;
              height: 1.0625rem;
              border: 0.125rem solid #696969;
              background: none; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked) + label:after,
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked + label:after {
              content: '×';
              position: absolute;
              top: -0.125rem;
              left: 0.1rem;
              font-size: 1.625rem;
              font-weight: bold;
              color: #696969;
              -webkit-transition: all .2s;
              -moz-transition: all .2s;
              -ms-transition: all .2s;
              transition: all .2s; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked) + label:after {
              opacity: 0;
              -webkit-transform: scale(0);
              -moz-transform: scale(0);
              -ms-transform: scale(0);
              transform: scale(0); }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked + label:after {
              opacity: 1;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              -ms-transform: scale(1);
              transform: scale(1); }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:disabled:not(:checked) + label:before,
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:disabled:checked + label:before {
              box-shadow: none;
              border-color: #bbb;
              background-color: #ddd; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:disabled:checked + label:after {
              color: #999; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:disabled + label {
              color: #aaa; }
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:checked:focus + label:before,
            .blog-page .filters-box .filters-box-content .filters-accordion .accordion-item .accordion-content .filter-item [type="checkbox"]:not(:checked):focus + label:before {
              border: 1px solid #fff; }
  .blog-page .archives-box {
    background: #ebebeb;
    padding: 1.25rem 1.25rem 0.3125rem 1.25rem;
    margin-bottom: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .blog-page .archives-box {
        padding: 1.875rem 1.875rem 0.3125rem 1.875rem; } }
    .blog-page .archives-box h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      margin: 0;
      padding: 0 0 0.9375rem 0; }
    .blog-page .archives-box .archives-box-content .filter-item {
      margin-bottom: 0.625rem;
      /* checkbox aspect */
      /* checked mark aspect */
      /* checked mark aspect changes */
      /* disabled checkbox */
      /* accessibility */ }
      .blog-page .archives-box .archives-box-content .filter-item label {
        color: #696969;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        margin-left: 0; }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:not(:checked),
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:checked {
        position: absolute;
        left: -624.9375rem; }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:not(:checked) + label,
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 1.5625rem;
        cursor: pointer; }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:not(:checked) + label:before,
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 1.0625rem;
        height: 1.0625rem;
        border: 0.125rem solid #696969;
        background: none; }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:not(:checked) + label:after,
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:checked + label:after {
        content: '×';
        position: absolute;
        top: -0.125rem;
        left: 0.1rem;
        font-size: 1.625rem;
        font-weight: bold;
        color: #696969;
        -webkit-transition: all .2s;
        -moz-transition: all .2s;
        -ms-transition: all .2s;
        transition: all .2s; }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0); }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:disabled:not(:checked) + label:before,
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd; }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:disabled:checked + label:after {
        color: #999; }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:disabled + label {
        color: #aaa; }
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:checked:focus + label:before,
      .blog-page .archives-box .archives-box-content .filter-item [type="checkbox"]:not(:checked):focus + label:before {
        border: 1px solid #fff; }

.single-post-page {
  background: #f5f5f5;
  padding: 1.875rem 0 5rem 0; }
  .single-post-page .margin-top {
    margin-top: 0.9375rem; }
  .single-post-page .single-post h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 2.8125rem;
    color: #1b4886; }
    @media print, screen and (min-width: 64em) {
      .single-post-page .single-post h1 {
        line-height: 3.75rem;
        font-size: 3.75rem; } }
  .single-post-page .single-post h5 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.1875rem;
    color: #696969;
    font-style: italic; }
  .single-post-page .single-post-content .post-subtitle {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 600;
    line-height: 1.4375rem;
    color: #1b4886;
    margin-bottom: 1.875rem; }
  .single-post-page .single-post-content .post-image {
    margin-bottom: 1.875rem; }
  .single-post-page .single-post-content .post-boxed-paragraph {
    background: #fff;
    padding: 1.875rem;
    margin-bottom: 1.875rem; }
    .single-post-page .single-post-content .post-boxed-paragraph > h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 700;
      line-height: 1.4375rem;
      color: #1b4886;
      text-transform: uppercase; }
    .single-post-page .single-post-content .post-boxed-paragraph .boxed-paragraph-content ul {
      margin-bottom: 0;
      padding-bottom: 0; }
      .single-post-page .single-post-content .post-boxed-paragraph .boxed-paragraph-content ul li {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        color: #696969;
        margin-top: 0.625rem; }
  .single-post-page .single-post-content .post-plain-paragraph {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 400;
    line-height: 1.4375rem;
    color: #696969;
    margin-bottom: 1.875rem; }
    .single-post-page .single-post-content .post-plain-paragraph p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      color: #696969; }
    .single-post-page .single-post-content .post-plain-paragraph h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 700;
      line-height: 1.4375rem;
      color: #1b4886;
      text-transform: uppercase; }
    .single-post-page .single-post-content .post-plain-paragraph h4 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 700;
      line-height: 1.4375rem;
      color: #696969; }
  .single-post-page .single-post-content .post-quote-box {
    width: 100%;
    margin: 0 auto 1.875rem auto; }
    @media print, screen and (min-width: 64em) {
      .single-post-page .single-post-content .post-quote-box {
        width: 85%; } }
    .single-post-page .single-post-content .post-quote-box p {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.875rem;
      font-weight: 300;
      line-height: 2.1875rem;
      color: #298dff; }
      @media print, screen and (min-width: 64em) {
        .single-post-page .single-post-content .post-quote-box p {
          line-height: 3.125rem;
          font-size: 2.8125rem; } }
    .single-post-page .single-post-content .post-quote-box h5 {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      line-height: 1.4375rem;
      color: #298dff; }
  .single-post-page .related {
    background: #ebebeb;
    padding: 1.875rem;
    margin-bottom: 1.875rem;
    margin-top: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .single-post-page .related {
        margin-top: 0; } }
    .single-post-page .related h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      margin: 0;
      padding: 0 0 1.25rem 0; }
    .single-post-page .related ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .single-post-page .related ul li {
        border-top: 0.125rem solid #dcdcdc;
        padding: 1.25rem 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        line-height: 1.4375rem; }
        .single-post-page .related ul li a {
          color: #298dff; }
          .single-post-page .related ul li a:hover {
            color: #1b4886; }

.share {
  border-top: 0.125rem solid #dcdcdc;
  padding-top: 1.25rem; }
  .share span {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: #298dff;
    display: inline-block;
    margin-right: 0.625rem;
    float: left; }
  .share .attachment {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: #298dff;
    display: block;
    margin-left: 0;
    float: none;
    clear: both; }
    .share .attachment img {
      width: 1.225rem;
      height: 1.66875rem;
      margin-left: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .share .attachment {
        float: left;
        display: inline-block;
        margin-left: 2.5rem;
        clear: none; } }
    .share .attachment:hover {
      color: #195599; }
      .share .attachment:hover img {
        -webkit-filter: brightness(60%);
        filter: brightness(60%); }
  .share ul {
    list-style: none;
    display: inline;
    margin: 0;
    padding: 0;
    float: none; }
    @media print, screen and (min-width: 40em) {
      .share ul {
        float: left; } }
    .share ul li {
      display: inline-block;
      margin: 0 0.625rem; }
      .share ul li a:hover img {
        -webkit-filter: brightness(60%);
        filter: brightness(60%); }
      .share ul li a.twitter img {
        width: 1.70125rem;
        height: 1.43687rem;
        margin-top: 0.25rem; }
      .share ul li a.facebook img {
        width: 0.79375rem;
        height: 1.70125rem; }
      .share ul li a.linkedin img {
        width: 1.70125rem;
        height: 1.70125rem; }
      .share ul li a.email img {
        width: 1.8375rem;
        height: 1.26125rem;
        margin-top: 0.425rem; }

.contact-channels .main-title {
  background: #298dff;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .contact-channels .main-title {
      background: #0d7eff;
      min-height: 5.625rem; } }
  .contact-channels .main-title:after, .contact-channels .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .contact-channels .main-title:after, .contact-channels .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .contact-channels .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .contact-channels .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .contact-channels .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .contact-channels .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.contact-channels .channels {
  background: #298dff;
  padding-top: 0;
  padding-bottom: 0; }
  @media print, screen and (min-width: 64em) {
    .contact-channels .channels {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .contact-channels .channels .channel {
    background: #fff;
    padding: 2.5rem 1.875rem 0 1.875rem;
    text-align: center;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-bottom: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .contact-channels .channels .channel {
        margin-bottom: 0; } }
    .contact-channels .channels .channel .channel-icon {
      margin-bottom: 1.5625rem; }
      .contact-channels .channels .channel .channel-icon img {
        height: 5.5625rem; }
    .contact-channels .channels .channel .channel-details h3 {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.4375rem;
      margin-bottom: 0.625rem;
      text-align: left; }
    .contact-channels .channels .channel .channel-details p {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      text-align: left;
      min-height: 4.9375rem; }
      .contact-channels .channels .channel .channel-details p:empty {
        display: none; }
      .contact-channels .channels .channel .channel-details p a {
        color: #696969;
        text-decoration: underline; }
        .contact-channels .channels .channel .channel-details p a:hover {
          color: #999; }
    .contact-channels .channels .channel .channel-cta {
      height: 4.5625rem;
      line-height: 4.5625rem;
      text-align: right;
      border-top: 0.125rem solid #ebebeb; }
      .contact-channels .channels .channel .channel-cta a {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        line-height: 1.5625rem;
        font-weight: 700;
        color: #696969; }
        .contact-channels .channels .channel .channel-cta a img {
          width: 1.6875rem;
          height: 1.1625rem;
          margin-left: 0.375rem; }
        .contact-channels .channels .channel .channel-cta a:hover {
          opacity: 0.7; }

.contact-map .main-title {
  background: #f5f5f5;
  min-height: auto;
  text-align: center; }
  @media print, screen and (min-width: 64em) {
    .contact-map .main-title {
      background: #ebebeb;
      min-height: 5.625rem;
      position: relative; } }
  .contact-map .main-title:after, .contact-map .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .contact-map .main-title:after, .contact-map .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .contact-map .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .contact-map .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .contact-map .main-title h5 {
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .contact-map .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.contact-map .sections {
  background: #f5f5f5;
  padding-top: 0;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 64em) {
    .contact-map .sections {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .contact-map .sections .section {
    background: #ebebeb;
    padding: 1.875rem;
    padding-bottom: 0; }
    .contact-map .sections .section:last-child {
      margin-top: 1.875rem;
      margin-bottom: 1.875rem; }
      @media print, screen and (min-width: 64em) {
        .contact-map .sections .section:last-child {
          margin-top: 1.875rem;
          margin-bottom: 0; } }
    .contact-map .sections .section .section-details h3 {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.4375rem;
      margin-bottom: 0.75rem; }
    .contact-map .sections .section .section-details p {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      margin-bottom: 1.5rem; }
    .contact-map .sections .section .section-cta {
      text-align: right;
      border-top: 0.125rem solid #dcdcdc;
      height: 4.5625rem;
      line-height: 4.5625rem; }
      .contact-map .sections .section .section-cta a {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        line-height: 1.5625rem;
        font-weight: 700;
        color: #696969; }
        .contact-map .sections .section .section-cta a img {
          width: 1.6875rem;
          height: 1.1625rem;
          margin-left: 0.375rem; }
        .contact-map .sections .section .section-cta a:hover {
          opacity: 0.7; }
  .contact-map .sections .the-map iframe {
    height: 25rem; }
    @media print, screen and (min-width: 64em) {
      .contact-map .sections .the-map iframe {
        height: auto; } }

.swycd-plans .main-title {
  background: #0d7eff;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 40em) {
    .swycd-plans .main-title {
      min-height: 5.625rem; } }
  .swycd-plans .main-title:after, .swycd-plans .main-title:before {
    display: none; }
    @media print, screen and (min-width: 40em) {
      .swycd-plans .main-title:after, .swycd-plans .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .swycd-plans .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .swycd-plans .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .swycd-plans .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .swycd-plans .main-title h5 {
        font-size: 1.5rem;
        line-height: 1.5rem;
        padding-top: 1.875rem;
        padding-bottom: 0; } }
  .swycd-plans .main-title p {
    color: #fff;
    font-size: 1.1875rem;
    line-height: 1.4375rem;
    font-weight: 400; }

.swycd-plans .plans {
  background: #298dff;
  padding-top: 1.875rem;
  padding-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .swycd-plans .plans {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .swycd-plans .plans .plan {
    background: #fff;
    padding: 1.875rem;
    box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2);
    margin-bottom: 1.875rem; }
    @media print, screen and (min-width: 40em) {
      .swycd-plans .plans .plan {
        margin-bottom: 0; } }
    .swycd-plans .plans .plan h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.4375rem;
      margin: 0 0 0.625rem 0; }
    .swycd-plans .plans .plan h4 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 700;
      line-height: 1.625rem;
      margin: 0; }
    .swycd-plans .plans .plan h5 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      margin: 0; }
    .swycd-plans .plans .plan p {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem;
      margin: 0.625rem 0 0.9375rem 0; }
      .swycd-plans .plans .plan p:empty {
        display: none; }
      .swycd-plans .plans .plan p > a {
        color: #298dff; }
    .swycd-plans .plans .plan .features {
      list-style: none;
      margin: 0;
      padding: 0; }
      .swycd-plans .plans .plan .features li {
        position: relative;
        color: #298dff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        padding: 0.3125rem 0.9375rem;
        border-bottom: 0.125rem solid #ebebeb; }
        .swycd-plans .plans .plan .features li:first-child {
          border-top: 0.125rem solid #ebebeb; }
        .swycd-plans .plans .plan .features li img {
          position: absolute;
          left: -1.25rem;
          top: 0.75rem;
          width: 0.75rem;
          height: 0.75rem; }
    .swycd-plans .plans .plan .demo,
    .swycd-plans .plans .plan .buy-now {
      display: block;
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      background: #298dff;
      border-radius: 3.125rem;
      height: 3.125rem;
      line-height: 3.125rem;
      color: #fff;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
      .swycd-plans .plans .plan .demo:hover,
      .swycd-plans .plans .plan .buy-now:hover {
        background: #1b4886; }
      .swycd-plans .plans .plan .demo.demo,
      .swycd-plans .plans .plan .buy-now.demo {
        margin-top: 1.875rem;
        background: #fff;
        border: 0.125rem solid #298dff;
        color: #298dff;
        box-shadow: none;
        margin-bottom: 1.25rem; }
        .swycd-plans .plans .plan .demo.demo:hover,
        .swycd-plans .plans .plan .buy-now.demo:hover {
          background: #fff;
          border: 0.125rem solid #1b4886;
          color: #1b4886; }

.swycd-plans .plans-mobile {
  background: #0d7eff;
  padding-top: 0;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 40em) {
    .swycd-plans .plans-mobile {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }
  .swycd-plans .plans-mobile .accordion .accordion-item {
    border-bottom: 0.125rem solid #0d7eff; }
    .swycd-plans .plans-mobile .accordion .accordion-item .accordion-title {
      background: #298dff;
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      border: none;
      position: relative;
      height: 4.875rem;
      line-height: 4.875rem;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.875rem; }
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"] {
        background: #298dff;
        color: #fff; }
        .swycd-plans .plans-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Safari */
          transform: rotate(180deg); }
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-title:before {
        background: url("../img/arrow-up-w.svg") no-repeat center center;
        top: 0;
        content: '';
        width: 1.25rem;
        height: auto;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        background-size: 1.25rem 0.75rem;
        -webkit-transition: all 0.2s;
        /* Safari */
        transition: all 0.2s; }
        @media print, screen and (min-width: 40em) {
          .swycd-plans .plans-mobile .accordion .accordion-item .accordion-title:before {
            width: 1.9375rem;
            background-size: 1.9375rem 1.1875rem; } }
    .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content {
      background: #298dff;
      border: none;
      padding: 1.875rem;
      padding-top: 0; }
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content h3 {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.4375rem;
        margin: 0 0 0.625rem 0; }
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content h4 {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 700;
        line-height: 1.625rem;
        margin: 0; }
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content h5 {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        margin: 0; }
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content p {
        color: #fff;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 400;
        line-height: 1.4375rem;
        min-height: 5rem;
        margin: 0.625rem 0 0.9375rem 0; }
        .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content p:empty {
          display: none; }
        .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content p > a {
          color: #298dff; }
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .features {
        list-style: none;
        margin: 0;
        padding: 0; }
        .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .features li {
          position: relative;
          color: #fff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.1875rem;
          font-weight: 400;
          line-height: 1.4375rem;
          padding: 0.3125rem 0.9375rem;
          border-bottom: 0.125rem solid #1D81FB; }
          .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .features li:first-child {
            border-top: 0.125rem solid #1D81FB; }
          .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .features li img {
            position: absolute;
            left: -1.25rem;
            top: 0.75rem;
            width: 0.75rem;
            height: 0.75rem; }
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .demo,
      .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .buy-now {
        display: block;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        background: #fff;
        border-radius: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem;
        color: #298dff;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
        .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .demo:hover,
        .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .buy-now:hover {
          color: #1b4886; }
        .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .demo.demo,
        .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .buy-now.demo {
          margin-top: 1.875rem;
          background: #298dff;
          border: 0.125rem solid #fff;
          color: #fff;
          box-shadow: none;
          margin-bottom: 1.25rem; }
          .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .demo.demo:hover,
          .swycd-plans .plans-mobile .accordion .accordion-item .accordion-content .buy-now.demo:hover {
            background: #298dff;
            border: 0.125rem solid #eee;
            color: #eee; }

.movr-title-wrapper {
  padding: 1.875rem 0; }
  .movr-title-wrapper .movr-title h1 {
    line-height: 2.8125rem;
    font-size: 2.5rem;
    font-weight: 400;
    color: #1b4886; }
    @media print, screen and (min-width: 64em) {
      .movr-title-wrapper .movr-title h1 {
        line-height: 3.75rem;
        font-size: 3.75rem; } }

.movr-intro {
  padding-bottom: 3.125rem;
  position: relative; }
  .movr-intro .movr-details {
    margin-bottom: 1.875rem; }
    .movr-intro .movr-details p {
      font-family: 'Source Sans Pro', sans-serif;
      line-height: 1.4375rem;
      font-size: 1.1875rem;
      font-weight: 600;
      color: #1b4886;
      margin: 0; }
      .movr-intro .movr-details p strong {
        font-weight: 600; }
  .movr-intro .movr-cta {
    width: auto; }
    @media print, screen and (min-width: 64em) {
      .movr-intro .movr-cta {
        width: 30.9375rem; } }
    .movr-intro .movr-cta a {
      display: block;
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      margin-bottom: 1.25rem;
      border-radius: 3.125rem;
      height: 3.125rem;
      line-height: 3.125rem;
      color: #fff;
      box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }

.report-highlights .main-title {
  background: #0d7eff;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .report-highlights .main-title {
      min-height: 5.625rem; } }
  .report-highlights .main-title:after, .report-highlights .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .report-highlights .main-title:after, .report-highlights .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .report-highlights .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .report-highlights .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #0d7eff;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .report-highlights .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .report-highlights .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.report-highlights .highlights {
  background: #298dff;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 64em) {
    .report-highlights .highlights {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .report-highlights .highlights .col-text {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 400; }
    .report-highlights .highlights .col-text p {
      line-height: 1.4375rem;
      padding-left: 1.25rem; }
    .report-highlights .highlights .col-text ul li {
      line-height: 1.4375rem;
      margin-bottom: 0.9375rem; }
  .report-highlights .highlights .movr-sub-form {
    margin-top: 1.875rem;
    margin-bottom: 0; }
    .report-highlights .highlights .movr-sub-form .movr-subscription-form-label {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.5rem;
      float: none;
      text-align: center;
      margin-bottom: 0.9375rem; }
      @media print, screen and (min-width: 64em) {
        .report-highlights .highlights .movr-sub-form .movr-subscription-form-label {
          line-height: 3.125rem;
          float: right;
          font-size: 1.5rem;
          text-align: right;
          margin: 0; } }

.movr-widget .highlights .movr-sub-form {
  margin-top: 0; }

.current-report .main-title {
  background: #1b4886;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .current-report .main-title {
      background: #153c6a;
      min-height: 5.625rem; } }
  .current-report .main-title:after, .current-report .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .current-report .main-title:after, .current-report .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .current-report .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #153c6a;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .current-report .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #153c6a;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .current-report .main-title h5 {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .current-report .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.current-report .reports {
  background: #1b4886;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 64em) {
    .current-report .reports {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .current-report .reports .columns {
    border-top: 0.125rem solid #153c6a;
    border-left: none; }
    .current-report .reports .columns:first-child {
      border-top: none;
      border-left: none; }
    @media print, screen and (min-width: 64em) {
      .current-report .reports .columns {
        border-left: 0.125rem solid #153c6a;
        border-top: none; }
        .current-report .reports .columns:first-child {
          border-left: none;
          border-top: none; } }
  .current-report .reports .report {
    padding: 1.875rem; }
    @media print, screen and (min-width: 64em) {
      .current-report .reports .report {
        padding: 0 1.875rem; } }
    .current-report .reports .report .report-title {
      min-height: auto;
      margin-bottom: 0;
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif; }
      @media print, screen and (min-width: 64em) {
        .current-report .reports .report .report-title {
          min-height: 13.75rem;
          margin-bottom: 1.25rem; } }
      .current-report .reports .report .report-title h3 {
        font-size: 1.5rem;
        line-height: 1.625rem;
        font-weight: 700; }
      .current-report .reports .report .report-title p {
        font-size: 1.1875rem;
        line-height: 1.4375rem;
        font-weight: 400; }
    .current-report .reports .report .report-cta {
      text-align: right; }
      .current-report .reports .report .report-cta a {
        color: #fff;
        font-size: 1.1875rem;
        line-height: 1.4375rem;
        font-weight: 600; }
        .current-report .reports .report .report-cta a img {
          margin-left: 0.625rem; }
          .current-report .reports .report .report-cta a img.view {
            height: 1.1625rem;
            width: 1.6875rem; }
          .current-report .reports .report .report-cta a img.download {
            height: 1.1625rem; }
        .current-report .reports .report .report-cta a:hover {
          opacity: 0.7; }

.current-report .reports-mobile {
  background: #1b4886;
  padding-top: 0;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 64em) {
    .current-report .reports-mobile {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .current-report .reports-mobile .accordion .accordion-item {
    border-bottom: 0.125rem solid #1b4886; }
    .current-report .reports-mobile .accordion .accordion-item .accordion-title {
      background: #183D68;
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.3125rem;
      font-weight: 700;
      border: none;
      height: auto;
      line-height: 1.75rem;
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      padding-left: 1.25rem;
      padding-right: 3.125rem;
      overflow: hidden;
      position: relative; }
      @media print, screen and (min-width: 64em) {
        .current-report .reports-mobile .accordion .accordion-item .accordion-title {
          height: 4.875rem;
          line-height: 4.875rem;
          font-size: 1.5rem;
          padding-top: 0;
          padding-bottom: 0; } }
      .current-report .reports-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"] {
        background: #183D68;
        border-bottom: 0.125rem solid #183D68; }
        .current-report .reports-mobile .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Safari */
          transform: rotate(180deg); }
      .current-report .reports-mobile .accordion .accordion-item .accordion-title:before {
        background: url("../img/arrow-up-w.svg") no-repeat center center;
        top: 0;
        content: '';
        width: 1.25rem;
        height: auto;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        background-size: 1.25rem 0.75rem;
        -webkit-transition: all 0.2s;
        /* Safari */
        transition: all 0.2s; }
        @media print, screen and (min-width: 40em) {
          .current-report .reports-mobile .accordion .accordion-item .accordion-title:before {
            width: 1.9375rem;
            background-size: 1.9375rem 1.1875rem; } }
    .current-report .reports-mobile .accordion .accordion-item .accordion-content {
      background: #183D68;
      border: none;
      color: #fff;
      padding: 1.875rem 1.25rem;
      padding-top: 0;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 400;
      line-height: 1.4375rem; }
      .current-report .reports-mobile .accordion .accordion-item .accordion-content a {
        font-size: 1.1875rem;
        font-weight: 700;
        display: block;
        color: #fff;
        text-align: right;
        position: relative;
        right: 0; }
        .current-report .reports-mobile .accordion .accordion-item .accordion-content a img {
          margin-left: 0.3125rem; }
          .current-report .reports-mobile .accordion .accordion-item .accordion-content a img.download {
            width: 1.0375rem; }
          .current-report .reports-mobile .accordion .accordion-item .accordion-content a img.view {
            width: 1.56875rem;
            height: 1.225rem; }

.previous-report .main-title {
  background: #f5f5f5;
  min-height: auto;
  text-align: center;
  position: relative; }
  @media print, screen and (min-width: 64em) {
    .previous-report .main-title {
      background: #ebebeb;
      min-height: 5.625rem; } }
  .previous-report .main-title:after, .previous-report .main-title:before {
    display: none; }
    @media print, screen and (min-width: 64em) {
      .previous-report .main-title:after, .previous-report .main-title:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        display: block; } }
  .previous-report .main-title:after {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 1.875rem;
    margin-left: -1.875rem; }
  .previous-report .main-title:before {
    border-color: rgba(21, 60, 106, 0);
    border-top-color: #ebebeb;
    border-width: 2.25rem;
    margin-left: -2.25rem; }
  .previous-report .main-title h5 {
    color: #696969;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;
    padding: 0.625rem; }
    @media print, screen and (min-width: 64em) {
      .previous-report .main-title h5 {
        font-size: 1.5rem;
        line-height: 4.375rem;
        padding: 0.625rem; } }

.previous-report .reports {
  background: #f5f5f5;
  padding-top: 0;
  padding-bottom: 1.875rem; }
  @media print, screen and (min-width: 64em) {
    .previous-report .reports {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }
  .previous-report .reports .copying-notes {
    margin-top: 0.9375rem; }
    .previous-report .reports .copying-notes p {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      font-weight: 400;
      margin-bottom: 0.75rem; }
    .previous-report .reports .copying-notes strong {
      color: #696969;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      font-weight: 700; }
  .previous-report .reports .years .year h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem;
    color: #696969; }
  .previous-report .reports .years .year .report-accordion .accordion .accordion-item {
    border-bottom: 0.125rem solid #f5f5f5; }
    .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-title {
      background: #1b4886;
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.3125rem;
      font-weight: 700;
      border: none;
      position: relative;
      height: auto;
      line-height: 1.75rem;
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      padding-left: 1.875rem; }
      @media print, screen and (min-width: 64em) {
        .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-title {
          height: 3.75rem;
          line-height: 3.75rem;
          font-size: 1.5rem;
          padding-top: 0;
          padding-bottom: 0; } }
      .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"] {
        background: #ebebeb;
        color: #1b4886;
        border-bottom: 0.125rem solid #ebebeb; }
        .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
          background: url("../img/arrow-up-b.svg") no-repeat center center;
          -ms-transform: rotate(180deg);
          /* IE 9 */
          -webkit-transform: rotate(180deg);
          /* Safari */
          transform: rotate(180deg); }
      .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-title:before {
        background: url("../img/arrow-up-w.svg") no-repeat center center;
        top: 0;
        content: '';
        width: 1.25rem;
        height: auto;
        margin-top: 0;
        position: absolute;
        bottom: 0;
        right: 1.875rem;
        background-size: 1.25rem 0.75rem;
        -webkit-transition: all 0.2s;
        /* Safari */
        transition: all 0.2s; }
        @media print, screen and (min-width: 64em) {
          .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-title:before {
            width: 1.9375rem;
            background-size: 1.9375rem 1.1875rem; } }
    .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-content {
      background: #ebebeb;
      border: none;
      padding: 0 1.875rem; }
      .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-content ul {
        list-style: none;
        margin: 0;
        padding: 0; }
        .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-content ul li {
          border-top: 0.125rem solid #ddd;
          font-size: 1.1875rem;
          font-weight: 600;
          line-height: 1.4375rem;
          padding: 0.9375rem 0; }
          .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-content ul li a {
            display: block;
            color: #696969;
            position: relative; }
            .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-content ul li a img {
              position: absolute;
              right: 0; }
              .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-content ul li a img.download {
                width: 1.225rem;
                height: 1.66875rem; }
              .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-content ul li a img.view {
                width: 1.56875rem;
                height: 1.225rem; }
            .previous-report .reports .years .year .report-accordion .accordion .accordion-item .accordion-content ul li a:hover {
              -webkit-filter: brightness(60%);
              filter: brightness(60%); }

.documentation {
  padding: 1.875rem 0 0 0; }
  .documentation .page-intro h1 {
    margin-bottom: 1.25rem;
    line-height: 2.8125rem;
    font-size: 2.5rem;
    font-weight: 400;
    color: #1b4886; }
    @media print, screen and (min-width: 64em) {
      .documentation .page-intro h1 {
        line-height: 3.75rem;
        font-size: 3.75rem; } }
  .documentation .page-documentation {
    padding-bottom: 3.125rem; }
    .documentation .page-documentation p {
      line-height: 1.4375rem;
      font-size: 1.1875rem;
      font-weight: 400;
      color: #696969; }
      .documentation .page-documentation p strong {
        font-weight: 600; }
      .documentation .page-documentation p:empty {
        display: none; }

.documentation-content {
  background: #f5f5f5;
  padding-top: 1.875rem;
  padding-bottom: 0; }
  @media print, screen and (min-width: 64em) {
    .documentation-content {
      padding-top: 3.75rem;
      padding-bottom: 1.875rem; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .documentation-content > .row > div:nth-of-type(2n+1) {
      clear: both; } }
  @media print, screen and (min-width: 64em) {
    .documentation-content > .row > div:nth-of-type(3n+1) {
      clear: both; } }
  .documentation-content .doc-block {
    background: #ebebeb;
    margin-bottom: 1.875rem;
    padding: 1.875rem;
    position: relative; }
    .documentation-content .doc-block .doc-block-content h3 {
      font-size: 1.5rem;
      line-height: 1.625rem;
      font-weight: 700;
      color: #298dff;
      padding-bottom: 0.625rem; }
    .documentation-content .doc-block .doc-block-content > ul {
      list-style: none;
      margin: 0 0 1.875rem 0;
      padding: 0 0 6.25rem 0; }
      .documentation-content .doc-block .doc-block-content > ul > li {
        border-top: 0.125rem solid #dcdcdc;
        text-transform: uppercase; }
        .documentation-content .doc-block .doc-block-content > ul > li > a {
          color: #298dff;
          position: relative;
          display: block;
          padding: 0.625rem 0;
          font-size: 1.1875rem;
          font-weight: 700; }
          .documentation-content .doc-block .doc-block-content > ul > li > a img {
            position: absolute;
            right: 0;
            top: 1.125rem; }
            .documentation-content .doc-block .doc-block-content > ul > li > a img.view {
              width: 1.56875rem;
              height: 1.225rem; }
            .documentation-content .doc-block .doc-block-content > ul > li > a img.download {
              width: 1.225rem;
              top: 0.8125rem; }
          .documentation-content .doc-block .doc-block-content > ul > li > a:hover {
            -webkit-filter: brightness(60%);
            filter: brightness(60%); }
        .documentation-content .doc-block .doc-block-content > ul > li .accordion {
          background: none;
          border: none; }
          .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-title {
            background: none;
            border: none;
            color: #298dff;
            position: relative;
            display: block;
            padding: 0.9375rem 1.875rem 0.9375rem 0;
            font-size: 1.1875rem;
            font-weight: 700;
            text-transform: uppercase; }
            .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-title[aria-expanded="true"] {
              background: none;
              color: #298dff; }
              .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-title[aria-expanded="true"]:before {
                -ms-transform: rotate(180deg);
                /* IE 9 */
                -webkit-transform: rotate(180deg);
                /* Safari */
                transform: rotate(180deg); }
            .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-title:before {
              background: url("../img/arrow-up-sb.svg") no-repeat center center;
              top: 0;
              content: '';
              width: 1.25rem;
              height: auto;
              margin-top: 0;
              position: absolute;
              bottom: 0;
              right: 0;
              background-size: 1.25rem 0.75rem;
              -webkit-transition: all 0.2s;
              /* Safari */
              transition: all 0.2s; }
              @media print, screen and (min-width: 64em) {
                .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-title:before {
                  width: 1.5rem;
                  background-size: 1.5rem 0.875rem; } }
            .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-title:hover {
              -webkit-filter: brightness(60%);
              filter: brightness(60%); }
          .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content {
            background: none;
            border: none;
            padding: 0; }
            .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul {
              list-style: none;
              margin: 0;
              padding: 0; }
              .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li {
                border-top: 0.125rem solid #dcdcdc; }
                .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li > a {
                  color: #696969;
                  position: relative;
                  display: block;
                  padding: 0.625rem 1.875rem 0 0;
                  font-size: 1.1875rem;
                  font-weight: 400; }
                  .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li > a img {
                    position: absolute;
                    right: 0;
                    top: 1.125rem; }
                    .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li > a img.view {
                      width: 1.56875rem;
                      height: 1.225rem; }
                    .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li > a img.download {
                      width: 1.225rem;
                      top: 0.8125rem; }
                  .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li > a:hover {
                    -webkit-filter: brightness(60%);
                    filter: brightness(60%); }
                .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li ul.accordion li a {
                  color: #696969;
                  font-weight: 400; }
                  .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li ul.accordion li a:before {
                    background: url("../img/arrow-down-g.svg") no-repeat center center; }
                .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li ul.accordion li .accordion-content {
                  text-transform: none; }
                  .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li ul.accordion li .accordion-content p {
                    text-transform: none;
                    color: #696969; }
                    .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li ul.accordion li .accordion-content p a {
                      color: #696969;
                      text-decoration: underline; }
                  .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li ul.accordion li .accordion-content ul {
                    margin-bottom: 0.9375rem; }
                    .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li ul.accordion li .accordion-content ul li {
                      color: #696969;
                      border: none;
                      text-transform: none; }
                      .documentation-content .doc-block .doc-block-content > ul > li .accordion .accordion-item .accordion-content > ul > li ul.accordion li .accordion-content ul li a {
                        color: #696969; }
    .documentation-content .doc-block .doc-cta {
      position: absolute;
      bottom: 1.875rem;
      left: 0;
      right: 0; }
      .documentation-content .doc-block .doc-cta a {
        display: block;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        color: #fff;
        border-radius: 3.125rem;
        height: 3.125rem;
        line-height: 3.125rem;
        background: #298dff;
        margin: 0 1.875rem;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
        .documentation-content .doc-block .doc-cta a:hover {
          background: #1b4886; }

.search-no-results .the-filter-contents {
  background: #3190FC; }

.the-filter-title {
  padding: 2.5rem 0; }
  .the-filter-title h1 {
    font-size: 2.8125rem;
    line-height: 3.125rem;
    font-weight: 400;
    color: #1b4886; }
    @media print, screen and (min-width: 64em) {
      .the-filter-title h1 {
        font-size: 3.75rem;
        line-height: 3.75rem; } }

.the-filter-contents {
  background: #f5f5f5;
  padding: 1.875rem 0; }
  .the-filter-contents .nothing-found {
    font-size: 1.625rem;
    line-height: 1.9375rem;
    text-align: center;
    font-weight: 700;
    color: #fff; }
  .the-filter-contents .searchform {
    position: relative; }
    .the-filter-contents .searchform label {
      font-size: 1rem;
      line-height: 1.1875rem;
      font-weight: 400;
      color: #fff;
      margin-bottom: 0.625rem; }
    .the-filter-contents .searchform input[type="text"] {
      width: 100%;
      display: inline;
      float: left;
      border: none;
      box-shadow: none;
      height: 3.125rem;
      padding-right: 9.375rem; }
      .the-filter-contents .searchform input[type="text"]:focus {
        border: none;
        box-shadow: none; }
    .the-filter-contents .searchform input[type="submit"] {
      width: 9.375rem;
      position: absolute;
      right: 0;
      text-align: center;
      display: inline;
      height: 3.125rem;
      line-height: 3.125rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      color: #fff;
      border: none;
      background: #1b4987 url("../img/search-icon-w.svg") no-repeat 90% center;
      background-size: 1.25rem 1.25rem; }
      @media print, screen and (min-width: 64em) {
        .the-filter-contents .searchform input[type="submit"] {
          font-size: 1.5rem; } }
      .the-filter-contents .searchform input[type="submit"]:hover {
        opacity: 0.8;
        cursor: hand; }
  .the-filter-contents .filter-results {
    list-style: none;
    margin: 0;
    padding: 0; }
    .the-filter-contents .filter-results li {
      padding: 1.875rem 0;
      border-bottom: 0.125rem solid #ebebeb; }
      .the-filter-contents .filter-results li h3 {
        font-size: 1.875rem;
        line-height: 2.1875rem;
        font-weight: 700; }
        .the-filter-contents .filter-results li h3 a {
          color: #1b4886; }
          .the-filter-contents .filter-results li h3 a:hover {
            opacity: 0.7; }
        @media print, screen and (min-width: 64em) {
          .the-filter-contents .filter-results li h3 {
            font-size: 2.8125rem;
            line-height: 3.125rem; } }
      .the-filter-contents .filter-results li .the-date {
        font-size: 1rem;
        line-height: 1.1875rem;
        font-weight: 600;
        font-style: italic;
        color: #696969;
        margin-bottom: 0.625rem; }
      .the-filter-contents .filter-results li p {
        font-size: 1.1875rem;
        line-height: 1.4375rem;
        font-weight: 400;
        color: #696969; }
      .the-filter-contents .filter-results li .read-more {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        line-height: 1.4375rem;
        font-weight: 700;
        color: #696969; }
        .the-filter-contents .filter-results li .read-more img {
          width: 1.6875rem;
          height: 1.1625rem;
          margin-left: 0.375rem; }
        .the-filter-contents .filter-results li .read-more:hover {
          opacity: 0.7; }
  .the-filter-contents .pagination .page-numbers {
    display: none; }
  .the-filter-contents .pagination .next {
    display: block;
    text-align: center;
    color: #1b4886;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem; }
    .the-filter-contents .pagination .next:hover {
      background: none;
      opacity: 0.7; }
    .the-filter-contents .pagination .next:after {
      content: url("../img/arrow-up-b.svg");
      width: 1.3125rem;
      height: 0.8125rem;
      display: inline-block; }

.login-page {
  padding-top: 0; }
  @media print, screen and (min-width: 64em) {
    .login-page {
      padding-top: 3.75rem; } }
  .login-page .main-title {
    background: #298dff;
    min-height: auto;
    text-align: center;
    position: relative; }
    @media print, screen and (min-width: 64em) {
      .login-page .main-title {
        background: #0d7eff; } }
    .login-page .main-title:after, .login-page .main-title:before {
      display: none; }
      @media print, screen and (min-width: 64em) {
        .login-page .main-title:after, .login-page .main-title:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          display: block; } }
    .login-page .main-title:after {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: 0.9375rem;
      margin-left: -0.9375rem; }
      @media print, screen and (min-width: 64em) {
        .login-page .main-title:after {
          border-width: 1.875rem;
          margin-left: -1.875rem; } }
    .login-page .main-title:before {
      border-color: rgba(21, 60, 106, 0);
      border-top-color: #0d7eff;
      border-width: 1.125rem;
      margin-left: -1.125rem; }
      @media print, screen and (min-width: 64em) {
        .login-page .main-title:before {
          border-width: 2.25rem;
          margin-left: -2.25rem; } }
    .login-page .main-title h5 {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.625rem;
      padding: 1.25rem 0 0.3125rem 0;
      margin: 0; }
      @media print, screen and (min-width: 64em) {
        .login-page .main-title h5 {
          font-size: 1.5rem;
          line-height: 1.875rem;
          padding: 1.25rem 0 1.25rem 0; } }
    .login-page .main-title p {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      line-height: 1.4375rem;
      margin-top: 0; }
      @media print, screen and (min-width: 64em) {
        .login-page .main-title p {
          margin-top: -0.9375rem; } }
      .login-page .main-title p:empty {
        display: none;
        margin: 0;
        padding: 0;
        height: 0; }
  .login-page .login-page-form {
    background: #298dff;
    padding-top: 1.25rem; }
    @media print, screen and (min-width: 64em) {
      .login-page .login-page-form {
        padding-top: 3.75rem; } }
    .login-page .login-page-form .tml-login .message {
      color: #fff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.5rem;
      margin-left: 0;
      text-align: center;
      background: none;
      border: none; }
    .login-page .login-page-form .tml-login .tml-user-login-wrap label {
      font-weight: 600;
      color: #fff;
      font-size: 1.1875rem;
      line-height: 1.4375rem; }
    .login-page .login-page-form .tml-login .tml-user-login-wrap input {
      box-shadow: none;
      border: none; }
    .login-page .login-page-form .tml-login .tml-user-pass-wrap label {
      font-weight: 600;
      color: #fff;
      font-size: 1.1875rem;
      line-height: 1.4375rem; }
    .login-page .login-page-form .tml-login .tml-user-pass-wrap input {
      box-shadow: none;
      border: none; }
    .login-page .login-page-form .tml-login .tml-action-links {
      float: right; }
      .login-page .login-page-form .tml-login .tml-action-links li a {
        font-weight: 600;
        color: #fff;
        font-size: 1.1875rem;
        line-height: 1.4375rem; }
        .login-page .login-page-form .tml-login .tml-action-links li a:after {
          content: '?'; }
        .login-page .login-page-form .tml-login .tml-action-links li a:hover {
          opacity: 0.7; }
    .login-page .login-page-form .tml-login .tml-submit-wrap {
      clear: both;
      display: block; }
      .login-page .login-page-form .tml-login .tml-submit-wrap input {
        display: block;
        width: 100%;
        height: 3.125rem;
        line-height: 3.125rem;
        color: #fff;
        font-size: 1.1875rem;
        font-weight: 600;
        border-radius: 3.125rem;
        background: #1b4886;
        border: none;
        font-family: 'Source Sans Pro', sans-serif;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
        .login-page .login-page-form .tml-login .tml-submit-wrap input:hover {
          background: #0a3a74;
          cursor: pointer; }
    .login-page .login-page-form .tml-login .tml-rememberme-wrap {
      float: left;
      /* checkbox aspect */
      /* checked mark aspect */
      /* checked mark aspect changes */
      /* disabled checkbox */
      /* accessibility */ }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap:hover {
        opacity: 0.7; }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap label {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        color: #fff; }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:not(:checked),
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:checked {
        position: absolute;
        left: -9999px; }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:not(:checked) + label,
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 1.5625rem;
        cursor: pointer; }
        @media print, screen and (min-width: 64em) {
          .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:not(:checked) + label,
          .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:checked + label {
            padding-left: 2.1875rem; } }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:not(:checked) + label:before,
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.125rem;
        width: 1.25rem;
        height: 1.25rem;
        border: 2px solid #fff;
        background: none; }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:not(:checked) + label:after,
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:checked + label:after {
        content: '×';
        position: absolute;
        top: -0.875rem;
        left: 0.16875rem;
        font-size: 1.75rem;
        color: #fff;
        -webkit-transition: all .2s;
        -moz-transition: all .2s;
        -ms-transition: all .2s;
        transition: all .2s; }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0); }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:disabled:not(:checked) + label:before,
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd; }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:disabled:checked + label:after {
        color: #999; }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:disabled + label {
        color: #aaa; }
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:checked:focus + label:before,
      .login-page .login-page-form .tml-login .tml-rememberme-wrap [type="checkbox"]:not(:checked):focus + label:before {
        border: 1px solid #fff; }
  .login-page .tml {
    max-width: 100%; }
  .login-page .register-block {
    padding: 5rem 0; }
    .login-page .register-block .cta-text {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 2.8125rem;
      font-weight: 300;
      line-height: 3.125rem;
      color: #1b4886;
      float: none;
      margin-left: 0;
      text-align: center; }
      @media print, screen and (min-width: 64em) {
        .login-page .register-block .cta-text {
          float: left;
          margin-left: -1.875rem;
          text-align: left;
          font-size: 3.75rem;
          line-height: 3.75rem; } }
    .login-page .register-block .cta-button {
      float: none;
      margin-top: 1.25rem; }
      @media print, screen and (min-width: 64em) {
        .login-page .register-block .cta-button {
          float: right;
          margin-top: 0; } }
      .login-page .register-block .cta-button a {
        display: block;
        width: 100%;
        height: 3.125rem;
        line-height: 3.125rem;
        color: #fff;
        font-size: 1.1875rem;
        font-weight: 600;
        border-radius: 3.125rem;
        background: #1b4886;
        border: none;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
        .login-page .register-block .cta-button a:hover {
          background: #0a3a74;
          cursor: pointer; }
        @media print, screen and (min-width: 64em) {
          .login-page .register-block .cta-button a {
            width: 35.25rem; } }

body .signup-form {
  padding: 3.75rem 0;
  background: #f5f5f5; }
  body .signup-form .gform_wrapper {
    margin: 0; }
    body .signup-form .gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
    body .signup-form .gform_wrapper select {
      padding-left: 0.9375rem;
      height: 3.125rem; }
    body .signup-form .gform_wrapper form {
      position: relative;
      z-index: 99;
      padding: 0; }
      body .signup-form .gform_wrapper form .validation_error {
        border: none;
        color: red;
        font-size: 1.1875rem;
        font-weight: 700;
        line-height: 1.4375rem; }
      body .signup-form .gform_wrapper form .gform_body .gform_fields .gf_right_half + li.gsection {
        padding: 0; }
      body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield {
        position: relative; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .top_label .ginput_container {
          margin: 0; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container input {
          margin: 0;
          box-shadow: none;
          border: none;
          color: #298dff;
          padding-left: 0.5rem; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .gfield_label {
          font-size: 1.1875rem;
          font-weight: 700;
          line-height: 1.4375rem;
          color: #696969;
          margin-bottom: 0.625rem; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .gfield_label .gfield_required {
            color: #696969; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .gfield_description {
          background: #fff;
          padding: 1.875rem 1.875rem 3.75rem 1.875rem;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.0625rem; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .gfield_description .gds_description {
            height: 12.0625rem;
            overflow-y: auto;
            padding-right: 1.875rem; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .gfield_description .open-window {
            float: right;
            margin-top: 1.25rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.375rem;
            color: #696969;
            border-bottom: 0.0625rem solid #696969; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_text {
          margin: 0; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_text input {
            margin: 0;
            box-shadow: none;
            border: none;
            color: #298dff; }
        @media screen and (max-width: 39.9375em) {
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect:after {
            content: url("../img/arrow-down-g.svg");
            width: 1.25rem;
            height: 0.75rem;
            display: block;
            right: 0.9375rem;
            top: 2.8125rem;
            position: absolute; } }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect select {
          height: 3.125rem !important;
          border-color: #fff;
          box-shadow: none;
          background: #fff;
          background-size: 1.25rem 0.75rem; }
          @media print, screen and (min-width: 40em) {
            body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect select {
              background: #fff url("../img/arrow-down-g.svg") no-repeat 97% center;
              background-size: 1.25rem 0.75rem; } }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect .chosen-container {
          max-width: 100%; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect .chosen-container .chosen-choices {
            height: 3.125rem !important;
            border-color: #fff;
            box-shadow: none;
            background: #fff;
            background-size: 1.25rem 0.75rem; }
            @media print, screen and (min-width: 40em) {
              body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect .chosen-container .chosen-choices {
                background: #fff url("../img/arrow-down-g.svg") no-repeat 97% center;
                background-size: 1.25rem 0.75rem; } }
            body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect .chosen-container .chosen-choices .search-field input {
              line-height: 3.125rem;
              height: 3.125rem; }
            body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect .chosen-container .chosen-choices .search-choice {
              height: 2.5rem;
              margin: 0.3125rem;
              background: #ebebeb;
              line-height: 1.875rem;
              border-radius: 0;
              color: #298dff; }
              body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect .chosen-container .chosen-choices .search-choice a {
                top: 0.9375rem; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_multiselect .chosen-container .chosen-drop {
            border-color: #ebebeb;
            box-shadow: none; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container {
          margin: 0; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container input {
            padding-left: 0.5rem; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_textarea textarea {
          border: none;
          background: #fff;
          padding: 0.3125rem;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1.0625rem;
          box-shadow: none;
          color: #298dff;
          margin: 0;
          width: 100%; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_select {
          margin: 0; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_select select {
            background-image: url("../img/arrow-down-g.svg");
            background-position: 99% center;
            background-size: 1.25rem 0.75rem;
            border: none;
            color: #298dff;
            margin-bottom: 0;
            padding-right: 0.9375rem; }
            body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_select select option {
              color: #696969; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li {
          padding: 0.9375rem 0 !important;
          /* checkbox aspect */
          /* checked mark aspect */
          /* checked mark aspect changes */
          /* disabled checkbox */
          /* accessibility */ }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li label {
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 1.1875rem;
            font-weight: 600;
            color: #696969; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:not(:checked),
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:checked {
            position: absolute;
            left: -9999px; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:not(:checked) + label,
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:checked + label {
            position: relative;
            padding-left: 1.5625rem;
            cursor: pointer; }
            @media print, screen and (min-width: 40em) {
              body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:not(:checked) + label,
              body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:checked + label {
                padding-left: 2.1875rem; } }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:not(:checked) + label:before,
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:checked + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0.125rem;
            width: 1.5625rem;
            height: 1.5625rem;
            border: 2px solid #696969;
            background: none; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:not(:checked) + label:after,
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:checked + label:after {
            content: '×';
            position: absolute;
            top: -1rem;
            left: 4px;
            font-size: 2.125rem;
            color: #298dff;
            line-height: 1.75;
            -webkit-transition: all .2s;
            -moz-transition: all .2s;
            -ms-transition: all .2s;
            transition: all .2s; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            -moz-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0); }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:checked + label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1); }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:disabled:not(:checked) + label:before,
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:disabled:checked + label:before {
            box-shadow: none;
            border-color: #bbb;
            background-color: #ddd; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:disabled:checked + label:after {
            color: #999; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:disabled + label {
            color: #aaa; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:checked:focus + label:before,
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container_checkbox ul li [type="checkbox"]:not(:checked):focus + label:before {
            border: 1px solid #fff; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_complex .ginput_right {
          width: 100%;
          margin-left: 0;
          margin-top: 1.5rem; }
          @media print, screen and (min-width: 40em) {
            body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_complex .ginput_right {
              margin-top: 0;
              width: 49.3%;
              margin-left: 0.5rem; } }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_complex label {
          font-size: 1.1875rem;
          font-weight: 700;
          line-height: 1.4375rem;
          color: #696969;
          margin-bottom: 0.625rem; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield .ginput_complex label .gfield_required {
            color: #696969; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield.field-terms label {
          display: none; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield.field_captcha label {
          display: none; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield.hide-label > label {
          display: none; }
      body .signup-form .gform_wrapper form .gform_body .gform_fields .gsection {
        border-bottom: none;
        margin: 0;
        padding: 0; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gsection h2 {
          color: #298dff;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1.625rem;
          margin: 0;
          padding: 0; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gsection.divider-only {
          padding: 0;
          margin: 0 !important; }
      body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield_error {
        background: none;
        border: none;
        padding-top: 0; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield_error .validation_message {
          display: none;
          padding: 0;
          height: 0; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield_error .gfield_label {
          color: #ff0000; }
          body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield_error .gfield_label .gfield_required {
            color: #ff0000; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield_error label {
          margin-top: 0;
          padding-top: 0;
          color: #ff0000; }
        body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield_error input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), body .signup-form .gform_wrapper form .gform_body .gform_fields .gfield_error .gform_wrapper li.gfield_error textarea {
          border: 0.0625rem solid red; }
      body .signup-form .gform_wrapper form .gform_footer input.button {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 3.125rem;
        line-height: 3.125rem;
        border-radius: 3.125rem;
        background: #298dff;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.1875rem;
        font-weight: 600;
        color: #fff;
        box-shadow: 0.40175rem 0.47875rem 0.9375rem rgba(0, 0, 0, 0.2); }
        body .signup-form .gform_wrapper form .gform_footer input.button:hover {
          background: #1b4886; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  padding-bottom: 3.75rem; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 45%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: #298dff;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: url("../img/arrow-left-sb.svg"); }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: url("../img/arrow-right-sb.svg"); }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 1; }

.error-page {
  background: #f5f5f5;
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }
  .error-page h1 {
    line-height: 3.125rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 2.8125rem;
    font-weight: 700;
    color: #696969; }
  .error-page p {
    line-height: 1.4375rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.1875rem;
    font-weight: 400;
    color: #696969; }
  .error-page .searchform {
    position: relative; }
    .error-page .searchform label {
      font-size: 1rem;
      line-height: 1.1875rem;
      font-weight: 400;
      color: #696969;
      margin-bottom: 0.625rem; }
    .error-page .searchform input[type="text"] {
      width: 100%;
      display: inline;
      float: left;
      border: none;
      box-shadow: none;
      height: 3.125rem;
      padding-right: 9.375rem; }
      .error-page .searchform input[type="text"]:focus {
        border: none;
        box-shadow: none; }
    .error-page .searchform input[type="submit"] {
      width: 9.375rem;
      position: absolute;
      right: 0;
      text-align: center;
      display: inline;
      height: 3.125rem;
      line-height: 3.125rem;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 1.1875rem;
      font-weight: 600;
      color: #fff;
      border: none;
      background: #1b4987 url("../img/search-icon-w.svg") no-repeat 90% center;
      background-size: 1.25rem 1.25rem; }
      @media print, screen and (min-width: 64em) {
        .error-page .searchform input[type="submit"] {
          font-size: 1.5rem; } }
      .error-page .searchform input[type="submit"]:hover {
        opacity: 0.8;
        cursor: hand; }
