
.faqs {
  background:#f5f5f5;
  padding:rem-calc(60) 0;
  .faq-section {
    h3.count {
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      color:#696969;
    }
    .section-title {
      font-size: rem-calc(24);
      font-weight: 700;
      line-height:rem-calc(26);
      color:#696969;
      margin-bottom:rem-calc(15);
    }
    .the-faq-accordion {
      margin-bottom:rem-calc(20);
      .accordion {
        .accordion-item {
          .accordion-title {
            background:#1b4886;
            color:#fff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(24);
            font-weight: 700;
            border:none;
            border-bottom:rem-calc(2) solid #fff;
            line-height:rem-calc(26);
            padding:rem-calc(15) rem-calc(50) rem-calc(15) rem-calc(30);
            position:relative;
            &[aria-expanded="true"] {
              background:#ebebeb;
              color:#1b4886;
              border-bottom:rem-calc(2) solid #ebebeb;

              &:before {
                background:url('../img/arrow-up-b.svg') no-repeat center center;
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari */
                transform: rotate(180deg);
              }
            }
            &:before {
              background:url('../img/arrow-up-w.svg') no-repeat center center;
              top:0;
              content:'';
              width:rem-calc(20);
              height:auto;
              margin-top:0;
              position:absolute;
              bottom:0;
              background-size:rem-calc(20) rem-calc(12);
              -webkit-transition: all 0.2s; /* Safari */
              transition: all 0.2s;

              @include breakpoint(medium) {
                width:rem-calc(31);
                background-size:rem-calc(31) rem-calc(19);
              }
            }
          }
          .accordion-content {
            background:#ebebeb;
            border:none;
            padding:rem-calc(30);
            padding-top:0;
            color:#696969;
            font-size: rem-calc(19);
            font-weight: 400;
            line-height:rem-calc(23);
            h4 {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(19);
              font-weight: 700;
              line-height:rem-calc(23);
              margin:0;
              color:#696969;
            }
            p {
              font-family: 'Source Sans Pro', sans-serif;
              font-size: rem-calc(19);
              font-weight: 400;
              line-height:rem-calc(23);
              color:#696969;
            }
            table.chart-table {
              position: relative;
              z-index: 2;
              tbody {
                background: transparent;

                tr {
                  background: transparent;
                  td {
                    vertical-align: top;
                    background-color: transparent;
                    padding-top:rem-calc(5);
                    padding-bottom:rem-calc(5);
                    &.key {
                      color: #298dff;
                      font-family: 'Source Sans Pro', sans-serif;
                      font-size: rem-calc(16);
                      font-weight: 600;
                      line-height: rem-calc(19);
                      padding-left:0;
                    }
                    &.value {
                      padding-left:0;
                      color: #696969;
                      font-family: 'Source Sans Pro', sans-serif;
                      font-size: rem-calc(16);
                      font-weight: 400;
                      line-height: rem-calc(19);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .search-box {
    background:#ebebeb;
    padding:rem-calc(30);
    margin-bottom:rem-calc(30);
    h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      margin:0 0 rem-calc(20) 0;
      padding:0;
    }
    form {
      position:relative;
      margin-bottom:0;
      input {
        height:rem-calc(50);
        line-height:rem-calc(50);
        background:#fff;
        border:none;
        box-shadow:none;
        margin-bottom:0;
      }
      button {
        position:absolute;
        right:rem-calc(20);
        top:rem-calc(15);
        margin-bottom:0;
        img {
          width:rem-calc(18.8);
          height:rem-calc(18.7);
        }
      }
      &:hover {
        button {
          opacity:0.7;
        }

      }
    }

  }

  .filters-box {
    background:#ebebeb;
    padding:rem-calc(30) rem-calc(30) rem-calc(15) rem-calc(30);
    margin-bottom:rem-calc(30);
    @include breakpoint(large) {
      margin-bottom:0;
    }
    h3 {
      color: #298dff;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: rem-calc(24);
      font-weight: 700;
      line-height: rem-calc(26);
      margin:0 0 0 0;
      padding:0 0 rem-calc(20) 0;
      border-bottom:rem-calc(2) solid #dcdcdc;
    }
    .filters-box-content {
      .filters-accordion {
        list-style:none;
        margin:0;
        padding:0;
        .accordion-item {
          border-top: rem-calc(2) solid #dcdcdc;
          padding:rem-calc(15) 0 rem-calc(15) 0;
          &:first-child {
            border-top:none;
          }
          .accordion-title {
            background: none;
            color: #298dff;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: rem-calc(19);
            font-weight: 700;
            border: none;
            text-transform:uppercase;
            line-height: rem-calc(23);
            padding: 0;
            position:relative;
            &[aria-expanded="true"] {

              color:#298dff;


              &:before {
                background:url('../img/arrow-up-sb.svg') no-repeat center center;
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari */
                transform: rotate(180deg);
              }
            }
            &:before {
              background:url('../img/arrow-up-sb.svg') no-repeat center center;
              top:0;
              content:'';
              width:rem-calc(20);
              height:auto;
              margin-top:0;
              position:absolute;
              bottom:0;
              right: 0;
              background-size:rem-calc(20) rem-calc(12);
              -webkit-transition: all 0.2s; /* Safari */
              transition: all 0.2s;
            }
          }
          .accordion-content {
            background:none;
            border:none;
            padding:rem-calc(15) 0 0 0;

            .filter-item {
              padding:0;
              margin-bottom:rem-calc(2);
              label {
                color:#696969;
                font-family: 'Source Sans Pro', sans-serif;
                font-size: rem-calc(19);
                font-weight: 400;
                line-height:rem-calc(23);
              }

              [type="checkbox"]:not(:checked),
              [type="checkbox"]:checked {
                position: absolute;
                left: rem-calc(-9999);
              }
              [type="checkbox"]:not(:checked) + label,
              [type="checkbox"]:checked + label {
                position: relative;
                padding-left: rem-calc(25);
                cursor: pointer;
                margin-left:0;
              }

              /* checkbox aspect */
              [type="checkbox"]:not(:checked) + label:before,
              [type="checkbox"]:checked + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 2px;
                width: rem-calc(17);
                height: rem-calc(17);
                border: rem-calc(2) solid #696969;
                background: none;


              }
              /* checked mark aspect */
              [type="checkbox"]:not(:checked) + label:after,
              [type="checkbox"]:checked + label:after {
                content: '×';
                position: absolute;
                top: rem-calc(-11);
                left: rem-calc(2.5);
                font-size: rem-calc(24);
                color: #696969;
                line-height: 1.75;
                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                -ms-transition: all .2s;
                transition: all .2s;
              }
              /* checked mark aspect changes */
              [type="checkbox"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                -ms-transform: scale(0);
                transform: scale(0);
              }
              [type="checkbox"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
              }
              /* disabled checkbox */
              [type="checkbox"]:disabled:not(:checked) + label:before,
              [type="checkbox"]:disabled:checked + label:before {
                box-shadow: none;
                border-color: #bbb;
                background-color: #ddd;
              }
              [type="checkbox"]:disabled:checked + label:after {
                color: #999;
              }
              [type="checkbox"]:disabled + label {
                color: #aaa;
              }
              /* accessibility */
              [type="checkbox"]:checked:focus + label:before,
              [type="checkbox"]:not(:checked):focus + label:before {
                border: 1px solid #fff;
              }
            }
          }
        }
      }
    }
  }
}