.ready-to-go {
  padding:rem-calc(70) 0 0 0;

  .cta-buttons {
    margin-bottom:rem-calc(50);
    text-align:center;
    .clear {
      clear:both;
    }
    .ready-text {
      font-size:rem-calc(45);
      line-height:rem-calc(50);
      font-weight:300;
      color:#298dff;
      float:none;

      @include breakpoint(large) {
        float:left;
        font-size:rem-calc(60);
        line-height:rem-calc(60);
      }
    }

    .buy-now {
      display:block;
      text-align:center;
      font-size:rem-calc(19);
      font-weight:600;
      margin-top:rem-calc(20);
      margin-left:0;
      border-radius:rem-calc(50);
      height:rem-calc(50);
      line-height:rem-calc(50);
      background:#298dff;
      color:#fff;
      width:100%;
      float:none;
      &:hover {
        background:#1b4886;
      }


      @include breakpoint(large) {
        float:left;
        width:rem-calc(322);
        display:inline-block;
        margin-left:rem-calc(30);
        margin-top:rem-calc(5);
      }
    }
    .trail-period {
      display:block;
      text-align:center;
      font-size:rem-calc(19);
      font-weight:600;
      margin-left:0;
      margin-top:rem-calc(20);
      border:rem-calc(2) solid #298dff;
      border-radius:rem-calc(50);
      height:rem-calc(50);
      line-height:rem-calc(50);
      color:#298dff;
      width:100%;
      float:none;

      @include breakpoint(large) {
        display:inline-block;
        float:left;
        width:rem-calc(329);
        margin-left:rem-calc(30);
        margin-top:rem-calc(5);
      }
      &:hover {
        color:#1b4886;
        border-color:#1b4886;
      }
    }
  }
}